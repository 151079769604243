













import { Component, Prop, Vue } from 'vue-property-decorator'
import { IJobData } from '@/interfaces/JobPositionsInterface'
import JobCard from '@/components/JobCard.vue'
import Routes from '@/router/routes'

@Component({
  components: { JobCard },
})
export default class JobPositionsRelated extends Vue {
  @Prop() data!: IJobData[]
  Routes = Routes
}
