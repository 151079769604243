




















































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { namespace } from 'vuex-class'
import { DateTime } from 'luxon'
import { BvTableFieldArray } from 'bootstrap-vue/src/components/table'
import Student from '@/classes/student'
import UserService from '@/services/UserService'
import { IMeta, IPaginatedResponse } from '@/interfaces/ResponseInterface'
import { ITableProperties, ITableSortChanged } from '@/interfaces/TableInterface'
import TrainingService from '@/services/TrainingService'
import TrainingData from '@/classes/training/training'
import Classifier from '@/classes/training/classifier'
import TrainingLocation from '@/classes/training/training-location'
import TrainingLocationService from '@/services/TrainingLocationService'
import IConfirmDialog from '@/interfaces/ConfirmDialogInterface'
import OrderItemService from '@/services/OrderItemService'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import SelectOption from '@/interfaces/SelectOption'
import EmptyList from '@/components/EmptyList.vue'
import Pagination from '@/components/Pagination.vue'
import Search from '@/components/Search.vue'
import AddStudentsModal from '@/components/profile/AddStudentsModal.vue'
import scrollToRef from '@/helpers/scroll-to-ref-helper'

enum StudentsFilter {
  search = 'q',
  training = 'filter-training-uuid',
  industry = 'filter-user_trainings-industry_classifier_id',
  trainingLocation = 'filter-trainingLocation-location',
  expireDate = 'filter-between-expire_date',
  gteExpire = 'filter-gte-days_to_expire',
  gtExpire = 'filter-gt-days_to_expire',
  ltExpire = 'filter-lt-days_to_expire',
  lteExpire = 'filter-lte-days_to_expire',
}

const Global = namespace('Global')

enum ProfileStudentsFilterValidity {
  moreThan60 = 'moreThan60',
  lessThan60 = 'lessThan60',
  expired = 'expired',
}

@Component({
  components: { AddStudentsModal, Search, EmptyList, Pagination },
})
export default class Students extends Vue {
  private loading: boolean = false
  ProfileStudentsFilterValidity = ProfileStudentsFilterValidity

  private students: Student[] = []
  private meta: IMeta | any = {}
  public opened: boolean = false
  private selectedStudents: string[] = []

  public tableProperties: ITableProperties = {
    currentPage: 1,
    perPage: 15,
    filters: {
      search: '',
      trainingId: null,
      industryClassifierId: null,
      trainingLocationId: null,
      expiryDate: '',
      validity: null,
    },
  }

  private trainings: TrainingData[] = []
  private classifiers: Classifier[] = []
  private trainingLocations: TrainingLocation[] = []

  @Global.Action
  private showConfirmDialog!: (confirmDialog: IConfirmDialog) => void

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  private onDateChange = _.debounce(this.dateChanged, 500)

  created(): void {
    this.parseQueryParams()
    this.loadStudents()
    this.loadFiltersData()

    this.$watch(
      () => this.$route,
      () => {
        this.parseQueryParams()
        this.loadStudents()
      },
      { deep: true }
    )

    this.$watch(() => this.tableProperties, this.changeRouteByProperties, { deep: true })
  }

  private onPageChange(page: number): void {
    this.tableProperties.currentPage = page
  }

  loadStudents(): void {
    this.loading = true

    const { query } = this.$router.currentRoute

    UserService.getStudents(query, this.tableProperties.perPage).then((response: IPaginatedResponse<Student[]>) => {
      this.students = response.data
      this.meta = response.meta
    })
    this.loading = false
  }

  loadFiltersData(): void {
    TrainingService.list({}, 9999).then(
      (response: IPaginatedResponse<TrainingData[]>) => (this.trainings = response.data)
    )

    TrainingService.getClassifiersByCategory('industry').then(
      (classifiers: Classifier[]) => (this.classifiers = classifiers)
    )

    TrainingLocationService.list({}, 9999).then(
      (response: IPaginatedResponse<TrainingLocation[]>) => (this.trainingLocations = response.data)
    )
  }

  private remindToRefresh(): void {
    let isOne = ''

    if (this.selectedStudents.length > 1) isOne = 's'

    this.showConfirmDialog({
      text: `Selected user${isOne} will be notified about training expiration date${isOne}`,
      confirmText: `Notify (${this.selectedStudents.length})`,
      confirmAction: async () => {
        await Promise.all(
          this.selectedStudents.map(async (item: string) => {
            const id = Number(item.split('.')[0])

            const userId = Number(item.split('.')[1])

            return OrderItemService.renewUserTraining(id, userId)
          })
        )

        this.selectedStudents = []
      },
    })
  }

  private generateReport(exportType: string): void {
    const { query } = this.$router.currentRoute

    UserService.generateStudentsReport(exportType, query)
      .then(() =>
        this.showSnackbar({
          type: SnackbarType.success,
          text: 'Students report generation started, download will processed soon as possible',
        })
      )
      .catch(() =>
        this.showSnackbar({
          type: SnackbarType.error,
          text: `Students report generation failed, please try again or contact system administrator`,
        })
      )
  }

  private onPageInput(id: string): void {
    this.loading = true

    scrollToRef(id)
  }

  private onSearch(search: string): void {
    this.tableProperties.filters.search = search

    this.changeRouteByProperties()
  }

  private dateChanged(): void {
    this.changeRouteByProperties()
  }

  private onValidityChange(): void {
    this.changeRouteByProperties()
  }

  private isSelected(student: Student): boolean {
    const id = this.getCombinedIds(student)

    return this.selectedStudents.some((item: string) => item === id)
  }

  private toggleSelection(student: Student): void {
    const id = this.getCombinedIds(student)

    if (this.isSelected(student)) this.selectedStudents = this.selectedStudents.filter((item: string) => item !== id)
    else this.selectedStudents.push(id)
  }

  private toggleAllSelection(): void {
    if (this.isAllSelected) {
      this.selectedStudents = []

      return
    }

    this.selectedStudents = this.students
      .filter((student: Student) => student.daysToExpire > 0)
      .map((student: Student) => this.getCombinedIds(student)) as string[]
  }

  private getCombinedIds(student: Student): string {
    return `${student.id}.${student.userId}`
  }

  private getTableTrClass(item: Student): string {
    let classes = ''

    if (!item) return classes

    if (this.selectedStudents.some((id: string) => id === this.getCombinedIds(item))) {
      classes += 'student-selected'
    }

    const timeRemaining = item.daysToExpire

    if (!item.isActivated) return classes

    if (timeRemaining < 0) return `${classes} expired`

    if (timeRemaining < 60) return `${classes} warning`

    return `${classes} valid`
  }

  private expirationToString(date: string): string {
    const dateFormat = new Date(date)

    if (dateFormat <= new Date()) return 'expired'

    const days = DateTime.fromJSDate(dateFormat).diff(DateTime.now(), 'days').days.toFixed(0)

    return `expires ${days} days`
  }

  private onTableSortChange(event: ITableSortChanged): void {
    this.tableProperties.sortBy = event.sortBy
    this.tableProperties.order = event.sortDesc ? 'desc' : 'asc'
    this.changeRouteByProperties()
  }

  public getToggleOrderEvent(order: string): any {
    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'asc') {
      return {
        sortBy: '',
        sortDesc: false,
      }
    }

    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'desc') {
      return {
        sortBy: order,
        sortDesc: false,
      }
    }

    return {
      sortBy: order,
      sortDesc: true,
    }
  }

  private getFilterOrderClass(order: string): string {
    if (this.tableProperties.sortBy === order) {
      return this.tableProperties.order || ''
    }

    return ''
  }

  private parseQueryParams(): void {
    const { query } = this.$router.currentRoute

    this.tableProperties.currentPage = Number(_.get(query, 'page', 1))
    this.tableProperties.sortBy = _.get(query, 'sort_by') as string
    this.tableProperties.order = _.get(query, 'order') as string
    this.tableProperties.filters.search = _.get(query, StudentsFilter.search, '')
    this.tableProperties.filters.trainingId = _.get(query, StudentsFilter.training, '')
    this.tableProperties.filters.industryClassifierId = _.get(query, StudentsFilter.industry, '')
    this.tableProperties.filters.trainingLocationId = _.get(query, StudentsFilter.trainingLocation, '')
    this.tableProperties.filters.expiryDate = _.get(query, StudentsFilter.expireDate, '')

    if (Object.keys(query).some((key: string) => this.validityFiltersVariations.some((item: string) => item === key))) {
      if (Number(_.get(query, StudentsFilter.gteExpire, 0)) === 60) {
        this.tableProperties.filters.validity = ProfileStudentsFilterValidity.moreThan60
      } else if (
        Number(_.get(query, StudentsFilter.ltExpire, 0)) === 60 &&
        Number(_.get(query, StudentsFilter.gtExpire, 0)) === 0
      ) {
        this.tableProperties.filters.validity = ProfileStudentsFilterValidity.lessThan60
      } else if (Number(_.get(query, StudentsFilter.lteExpire, 0)) === 0) {
        this.tableProperties.filters.validity = ProfileStudentsFilterValidity.expired
      }
    }
  }

  private changeRouteByProperties(): void {
    const { name, query, params }: any = this.$router.currentRoute

    const { currentPage, sortBy, order, filters } = this.tableProperties

    const routeQuery = {
      ...query,
      page: currentPage,
      sort_by: sortBy,
      order,
    }

    if (sortBy) routeQuery.sort_by = sortBy
    else delete routeQuery.sort_by

    if (order) routeQuery.order = order
    else delete routeQuery.order

    if (filters.search !== '') routeQuery[StudentsFilter.search] = filters.search
    else delete routeQuery[StudentsFilter.search]

    Object.keys(routeQuery).forEach((key: string) => {
      if (key.startsWith('filter-')) delete routeQuery[key]
    })

    if (filters.trainingId) routeQuery[StudentsFilter.training] = filters.trainingId

    if (filters.industryClassifierId) routeQuery[StudentsFilter.industry] = filters.industryClassifierId

    if (filters.trainingLocationId) routeQuery[StudentsFilter.trainingLocation] = filters.trainingLocationId

    if (filters.expiryDate !== '') routeQuery[StudentsFilter.expireDate] = filters.expiryDate

    if (filters.validity && filters.validity !== '') {
      switch (filters.validity) {
        case ProfileStudentsFilterValidity.moreThan60:
          routeQuery[StudentsFilter.gteExpire] = 60
          break
        case ProfileStudentsFilterValidity.lessThan60:
          routeQuery[StudentsFilter.ltExpire] = 60
          routeQuery[StudentsFilter.gtExpire] = 0
          break
        default:
          routeQuery[StudentsFilter.lteExpire] = 0
          break
      }
    }

    this.$router
      .replace({
        name,
        params,
        query: routeQuery,
      })
      .catch(() => {})
  }

  private clearAllFilters(): void {
    this.tableProperties.filters.expiryDate = ''
    this.tableProperties.filters.industryClassifierId = ''
    this.tableProperties.filters.search = ''
    this.tableProperties.filters.trainingId = ''
    this.tableProperties.filters.trainingLocationId = ''
    this.tableProperties.filters.validity = null

    const { trainingFilter, industryFilter, locationFilter, dateFilter }: any = this.$refs

    if (locationFilter) locationFilter.inputValue = ''

    if (industryFilter) industryFilter.inputValue = ''

    if (trainingFilter) trainingFilter.inputValue = ''

    if (dateFilter) dateFilter.initialValue = ''

    this.changeRouteByProperties()
  }

  private getFields(): BvTableFieldArray {
    return [
      {
        key: 'selected',
        label: '',
      },
      {
        key: 'user_trainings.users_name',
        label: 'Student name',
        sortable: true,
      },
      {
        key: 'user_trainings.training_title',
        label: 'Training',
        sortable: true,
      },
      {
        key: 'user_trainings.industry_title',
        label: 'Industry',
        sortable: true,
      },
      {
        key: 'user_trainings.location',
        label: 'Location',
        sortable: true,
      },
      {
        key: 'expire_date',
        label: 'Training expiry date',
        sortable: true,
      },
    ]
  }

  private toggle(): void {
    this.opened = !this.opened
  }

  get isAllExpired(): boolean {
    return !this.students.find((student: Student) => student.daysToExpire > 0)
  }

  get isAllSelected(): boolean {
    const filteredExpiredStudents = this.students.filter((student: Student) => student.daysToExpire > 0)

    if (filteredExpiredStudents.length === 0) return false

    return filteredExpiredStudents.every((student: Student) =>
      this.selectedStudents.some((item: string) => item === this.getCombinedIds(student))
    )
  }

  get isRemindToRefreshEnabled(): boolean {
    return this.selectedStudents.length > 0
  }

  get validityFiltersVariations(): string[] {
    return [StudentsFilter.gteExpire, StudentsFilter.gtExpire, StudentsFilter.ltExpire, StudentsFilter.lteExpire]
  }

  get trainingSelections(): SelectOption[] {
    return this.trainings.map((training: TrainingData) => ({
      value: training.uuid,
      text: training.title,
    }))
  }

  get industryClassifierSelections(): SelectOption[] {
    return this.classifiers.map((industry: Classifier) => ({
      value: industry.id,
      text: industry.title,
    }))
  }

  get trainingLocationSelections(): SelectOption[] {
    return [
      ...new Set(this.trainingLocations.map((trainingLocation: TrainingLocation) => trainingLocation.location)),
    ].map((location: string) => ({
      value: location,
      text: location,
    }))
  }
}
