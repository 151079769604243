export enum LocationTypes {
  online = 'online',
  mixed = 'mixed',
  local = 'local',
}

export const LocationTypesData: any = {
  [LocationTypes.local]: 'Local',
  [LocationTypes.mixed]: 'Mixed',
  [LocationTypes.online]: 'Online',
}
