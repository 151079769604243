enum StudentUserStatus {
  notAttended = 'not_attended',
  attended = 'attended',
  refunded = 'refunded',
  registered = 'registered',
}

export const StudentUserStatusTitle: Record<string, string> = {
  [StudentUserStatus.attended]: 'Attended',
  [StudentUserStatus.notAttended]: 'Not Attended',
  [StudentUserStatus.refunded]: 'Refunded',
  [StudentUserStatus.registered]: 'Register',
}

export default StudentUserStatus
