
import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { AxiosError } from 'axios'
import { namespace } from 'vuex-class'
import { ICountries } from '@/interfaces/AuthInterface'
import Routes from '@/router/routes'
import IUserForm from '@/interfaces/form-interfaces/UserFormInterface'
import ProfileService from '@/services/ProfileService'
import User from '@/classes/user/user'

interface IOption {
  value: string
  text: string
}

const Auth = namespace('Auth')

@Component
export default class ProfileEditAbstract extends Vue {
  @Auth.Action
  private getMe!: () => Promise<User>

  @Prop() readonly data!: User
  loading: boolean = false
  countries: ICountries[] = require('@/storage/countries.json')

  clearErrorOnChange(id: string): void {
    this.errors.remove(id)
  }

  async submit(user: IUserForm, formData: FormData = new FormData()): Promise<void> {
    this.errors.clear()
    this.loading = true

    this.buildFormData(formData, _.omit(user, 'documents'))

    await ProfileService.submitProfileEdit(user, formData)
      .then(() => this.getMe())
      .then(() => this.$router.push({ name: Routes.PROFILE }))
      .catch((error: AxiosError) => this.setErrorsFromResponse(error))
      .finally(async () => {
        this.loading = false
        this.$root.$emit('checkForCompleteProfileData')
      })
  }

  buildFormData(formData: FormData, data: any, parentKey: string = ''): void {
    if (_.isObject(data) && !(data instanceof Date) && !(data instanceof File)) {
      _.each(data, (value: any, key: string) => {
        // @ts-ignore
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${_.snakeCase(key)}]` : _.snakeCase(key))
      })
    } else {
      const value = data === null ? '' : data
      formData.append(parentKey, value)
    }
  }

  get countryOptions(): IOption[] {
    return this.countries.map((country: ICountries) => ({
      value: country.name,
      text: country.name,
    }))
  }
}
