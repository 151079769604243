


























import { Component, Prop } from 'vue-property-decorator'
import AbstractField from '@/components/form/fields/AbstractField.vue'

@Component
export default class TextField extends AbstractField {
  @Prop({ default: 'text' }) private readonly type!: string
  @Prop({ default: 'on' }) private readonly autocomplete!: string
}
