













































import { Component, Prop } from 'vue-property-decorator'
import AbstractField from '@/components/form/fields/AbstractField.vue'
import DocumentClass from '@/classes/user/document'
import { DocumentTypes } from '@/configs/document-type'

@Component
export default class UserCvField extends AbstractField {
  @Prop() private readonly existingCV!: DocumentClass
  cvModel: DocumentClass | null = null

  async saveFiles(event: any): Promise<void> {
    if (!event.target.files[0]) return

    if (this.existingCV) this.$emit('removeImage', this.existingCV, this.id)

    this.cvModel = new DocumentClass({
      type: DocumentTypes.cv,
      id: 0,
      file: event.target.files[0],
    })
    this.emitChange(this.cvModel)
  }

  get previewImage(): DocumentClass | null {
    if (this.existingCV) return this.existingCV

    if (this.cvModel) return this.cvModel

    return null
  }
}
