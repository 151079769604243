






import { Component, Vue } from 'vue-property-decorator'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import CompanyProfileEdit from '@/components/profile/edit/CompanyProfileEdit.vue'
import TrainingCenterProfileEdit from '@/components/profile/edit/TrainingCenterProfileEdit.vue'
import UserProfileEdit from '@/components/profile/edit/UserProfileEdit.vue'
import ProfileService from '@/services/ProfileService'
import User, { UserType } from '@/classes/user/user'

const RoleComponents: any = {
  [UserType.admin]: UserProfileEdit,
  [UserType.user]: UserProfileEdit,
  [UserType.company]: CompanyProfileEdit,
  [UserType.trainingCenter]: TrainingCenterProfileEdit,
}

@Component({
  components: { Breadcrumbs },
})
export default class ProfileEdit extends Vue {
  user: User | null = null
  loading: boolean = false
  RoleComponents = RoleComponents

  async created(): Promise<void> {
    this.loading = true
    this.user = await ProfileService.getMe().finally(() => (this.loading = false))
  }
}
