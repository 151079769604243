import TrainingLocationDate from '@/classes/training/training-location-date'
import IWishlistResponse from '@/interfaces/WishlistInterface'
import Model from '@/classes/model'

export default class Wishlist extends Model {
  trainingLocationDate!: TrainingLocationDate

  constructor(data: IWishlistResponse) {
    super(data)

    this.trainingLocationDate = this.setRelation(data.training_location_date, TrainingLocationDate)
  }
}
