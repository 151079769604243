











import { Component, Prop, Vue } from 'vue-property-decorator'
import { Route } from 'vue-router'

@Component
export default class Breadcrumbs extends Vue {
  @Prop({ default: null }) overrides: any

  get routes(): any[] {
    const { name }: Route = this.$route

    if (!name) return []

    return name.split('/').map((item: string) => ({
      readableName: this.overrides && this.overrides[item] ? this.overrides[item] : item.replaceAll('-', ' '),
      name: item,
    }))
  }
}
