



















import { Component, Vue } from 'vue-property-decorator'
import VueSlickCarousel from 'vue-slick-carousel'
import { StoryInterface } from '@/interfaces/StoryInterface'
import FeaturedStory from '@/components/FeaturedStory.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

@Component({
  components: {
    FeaturedStory,
    VueSlickCarousel,
  },
})
export default class FrontStories extends Vue {
  nextSlide(): void {
    const carouselRef: any = this.$refs.storyCarousel
    carouselRef.next()
  }

  created(): void {
    this.$root.$on('carouselNext', this.nextSlide)
  }

  public stories: StoryInterface[] = [
    {
      name: 'Nikas geisleris',
      position: 'Graphic designer',
      text: 'Pulvinar interdum sem tincidunt facilisis eget in. Ut quis tellus amet sed malesuada. Nunc arcu nunc, mi integer. Ut quis tellus amet sed malesuada. Nunc arcu nunc, mi integer. Pulvinar interdum sem tincidunt facilisis eget in. Ut quis tellus amet sed malesuada.',
      image: 'https://placeimg.com/700/575/any',
    },
    {
      name: 'Name2',
      position: 'Position2',
      text: 'Text2',
      image: 'https://placeimg.com/700/575/any',
    },
  ]
}
