import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'
import TrainingLocation from '@/classes/training/training-location'
import http from '@/classes/http'
import ITrainingLocation from '@/interfaces/training/TrainingLocationInterface'

export default class TrainingLocationService {
  static async list(query: any = {}, perPage: number = 15): Promise<IPaginatedResponse<TrainingLocation[]>> {
    return http
      .get('training-locations', {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: ITrainingLocation) => new TrainingLocation(item)),
        meta: response.data.meta,
      }))
  }

  static async countries(query: any = {}, perPage: number = 15): Promise<IPaginatedResponse<TrainingLocation[]>> {
    return http
      .get('trainings/profile-training-country-list', {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: ITrainingLocation) => new TrainingLocation(item)),
        meta: response.data.meta,
      }))
  }
}
