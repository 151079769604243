






















import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import { IBaseResponse } from '@/interfaces/AuthInterface'

const Global = namespace('Global')

const Auth = namespace('Auth')

@Component
export default class ForgotPasswordModal extends Vue {
  private loading = false
  private email = ''

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Auth.Action
  private passwordResetInit!: (email: string) => Promise<IBaseResponse>

  private async sendConfirmationLink() {
    this.loading = true

    try {
      const response = await this.passwordResetInit(this.email)

      this.showSnackbar({
        type: SnackbarType.success,
        text: response.message,
      })
      this.loading = false
      this.$bvModal.hide('forgotPassword')
    } catch (error: any) {
      if (error.message) {
        this.showSnackbar({
          type: SnackbarType.error,
          text: error.message,
        })
      }

      this.loading = false
      this.$validator.errors.clear()

      if (error.status !== 422) return

      const errorFields = Object.keys(error.errors)
      errorFields.forEach((field: any) => {
        const errorString = error.errors[field].join(', ')
        this.$validator.errors.add({
          field,
          msg: errorString,
        })
      })
    }
  }
}
