import { Action, Module, VuexModule } from 'vuex-module-decorators'
import EmployeesService from '@/services/EmployeesService'
import { IAddEmployeeData } from '@/interfaces/EmployeeListInterface'

@Module({ namespaced: true })
class Company extends VuexModule {
  @Action({ rawError: true })
  async addNewEmployee(data: IAddEmployeeData): Promise<void> {
    try {
      await EmployeesService.addNewEmployee(data)
    } catch (error: any) {
      throw (
        { ...(error.response && error.response.data), status: error.response?.status, itemIndex: data.index } ||
        error.message ||
        error.toString()
      )
    }
  }
}

export default Company
