































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Header from '@/components/Header.vue'
import PageHeader from '@/components/PageHeader.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Routes from '@/router/routes'
import User, { UserType } from '@/classes/user/user'
import CompanyDetails from '@/components/profile/CompanyDetails.vue'
import TrainingCenterDetails from '@/components/profile/TrainingCenterDetails.vue'
import UserDetails from '@/components/profile/UserDetails.vue'
import AdminDetails from '@/components/profile/AdminDetails.vue'

const Auth = namespace('Auth')

@Component({
  components: {
    Breadcrumbs,
    PageHeader,
    Header,
  },
})
export default class Profile extends Vue {
  Routes = Routes
  UserType = UserType

  @Auth.Getter
  private user!: User

  get detailsComponents(): any {
    return {
      [UserType.company]: CompanyDetails,
      [UserType.trainingCenter]: TrainingCenterDetails,
      [UserType.user]: UserDetails,
      [UserType.admin]: AdminDetails,
    }
  }
}
