import { readAndCompressImage } from 'browser-image-resizer'

const config = {
  quality: 0.9,
  maxWidth: 1920,
  maxHeight: 1080,
  autoRotate: false,
  debug: false,
}

export default async function resizeAndCompress(file: File): Promise<File> {
  return new File([await readAndCompressImage(file, config)], file.name, { type: file.type })
}
