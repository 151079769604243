





































































































































import { Component } from 'vue-property-decorator'
import _ from 'lodash'
import ProfileEditAbstract from '@/components/profile/edit/ProfileEditAbstract.vue'
import ProfileHeader from '@/components/profile/edit/ProfileHeader.vue'
import Unsubscribe from '@/components/profile/edit/Unsubscribe.vue'
import Routes from '@/router/routes'
import IUserForm from '@/interfaces/form-interfaces/UserFormInterface'
import CompanyLogoField from '@/components/form/fields/CompanyLogoField.vue'
import DocumentClass from '@/classes/user/document'
import { DocumentTypes } from '@/configs/document-type'
import ProfileService from '@/services/ProfileService'

@Component({
  components: {
    CompanyLogoField,
    ProfileHeader,
    Unsubscribe,
  },
})
export default class CompanyProfileEdit extends ProfileEditAbstract {
  user!: IUserForm
  companyLogo: DocumentClass | null = null
  Routes = Routes

  created(): void {
    this.user = this.data.toFormData()
  }

  get documentLogosFromData(): DocumentClass | undefined {
    return _.find(this.user.documents, (document: DocumentClass) => document.type === DocumentTypes.logo)
  }

  removeDBImage(image: DocumentClass, fieldId: string): void {
    ProfileService.deleteDBImage(image)
      .then(() => {
        if (!this.user.documents) this.user.documents = []
        this.user.documents = this.user.documents.filter((document: DocumentClass) => document.id !== image.id)
      })
      .catch(() => this.addError(fieldId, `Could not remove image ${image.fileName}`))
  }

  appendImagesToFormData(formData: FormData, data: any): FormData {
    data.forEach((document: DocumentClass, index: number) => {
      if (!document) return
      Object.keys(document).forEach((key: string) => {
        if (data[index][key]) formData.append(`documents[${index}][${key}]`, data[index][key])
      })
    })

    return formData
  }

  submitCompany(): void {
    const formData: FormData = new FormData()

    this.submit(this.user, this.appendImagesToFormData(formData, [this.companyLogo]))
  }
}
