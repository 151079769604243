




import { Component, Prop, Vue } from 'vue-property-decorator'
import TrainingData from '@/classes/training/training'
import ServicesField from '@/components/form/fields/training/ServicesField.vue'
import Classifier from '@/classes/training/classifier'
import TrainingService from '@/services/TrainingService'

@Component({
  components: { ServicesField },
})
export default class TrainingStepServices extends Vue {
  @Prop() private readonly data!: TrainingData

  services: Classifier[] = []

  async created(): Promise<void> {
    this.services = await TrainingService.getClassifiersByCategory('training_service')
  }
}
