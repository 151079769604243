import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import Register from '@/views/Register.vue'
import Login from '@/views/Login.vue'
import NewsArchive from '@/views/NewsArchive.vue'
import NewTraining from '@/views/NewTraining.vue'
import Checkout from '@/views/Checkout.vue'
import Cart from '@/views/Cart.vue'
import Success from '@/views/Success.vue'
import Trainings from '@/views/Trainings.vue'
import Profile from '@/views/Profile.vue'
import ProfileEdit from '@/views/ProfileEdit.vue'
import TrainingInner from '@/views/TrainingInner.vue'
import EmailVerify from '@/views/EmailVerify.vue'
import TrainingCenters from '@/views/TrainingCenters.vue'
import TrainingCenterInner from '@/views/TrainingCenterInner.vue'
import Routes from '@/router/routes'
import { beforeEach } from '@/helpers/router/before-each'
import ProfileTrainings from '@/views/Profile/Trainings.vue'
import ProfileStudents from '@/views/Profile/Students.vue'
import ProfileDiscounts from '@/views/Profile/Discounts.vue'
import ProfileEmployees from '@/views/Profile/Employees.vue'
import ProfileWishlist from '@/views/Profile/Wishlist.vue'
import AboutUs from '@/views/Additional/AboutUs.vue'
import Faq from '@/views/Additional/Faq.vue'
import Support from '@/views/Additional/Support.vue'
import Contact from '@/views/Additional/Contact.vue'
import HowToApply from '@/views/Additional/HowToApply.vue'
import Prices from '@/views/Additional/Prices.vue'
import TermsOfService from '@/components/home/TermsOfService.vue'
import PasswordReset from '@/views/PasswordReset.vue'
import EmployeeConfirm from '@/views/EmployeeConfirm.vue'
import NotFound from '@/views/NotFound.vue'
import CompanyUserPasswordSet from '@/views/CompanyUserPasswordSet.vue'
import StripeCallback from '@/views/StripeCallback.vue'
import SocialLogin from '@/views/SocialLogin.vue'
import AdminPanel from '@/views/Admin/AdminPanel.vue'
import AdminUserList from '@/views/Admin/AdminUsersList.vue'
import AdminBoughtTrainingsList from '@/views/Admin/AdminBoughtTrainingsList.vue'
import AdminClassifiers from '@/views/Admin/AdminClassifiers.vue'
import MyOrders from '@/views/Profile/MyOrders.vue'
import PassCreateSuccess from '@/views/PassCreateSuccess.vue'
import PrivacyPolicy from '@/components/home/PrivacyPolicy.vue'
import ProfileJobPositions from '@/views/Profile/ProfileJobPositions.vue'
import JobPositions from '@/views/JobPositions.vue'
import NewJobPosition from '@/views/Profile/NewJobPosition.vue'
import JobPositionInner from '@/views/JobPositionInner.vue'
import EditJobPosition from '@/views/Profile/EditJobPosition.vue'
import UserCertificates from '@/views/Profile/UserCertificates.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: Routes.HOME,
    component: Home,
  },
  {
    path: '/user-register',
    name: Routes.USER_REGISTER,
    component: Register,
  },
  {
    path: '/company-register',
    name: Routes.COMPANY_REGISTER,
    component: Register,
  },
  {
    path: '/training-register',
    name: Routes.TRAINING_REGISTER,
    component: Register,
  },
  {
    path: '/login',
    name: Routes.LOGIN,
    component: Login,
  },
  {
    path: '/auth/:provider/callback',
    name: Routes.SOCIAL_LOGIN,
    component: SocialLogin,
  },
  {
    path: '/news',
    name: Routes.NEWS,
    component: NewsArchive,
  },
  {
    path: '/new-training',
    name: Routes.NEW_TRAINING,
    component: NewTraining,
  },
  {
    path: '/checkout',
    name: Routes.CHECKOUT,
    meta: {
      requiresAuth: true,
    },
    component: Checkout,
  },
  {
    path: '/stripe-callback',
    name: Routes.STRIPE_CALLBACK,
    meta: {
      requiresAuth: true,
    },
    component: StripeCallback,
  },
  {
    path: '/cart',
    name: Routes.CART,
    meta: {
      requiresAuth: true,
    },
    component: Cart,
  },
  {
    path: '/success',
    name: Routes.SUCCESS,
    component: Success,
  },
  {
    path: '/trainings',
    name: Routes.TRAININGS,
    component: Trainings,
  },
  {
    path: '/admin',
    name: Routes.ADMIN_PANEL,
    meta: {
      requiresAdminAuth: true,
    },
    component: AdminPanel,
    redirect: {
      name: Routes.ADMIN_USERS_LIST,
    },
    children: [
      {
        path: 'users-list',
        name: Routes.ADMIN_USERS_LIST,
        component: AdminUserList,
      },
      {
        path: 'classifiers',
        name: Routes.ADMIN_CLASSIFIERS,
        component: AdminClassifiers,
      },
      {
        path: 'bought-trainings-list',
        name: Routes.ADMIN_BOUGHT_TRAININGS_LIST,
        component: AdminBoughtTrainingsList,
      },
    ],
  },
  {
    path: '/profile',
    name: Routes.PROFILE,
    component: Profile,
    redirect: {
      name: Routes.PROFILE_TRAININGS,
    },
    children: [
      {
        path: 'trainings',
        name: Routes.PROFILE_TRAININGS,
        component: ProfileTrainings,
      },
      {
        path: 'students',
        name: Routes.PROFILE_STUDENTS,
        component: ProfileStudents,
      },
      {
        path: 'discounts',
        name: Routes.PROFILE_DISCOUNTS,
        component: ProfileDiscounts,
      },
      {
        path: 'employees',
        name: Routes.PROFILE_EMPLOYEES,
        component: ProfileEmployees,
      },
      {
        path: 'wishlist',
        name: Routes.PROFILE_WISHLIST,
        component: ProfileWishlist,
      },
      {
        path: 'my-orders',
        name: Routes.PROFILE_MY_ORDERS,
        component: MyOrders,
      },
      {
        path: 'job-positions',
        name: Routes.PROFILE_JOB_POSITIONS,
        component: ProfileJobPositions,
      },
      {
        path: 'my-certificates',
        name: Routes.PROFILE_JOB_CERTIFICATES,
        component: UserCertificates,
      },
    ],
  },
  {
    path: '/profile-edit',
    name: Routes.PROFILE_EDIT,
    component: ProfileEdit,
  },
  {
    path: '/trainings/:slug',
    name: Routes.TRAINING_INNER,
    component: TrainingInner,
  },
  {
    path: '/password-reset',
    name: Routes.PASSWORD_RESET,
    component: PasswordReset,
  },
  {
    path: '/company-user-password-set',
    name: Routes.COMPANY_USER_PASSWORD_SET,
    component: CompanyUserPasswordSet,
  },
  {
    path: '/email-verify',
    name: Routes.EMAIL_VERIFY,
    component: EmailVerify,
  },
  {
    path: '/password-create-success',
    name: Routes.PASS_CREATE_SUCCESS,
    component: PassCreateSuccess,
  },
  {
    path: '/employee-confirm',
    name: Routes.EMPLOYEE_CONFIRM,
    component: EmployeeConfirm,
  },
  {
    path: '/training-centers',
    name: Routes.TRAINING_CENTERS,
    component: TrainingCenters,
  },
  {
    path: '/training-centers/:slug',
    name: Routes.TRAINING_CENTER_INNER,
    component: TrainingCenterInner,
  },
  {
    path: '/about-us',
    name: Routes.ABOUT_US,
    component: AboutUs,
  },
  {
    path: '/faq',
    name: Routes.FAQ,
    component: Faq,
  },
  {
    path: '/contact',
    name: Routes.CONTACT,
    component: Contact,
  },
  {
    path: '/support',
    name: Routes.SUPPORT,
    component: Support,
  },
  {
    path: '/how-to-apply',
    name: Routes.HOW_TO_APPLY,
    component: HowToApply,
  },
  {
    path: '/prices',
    name: Routes.PRICES,
    component: Prices,
  },
  {
    path: '/terms-of-service',
    name: Routes.TERMS_OF_SERVICE,
    component: TermsOfService,
  },
  {
    path: '/privacy-policy',
    name: Routes.PRIVACY_POLICY,
    component: PrivacyPolicy,
  },
  {
    path: '/job-positions',
    name: Routes.JOB_POSITIONS,
    component: JobPositions,
  },
  {
    path: '/job-positions/:slug',
    name: Routes.JOB_POSITION_INNER,
    component: JobPositionInner,
  },
  {
    path: '/new-job-position',
    name: Routes.NEW_JOB_POSITION,
    component: NewJobPosition,
  },
  {
    path: '/edit-job-position/:slug',
    name: Routes.EDIT_JOB_POSITION,
    component: EditJobPosition,
  },
  {
    path: '*',
    name: Routes.NOT_FOUND,
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to: Route, from: Route, savedPosition: any): any => {
    const topPosition = { x: 0, y: 0 }

    if (to.name !== from.name) return topPosition

    return savedPosition
  },
})

router.beforeEach(beforeEach)

export default router
