import { IDocumentResponse } from '@/interfaces/user/UserInterface'

export default interface IClassifierResponse {
  id: number
  code: string
  title: string
  description: string
  classifier_parent_id: number
  class_category_id: number
  training_count: number
  expire_in_months: number
  icon: IDocumentResponse

  industry_id?: number
  industry_title?: string
  standard_id?: number
  standard_title?: string
  validity?: number | null
}

export interface IClassifiersById {
  value: number
  label: string
}

export interface IClassifierForm {
  id: number
  title: string
  description: string
  validity?: number | null
}

export interface INewClassifier {
  title: string
  description: string
  validity: number
  classifier_parent_id: string
  class_category_id: string
}

export enum Industry {
  wind = 'is_wind',
  nuclear = 'is_nuclear',
  hydro = 'is_hydro',
  'oil-gas' = 'is_oil_and_gas',
  solar = 'is_solar',
  biofuel = 'is_biomass',
}
