









import { Component, Watch } from 'vue-property-decorator'
import AbstractField from '@/components/form/fields/AbstractField.vue'

@Component
export default class WysiwygField extends AbstractField {
  @Watch('model')
  emitChange(): void {
    this.$emit('input', this.model)
    this.$emit('clearError', this.id)
  }
}
