































import { Component, Vue } from 'vue-property-decorator'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import IndustriesInfo from '@/components/home/IndustriesInfo.vue'
import Cta from '@/components/home/Cta.vue'
import Routes from '@/router/routes'

@Component({
  components: { Cta, IndustriesInfo, Breadcrumbs },
})
export default class AboutUs extends Vue {
  Routes = Routes

  goTo(name: string): void {
    this.$router.push({ name }).catch(() => {})
  }
}
