import { IDocumentResponse } from '@/interfaces/user/UserInterface'
import Model from '@/classes/model'

export default class DocumentClass extends Model {
  type!: string
  file!: string | File
  createdUserId?: number
  fileName?: string
  fileSize?: number
  fileType?: string
  filePath?: string

  constructor(data: IDocumentResponse) {
    super(data)

    this.type = data.type
    this.file = data.file
    this.createdUserId = data.created_user_id
    this.fileName = data.file_name
    this.fileSize = data.file_size
    this.fileType = data.file_type
    this.filePath = data.file_path
  }

  getImagePreview(): string {
    return this.file instanceof File
      ? URL.createObjectURL(this.file)
      : `data:image/${this.fileType === 'svg' ? `${this.fileType}+xml` : this.fileType};base64,${this.file}`
  }

  getImageURL(): string | undefined {
    return this.filePath || this.getImagePreview()
  }

  documentIsLocal(): boolean {
    return this.file instanceof File
  }

  get fileExists(): boolean {
    return !!this.file
  }
}
