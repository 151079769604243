
































































































































import { Component, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { BvTableFieldArray } from 'bootstrap-vue/src/components/table'
import Search from '@/components/Search.vue'
import Classifier, { ClassifierType } from '@/classes/training/classifier'
import { IMeta, IPaginatedResponse } from '@/interfaces/ResponseInterface'
import { ITableProperties, ITableSortChanged } from '@/interfaces/TableInterface'
import ClassifierService from '@/services/ClassifierService'
import SelectOption from '@/interfaces/SelectOption'
import Pagination from '@/components/Pagination.vue'
import EmptyList from '@/components/EmptyList.vue'
import EditClassifierModal from '@/components/admin/classifiers/EditClassifierModal.vue'
import scrollToRef from '@/helpers/scroll-to-ref-helper'
import CreateClassifierModal from '@/components/admin/classifiers/CreateClassifierModal.vue'

enum ClassifierFilters {
  search = 'q',
  type = 'type',
}

@Component({
  components: { CreateClassifierModal, EditClassifierModal, EmptyList, Pagination, Search },
})
export default class AdminClassifiers extends Vue {
  ClassifierType = ClassifierType
  private loading: boolean = false
  private classifiers: Classifier[] = []
  private meta: IMeta | any = {}

  public tableProperties: ITableProperties = {
    currentPage: 1,
    perPage: 1,
    filters: {
      search: '',
      type: ClassifierType.industry,
    },
  }

  private promiseMap = {
    [ClassifierType.industry]: ClassifierService.getIndustryList,
    [ClassifierType.standard]: ClassifierService.getStandardList,
    [ClassifierType.trainingType]: ClassifierService.getTrainingTypeList,
  }

  public created(): void {
    this.parseQueryParams()
    this.loadClassifiers()

    this.$watch(() => this.$route, this.loadClassifiers)
  }

  private loadClassifiers(): void {
    this.loading = true

    let { query } = this.$router.currentRoute
    query = _.cloneDeep(query)

    const call: (query?: any, perPage?: number) => Promise<IPaginatedResponse<Classifier[]>> = _.get(
      this.promiseMap,
      this.tableProperties.filters.type
    )

    delete query[ClassifierFilters.type]

    call(query).then((response: IPaginatedResponse<Classifier[]>) => {
      this.classifiers = response.data
      this.meta = response.meta
      this.loading = false
    })
  }

  private onTableSortChange(event: ITableSortChanged): void {
    this.tableProperties.sortBy = event.sortBy
    this.tableProperties.order = event.sortDesc ? 'desc' : 'asc'

    this.changeRouteByProperties()
  }

  private onPageChange(page: number): void {
    this.tableProperties.currentPage = page

    this.changeRouteByProperties()
  }

  private onSearch(search: string): void {
    this.tableProperties.filters.search = search

    this.changeRouteByProperties()
  }

  private changeRouteByProperties(): void {
    const { name, query, params }: any = this.$router.currentRoute

    const { currentPage, sortBy, order, filters } = this.tableProperties

    const routeQuery = {
      ...query,
      page: currentPage,
      sort_by: sortBy,
      order,
    }

    if (filters.search !== '') routeQuery[ClassifierFilters.search] = filters.search
    else delete routeQuery[ClassifierFilters.search]

    if (filters.type) routeQuery[ClassifierFilters.type] = filters.type
    else delete routeQuery[ClassifierFilters.type]

    this.$router
      .replace({
        name,
        params,
        query: routeQuery,
      })
      .catch(() => {})
  }

  private parseQueryParams(): void {
    const { query } = this.$router.currentRoute

    this.tableProperties.currentPage = Number(_.get(query, 'page', 1))
    this.tableProperties.filters.search = _.get(query, ClassifierFilters.search, '')
    this.tableProperties.filters.sortBy = _.get(query, 'sort_by')
    this.tableProperties.filters.order = _.get(query, 'order')
    this.tableProperties.filters.type = _.get(query, ClassifierFilters.type, ClassifierType.industry)
  }

  private onPageInput(id: string): void {
    this.loading = true

    scrollToRef(id)
  }

  private getFields(): BvTableFieldArray {
    const fields = [
      {
        key: 'id',
        label: 'Id',
        sortable: true,
      },
      {
        key: 'title',
        label: 'Title',
        sortable: true,
      },
    ]

    if (this.tableProperties.filters.type !== ClassifierType.industry) {
      fields.push({
        key: 'industry_title',
        label: 'Industry',
        sortable: true,
      })

      if (this.tableProperties.filters.type !== ClassifierType.standard) {
        fields.push({
          key: 'standard_title',
          label: 'Standard',
          sortable: true,
        })
      }
    }

    fields.push({
      key: 'description',
      label: 'Has description?',
      sortable: false,
    })

    if (this.tableProperties.filters.type === ClassifierType.trainingType) {
      fields.push({
        key: 'validity',
        label: 'Validity (in years)',
        sortable: false,
      })
    }

    fields.push({
      key: 'actions',
      label: '',
      sortable: false,
    })

    return fields
  }

  get classifierTypeSelections(): SelectOption[] {
    return [
      {
        value: ClassifierType.industry,
        text: 'Industry',
      },
      {
        value: ClassifierType.standard,
        text: 'Standard',
      },
      {
        value: ClassifierType.trainingType,
        text: 'Training Type',
      },
    ]
  }
}
