enum Routes {
  HOME = 'home',
  LOGIN = 'login',
  SOCIAL_LOGIN = 'social-login',
  USER_REGISTER = 'user-register',
  COMPANY_REGISTER = 'company-register',
  TRAINING_REGISTER = 'training-register',
  NEWS = 'news',
  NEW_TRAINING = 'new-training',
  ADMIN_PANEL = 'admin',
  ADMIN_USERS_LIST = 'admin/users-list',
  ADMIN_CLASSIFIERS = 'admin/classifiers',
  ADMIN_BOUGHT_TRAININGS_LIST = 'admin/bought-trainings-list',
  CHECKOUT = 'checkout',
  STRIPE_CALLBACK = 'stripe-callback',
  CART = 'cart',
  SUCCESS = 'success',
  PROFILE = 'profile',
  PROFILE_TRAININGS = 'profile/trainings',
  PROFILE_MY_ORDERS = 'profile/my-orders',
  PROFILE_DISCOUNTS = 'profile/discounts',
  PROFILE_STUDENTS = 'profile/students',
  PROFILE_EMPLOYEES = 'profile/employees',
  PROFILE_JOB_POSITIONS = 'profile/job-positions',
  PROFILE_JOB_CERTIFICATES = 'profile/my-certificates',
  PROFILE_WISHLIST = 'profile/wishlist',
  PROFILE_EDIT = 'profile/edit',
  TRAININGS = 'trainings',
  TRAINING_INNER = 'trainings/inner',
  TRAINING_CENTERS = 'training-centers',
  TRAINING_CENTER_INNER = 'training-centers/inner',
  EMAIL_VERIFY = 'email-verify',
  PASS_CREATE_SUCCESS = 'pass-create-success',
  EMPLOYEE_CONFIRM = 'employee-confirm',
  PASSWORD_RESET = 'password-reset',
  COMPANY_USER_PASSWORD_SET = 'company-user-password-set',
  ABOUT_US = 'about-us',
  FAQ = 'faq',
  CONTACT = 'contact',
  SUPPORT = 'support',
  HOW_TO_APPLY = 'how-to-apply',
  PRICES = 'prices',
  TERMS_OF_SERVICE = 'terms-of-service',
  PRIVACY_POLICY = 'privacy-policy',
  NOT_FOUND = 'not-found',
  JOB_POSITIONS = 'job-positions',
  JOB_POSITION_INNER = 'job-positions/inner',
  NEW_JOB_POSITION = 'new-job-position',
  EDIT_JOB_POSITION = 'edit-job-position',
}

export default Routes
