import { UserType } from '@/classes/user/user'

const UserTitle: any = {
  [UserType.admin]: 'Admin',
  [UserType.company]: 'Company',
  [UserType.trainingCenter]: 'Training Center',
  [UserType.user]: 'User',
}

export default UserTitle
