enum IndustryTypes {
  WIND = 'isWind',
  NUCLEAR = 'isNuclear',
  OIL_AND_GAS = 'isOilAndGas',
  HYDRO = 'isHydro',
  SOLAR = 'isSolar',
  BIOMASS = 'isBiomass',
}

export const IndustryTypeData: any = {
  [IndustryTypes.WIND]: {
    title: 'Wind',
    code: 'wind',
  },
  [IndustryTypes.NUCLEAR]: {
    title: 'Nuclear',
    code: 'nuclear',
  },
  [IndustryTypes.OIL_AND_GAS]: {
    title: 'Oil & Gas',
    code: 'oil-gas',
  },
  [IndustryTypes.HYDRO]: {
    title: 'Hydro',
    code: 'hydro',
  },
  [IndustryTypes.SOLAR]: {
    title: 'Solar',
    code: 'solar',
  },
  [IndustryTypes.BIOMASS]: {
    title: 'Biomass',
    code: 'biofuel',
  },
}

export default IndustryTypes
