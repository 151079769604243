




































































































































































import { Component, Prop } from 'vue-property-decorator'
import _ from 'lodash'
import { namespace } from 'vuex-class'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import TextArea from '@/components/form/fields/TextArea.vue'
import TrainingLocationDate from '@/classes/training/training-location-date'
import { LocationTypes, LocationTypesData } from '@/configs/training-steps'
import ProfileEditAbstract from '@/components/profile/edit/ProfileEditAbstract.vue'
import TrainingService from '@/services/TrainingService'
import ServicesField from '@/components/form/fields/training/ServicesField.vue'
import TrainingLocationDateService from '@/classes/training/training-location-date-service'

const Global = namespace('Global')

@Component({
  components: { TextArea, ServicesField },
})
export default class TrainingEditModal extends ProfileEditAbstract {
  services: TrainingLocationDateService[] = []
  selectedServices: Array<number> = []
  training: TrainingLocationDate | null = null
  LocationTypes = LocationTypes
  @Prop() currentTrainingUuid!: string

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  visible: boolean = false
  modalData: TrainingLocationDate = null!
  modalDataDateRange: string = ''

  loading: boolean = false
  loadingButton: boolean = false

  public created(): void {
    this.$watch(
      () => this.visible,
      async () => {
        if (this.visible) {
          await this.fetchData()
        }
      }
    )
  }

  private async fetchData() {
    this.loading = true

    const response = await TrainingService.getEditTrainingData(this.currentTrainingUuid)
    this.training = new TrainingLocationDate(response)
    this.modalData = _.cloneDeep(this.training)
    this.modalDataDateRange = `${this.modalData.from},${this.modalData.to}`
    this.services = this.modalData.trainingLocationDateServices as TrainingLocationDateService[]
    this.selectedServices = this.services.filter((service) => service.isActive).map((service) => service.id)

    this.loading = false
  }

  async submit(): Promise<void> {
    this.loadingButton = true

    try {
      const payload = {
        ...this.modalData,
        from: this.modalDataDateRange.split(',')[0],
        to: this.modalDataDateRange.split(',')[1],
        trainingLocationDateServices: this.setTrainingLocationDateServicesValues(this.selectedServices, this.services),
      } as TrainingLocationDate

      if (JSON.stringify(this.training) === JSON.stringify(payload)) {
        this.showSnackbar({
          type: SnackbarType.info,
          text: 'Nothing to change',
        })
        this.loadingButton = false

        return
      }

      await TrainingService.updateTraining(payload)

      this.showSnackbar({
        type: SnackbarType.success,
        text: 'Training successfully updated',
      })
      this.$root.$emit('trainingUpdate:success')
      this.close()
      this.loadingButton = false
    } catch (error: any) {
      this.loadingButton = false
    }
  }

  public toggle(): void {
    this.visible = !this.visible
  }

  private reset(): void {
    this.modalData = _.cloneDeep(this.training as TrainingLocationDate)

    const { locationOptions, countryOptions, dateRangeInput }: any = this.$refs

    if (locationOptions)
      locationOptions.inputValue = this.getFirstUpperCaseString(this.training!.trainingLocation.location)

    if (countryOptions) countryOptions.inputValue = this.training!.trainingLocation.country

    if (dateRangeInput) {
      this.modalDataDateRange = `${this.modalData.from},${this.modalData.to}`
      dateRangeInput.initialValue = { start: this.modalData.from, end: this.modalData.to }
    }
    this.services = this.modalData.trainingLocationDateServices as TrainingLocationDateService[]
    this.selectedServices = this.services.filter((service) => service.isActive).map((service) => service.id)
  }

  public deleteTraining(): void {
    if (!this.training?.uuid) {
      return
    }
    this.loading = true
    TrainingService.deleteTraining(this.training.uuid)
      .then(() => {
        this.close()
        this.showSnackbar({
          type: SnackbarType.info,
          text: 'Training deleted',
        })
        this.$root.$emit('trainingUpdate:success')
      })
      .catch(() => {
        this.showSnackbar({
          type: SnackbarType.error,
          text: 'Training could not be deleted',
        })
      })
      .finally(() => {
        this.loading = false
      })
  }

  public close(): void {
    this.visible = false
  }

  private isSelected(id: number): boolean {
    return this.services.some((service) => service.id === id)
  }

  private setTrainingLocationDateServicesValues(
    ids: Array<number>,
    services: TrainingLocationDateService[]
  ): TrainingLocationDateService[] {
    const updatedServices: TrainingLocationDateService[] = []
    services.forEach((item) => {
      item.isActive = ids.includes(item.id)
      updatedServices.push(item)
    })

    return updatedServices
  }

  private getFirstUpperCaseString(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  get locationTypes(): any {
    return Object.keys(LocationTypes).map((locationType: string) => ({
      value: locationType,
      text: LocationTypesData[locationType],
    }))
  }
}
