import Model from '@/classes/model'
import IInvoiceResponse from '@/interfaces/InvoiceInterface'
import OrderItem from './order-item'

export default class Invoice extends Model {
  number!: string
  type!: string
  reportPath!: string
  isInvoice!: boolean
  name!: string
  trainingCenterName!: string
  order!: OrderItem
  createdAt!: string

  constructor(data: IInvoiceResponse) {
    super(data)

    this.number = data.number
    this.type = data.type
    this.reportPath = data.report_path
    this.isInvoice = data.is_invoice
    this.createdAt = data.created_at
    this.name = data.name
    this.trainingCenterName = data.training_center_name
    this.order = this.setRelation(data.order, OrderItem)
  }
}
