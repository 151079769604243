







































import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ICartResponseData } from '@/interfaces/CartInterface'
import Routes from '@/router/routes'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import IConfirmDialog from '@/interfaces/ConfirmDialogInterface'
import TrainingLocationDate from '@/classes/training/training-location-date'

const Global = namespace('Global')

const Cart = namespace('Cart')

const Auth = namespace('Auth')

@Component({
  components: {},
})
export default class CartActionButton extends Vue {
  @Prop() private trainingUuid!: string
  @Prop() private training!: TrainingLocationDate
  @Prop() private customClass!: string
  @Prop() private isRemove!: boolean
  private loading: boolean = false
  private isInCart: boolean = false

  @Global.Action
  private showConfirmDialog!: (confirmDialog: IConfirmDialog) => void

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Cart.Getter
  private cartItems!: ICartResponseData[]

  @Auth.Getter
  private isLoggedIn!: boolean

  @Cart.Action
  private fetchCart!: () => Promise<ICartResponseData[]>

  @Cart.Action
  private addItemToCart!: (trainingUuid: string) => Promise<ICartResponseData>

  @Cart.Action
  private removeItemFromCart!: (cartItem: ICartResponseData) => Promise<void>

  private favoriteToggle(): void {
    this.$emit('favoriteToggle', this.trainingUuid)
  }

  mounted(): void {
    this.isInCart = !!this.cartItems.find((item) => item.training_location_date.uuid === this.trainingUuid)
  }

  private async cartItemAdd(trainingUuid: string) {
    if (!this.isLoggedIn) {
      await this.$router.push({ name: Routes.LOGIN, params: { data: JSON.stringify({ trainingUuid }) } })

      return
    }

    try {
      this.loading = true
      await this.addItemToCart(trainingUuid)
      await this.fetchCart()
      this.isInCart = !!this.cartItems.find((item) => item.training_location_date.uuid === this.trainingUuid)
      this.loading = false

      this.showSnackbar({
        type: SnackbarType.success,
        text: 'Training added to cart',
      })
    } catch (error: any) {
      this.showSnackbar({
        type: SnackbarType.error,
        text: error.errors.training_location_date_uuid.join(';\n') || 'Adding to cart failed',
      })
      this.loading = false
    }
  }

  private async cartItemDelete() {
    this.showConfirmDialog({
      text: 'Are you sure you want to delete this cart item?',
      confirmText: 'Delete',
      cancelText: 'Cancel',
      confirmAction: async () => {
        try {
          this.loading = true

          const cartItem = this.cartItems.find((item) => item.training_location_date.uuid === this.trainingUuid)

          if (!cartItem) return
          await this.removeItemFromCart(cartItem)
          await this.fetchCart()
          this.$parent.$emit('cartItemRemove:success')

          this.showSnackbar({
            type: SnackbarType.success,
            text: 'Training removed from cart',
          })
          this.loading = false
        } catch (error) {
          this.showSnackbar({
            type: SnackbarType.error,
            text: 'Removing from cart failed',
          })
          this.loading = false
        }
      },
    })
  }
}
