

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { StoryInterface } from '@/interfaces/StoryInterface'

@Component
export default class FeaturedStory extends Vue {
  showNext(): void {
    this.$root.$emit('carouselNext')
  }
  @Prop() private storyData!: StoryInterface
  @Prop() private index!: number
  @Prop() private total!: number
}
