
















































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import TextArea from '@/components/form/fields/TextArea.vue'
import http from '@/classes/http'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'

const Global = namespace('Global')

@Component({
  components: { TextArea, Breadcrumbs },
})
export default class Contact extends Vue {
  loading: boolean = false
  data: any = {
    name: '',
    email: '',
    companyName: '',
    message: '',
  }
  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  get disabled(): boolean {
    return !this.data.name || !this.data.email || !this.data.companyName || !this.data.message
  }

  async recaptcha() {
    await this.$recaptchaLoaded()

    const token = await this.$recaptcha('login')
    this.submit(token)
  }

  submit(token: string): void {
    this.loading = true

    http
      .post('contact-form', { ...this.data, 'g-recaptcha-response': token })
      .then(() => {
        this.showSnackbar({
          type: SnackbarType.success,
          text: 'Your message was successfully sent!',
        })

        this.data = {
          name: '',
          email: '',
          companyName: '',
          message: '',
        }
      })
      .finally(() => (this.loading = false))
  }
}
