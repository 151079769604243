import IClassifierResponse, { IClassifierForm } from '@/interfaces/training/ClassifierInterface'
import DocumentClass from '@/classes/user/document'
import Model from '@/classes/model'

export enum ClassifierType {
  industry = 'industry',
  standard = 'standard',
  trainingType = 'training-type',
}

export default class Classifier extends Model {
  code!: string
  title!: string
  description!: string
  classifierParentId!: number
  classCategoryId!: number
  expireInMonths!: number
  icon!: DocumentClass
  trainingCount!: number

  industryId?: number
  industryTitle?: string
  standardId?: number
  standardTitle?: string
  validity?: number | null = null

  constructor(data: IClassifierResponse) {
    super(data)

    this.code = data.code
    this.title = data.title
    this.description = data.description
    this.classifierParentId = data.classifier_parent_id
    this.classCategoryId = data.class_category_id
    this.trainingCount = data.training_count
    this.expireInMonths = data.expire_in_months

    this.industryId = data.industry_id
    this.industryTitle = data.industry_title
    this.standardId = data.standard_id
    this.standardTitle = data.standard_title
    this.validity = data.validity

    this.icon = this.setRelation(data.icon, DocumentClass)
  }

  toFormData(): IClassifierForm {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      validity: this.validity,
    }
  }
}
