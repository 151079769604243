
























import { Component, Vue } from 'vue-property-decorator'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Routes from '@/router/routes'
import AuthService from '@/services/AuthService'

@Component({
  components: { Breadcrumbs },
})
export default class EmployeeConfirm extends Vue {
  private message = ''
  private success: boolean | null = null
  Routes = Routes

  created(): void {
    if (this.$route.query && this.$route.query.employee_accept_url) {
      const apiUrl = this.$route.fullPath.split('employee_accept_url=').pop() as string
      this.proceedEmployeeConfirm(decodeURIComponent(apiUrl))
    } else {
      this.success = false
      this.message = 'Employee confirmation failed.'
    }
  }

  private async proceedEmployeeConfirm(url: string): Promise<void> {
    try {
      await AuthService.confirmEmployee(url)
      this.success = true
      this.message = 'Employee confirmation succeeded!'
    } catch (error) {
      this.success = false
      this.message = 'Employee confirmation failed. Try again later.'
    }
  }
}
