














































































import { Component, Vue } from 'vue-property-decorator'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import IndividualRegister from '@/components/account/IndividualRegister.vue'
import TrainingRegister from '@/components/account/TrainingRegister.vue'
import CompanyRegister from '@/components/account/CompanyRegister.vue'
import Routes from '@/router/routes'

@Component({
  components: { CompanyRegister, TrainingRegister, IndividualRegister, Breadcrumbs },
})
export default class Register extends Vue {
  Routes = Routes
  private loading: boolean = false
  private agree: boolean = false
  private selectedTab: number = this.getTabIndex()
  bus = new Vue()
  private registered = false

  private async registerUser(): Promise<void> {
    this.loading = true
    await this.$recaptchaLoaded()

    const token = await this.$recaptcha('login')

    this.bus.$emit('submit', token)
  }
  private isRoute(routeName: string): boolean {
    return this.$route.name === routeName
  }
  private linkToRoute(routeName: string) {
    if (!this.isRoute(routeName)) this.$router.push({ name: routeName })
  }

  private getTabIndex() {
    if (this.$route.name === Routes.USER_REGISTER) {
      return 0
    }

    return this.$route.name === Routes.COMPANY_REGISTER ? 1 : 2
  }

  private scrollToId(id: string): void {
    const element = document.getElementById(id)

    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}
