import { AxiosResponse } from 'axios'
import http from '@/classes/http'
import { IJobPositionsPayload } from '@/interfaces/JobPositionsInterface'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'

export default class JobPositionsService {
  static async getJobPositions(page: number = 1, per_page: number = 10): Promise<any> {
    return http
      .get(`job-offers`, {
        params: {
          per_page,
          page,
        },
      })
      .then((response: AxiosResponse<IPaginatedResponse<any[]>>) => ({
        data: response.data.data,
        meta: response.data.meta,
      }))
  }

  static getJobPosition(id: string): Promise<any> {
    return http.get(`job-offers/${id}`)
  }

  static addNewJobPosition(data: IJobPositionsPayload): Promise<AxiosResponse> {
    return http.post('job-offers', data)
  }

  static updateJobPosition(data: IJobPositionsPayload, jobId: string): Promise<AxiosResponse> {
    return http.put(`job-offers/${jobId}`, data)
  }

  static deleteJobPosition(data: IJobPositionsPayload, jobId: string): Promise<AxiosResponse> {
    return http.delete(`job-offers/${jobId}`)
  }

  static applyAsRegisteredUser(jobId: number): Promise<AxiosResponse> {
    return http.put(`job-offers/${jobId}/apply-for-job`)
  }

  static applyAsNewUser(jobId: number, data: FormData): Promise<AxiosResponse> {
    return http
      .post(`job-offers/${jobId}/new-user-apply-for-job`, data, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })
      .then((response: AxiosResponse<any>) => response)
  }

  static async getMyJobPositions(page: number = 1, per_page: number = 10): Promise<any> {
    return http
      .get('job-offers/my', {
        params: {
          per_page,
          page,
        },
      })
      .then((response: AxiosResponse<IPaginatedResponse<any[]>>) => ({
        data: response.data.data,
        meta: response.data.meta,
      }))
  }

  static async getJobPositionApplicants(jobId: number): Promise<any> {
    return http.get(`job-offers/${jobId}/applicants`).then((response: AxiosResponse<any>) => response.data.data)
  }
}
