import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { trainingMutations } from '@/store/mutations/training.mutations'
import TrainingService from '@/services/TrainingService'
import trainingActions from '@/store/actions/training.actions'
import ITrainingDataForm, {
  ITrainingFormLocation,
  ITrainingFormLocationDate,
  ITrainingFormLocationDateService,
} from '@/interfaces/form-interfaces/TrainingDataFormInterface'
import trainingGetters from '@/store/getters/training.getters'

export interface ISteps {
  activeStep: number
  completedStep: number
}

interface IStates {
  init?: boolean
  changed: boolean
}

const defaultTraining: ITrainingDataForm = {
  activeStep: 1,
  completedStep: 1,
  isFinished: false,
  trainingLocations: [],
  trainingLocationDates: [],
  trainingLocationDateServices: [],
  trainingTypeDescription: '',
}

@Module({ namespaced: true })
class Training extends VuexModule {
  public trainingData: ITrainingDataForm = defaultTraining
  public oldTrainingData!: ITrainingDataForm
  public trainingIsChangedState: boolean = false
  public trainingIsInitializedState: boolean = false
  public loadingState: boolean = false

  @Mutation
  public setTraining(newData: ITrainingDataForm): void {
    this.trainingData = newData
  }

  @Mutation
  public setTrainingLocations(locations: ITrainingFormLocation[]): void {
    this.trainingData.trainingLocations = locations
  }

  @Mutation
  public setTrainingLocationDates(dates: ITrainingFormLocationDate[]): void {
    this.trainingData.trainingLocationDates = dates
  }

  @Mutation
  public setTrainingLocationDateServices(services: ITrainingFormLocationDateService[]): void {
    this.trainingData.trainingLocationDateServices = services
  }

  @Mutation
  public setOldTraining(data: ITrainingDataForm): void {
    this.oldTrainingData = data
  }

  @Mutation
  public setLoading(loading: boolean): void {
    this.loadingState = loading
  }

  @Mutation
  public setStepData(stepData: ISteps): void {
    this.trainingData.activeStep = stepData.activeStep
    this.trainingData.completedStep = stepData.completedStep
  }

  @Mutation
  public setState(state: boolean): void {
    this.trainingIsChangedState = state
  }

  @Mutation
  public setInitialized(init: boolean): void {
    this.trainingIsInitializedState = init
  }

  @Action
  appendToTraining(data: Record<string, any>): void {
    this.context.commit(trainingMutations.SET_TRAINING, {
      ...this.context.getters[trainingGetters.TRAINING],
      ...data,
    })
  }

  @Action
  backupData(): void {
    this.context.commit(trainingMutations.SET_OLD_TRAINING, this.context.getters[trainingGetters.TRAINING])
  }

  @Action
  setNewTraining(training: ITrainingDataForm): void {
    this.context.commit(trainingMutations.SET_TRAINING, training)
  }

  @Action
  setStates(states: IStates): void {
    if (states.init) this.context.commit(trainingMutations.SET_INITIALIZED_STATE, states.init)
    this.context.commit(trainingMutations.SET_DATA_STATE, states.changed)
  }

  @Action
  addTrainingLocationDate(trainingLocationUuid: string): void {
    const training: ITrainingDataForm = this.context.getters[trainingGetters.TRAINING]

    if (!training.uuid) return

    const newDate: ITrainingFormLocationDate = {
      trainingUuid: training.uuid,
      trainingLocationUuid,
      from: DateTime.now().toFormat('yyyy-MM-dd'),
      to: DateTime.now().toFormat('yyyy-MM-dd'),
      seats: 0,
      vat: 0,
      price: 0,
      isRefresh: false,
      language: 'EN',
    }

    this.context.commit(trainingMutations.SET_TRAINING_LOCATION_DATES, [...training.trainingLocationDates, newDate])
  }

  @Action
  resetDataToOld(): void {
    const training: ITrainingDataForm = this.context.getters[trainingGetters.TRAINING]

    const oldTraining: ITrainingDataForm = this.context.getters[trainingGetters.OLD_TRAINING]

    if (!oldTraining) {
      this.context.dispatch(trainingActions.RESET_TRAINING)

      return
    }

    const newTrainingObject: ITrainingDataForm = _.cloneDeep(oldTraining)

    const isInitialized: boolean = this.context.getters[trainingGetters.TRAINING_IS_INITIALIZED]
    newTrainingObject.activeStep = training.activeStep
    newTrainingObject.completedStep = training.completedStep

    newTrainingObject.isFinished = 'isFinished' in oldTraining ? oldTraining.isFinished : false

    if (isInitialized && _.has(training, 'uuid')) newTrainingObject.uuid = training.uuid

    this.context.commit(trainingMutations.SET_TRAINING, newTrainingObject)
    this.context.commit(trainingMutations.SET_DATA_STATE, false)
  }

  @Action
  resetTraining(): void {
    this.context.commit(trainingMutations.SET_TRAINING, defaultTraining)
    this.context.commit(trainingMutations.SET_INITIALIZED_STATE, false)
    this.context.commit(trainingMutations.SET_DATA_STATE, false)
  }

  @Action
  changeLoadingState(loading: boolean): void {
    this.context.commit(trainingMutations.SET_LOADING, loading)
  }

  // Submit Actions

  @Action
  async submitTrainingData(steps: ISteps): Promise<void> {
    switch (this.trainingData.activeStep) {
      case 1:
      case 2:
      case 3:
        await this.context.dispatch(trainingActions.SUBMIT_TRAINING_INFO, steps)
        break
      case 4:
        await this.context.dispatch(trainingActions.SUBMIT_TRAINING_LOCATIONS, steps)
        break
      case 5:
      case 6:
        await this.context.dispatch(trainingActions.SUBMIT_TRAINING_LOCATION_DATES, steps)
        break
      case 7:
        await this.context.dispatch(trainingActions.SUBMIT_TRAINING_LOCATION_DATE_SERVICES, steps)
        break
      default:
        break
    }
  }

  @Action
  async createTraining(steps: ISteps): Promise<void> {
    await TrainingService.createNewTraining(
      this.context.getters[trainingGetters.TRAINING_BASE],
      steps.activeStep,
      steps.completedStep
    ).then((response: ITrainingDataForm) => {
      this.context.dispatch(trainingActions.BACKUP_DATA)
      this.context.dispatch(trainingActions.SET_NEW_TRAINING, response)
      this.context.dispatch(trainingActions.SET_STATES, { changed: false, init: true })
    })
  }

  @Action
  async submitTrainingInfo(steps: ISteps): Promise<void> {
    await TrainingService.submitTrainingInfo(
      this.context.getters[trainingGetters.TRAINING_BASE],
      steps.activeStep,
      steps.completedStep
    ).then((response: ITrainingDataForm) => {
      this.context.dispatch(trainingActions.BACKUP_DATA)
      this.context.dispatch(trainingActions.APPEND_TO_TRAINING, response)
      this.context.dispatch(trainingActions.SET_STATES, { changed: false })
    })
  }

  @Action
  async submitTrainingLocations(steps: ISteps): Promise<void> {
    await TrainingService.submitTrainingLocations(this.context.getters[trainingGetters.TRAINING_LOCATIONS]).then(
      (response: ITrainingFormLocation[]) => {
        const { activeStep, completedStep } = steps
        this.context.dispatch(trainingActions.BACKUP_DATA)
        this.context.commit(trainingMutations.SET_TRAINING_LOCATIONS, response)
        this.context.dispatch(trainingActions.APPEND_TO_TRAINING, { activeStep, completedStep })
        this.context.dispatch(trainingActions.SET_STATES, { changed: false })
      }
    )
  }

  @Action
  async submitTrainingLocationDates(steps: ISteps): Promise<void> {
    await TrainingService.submitTrainingLocationDates(
      this.context.getters[trainingGetters.TRAINING_LOCATION_DATES]
    ).then((response: ITrainingFormLocationDate[]) => {
      const { activeStep, completedStep } = steps
      this.context.dispatch(trainingActions.BACKUP_DATA)
      this.context.commit(trainingMutations.SET_TRAINING_LOCATION_DATES, response)
      this.context.dispatch(trainingActions.APPEND_TO_TRAINING, { activeStep, completedStep })
      this.context.dispatch(trainingActions.SET_STATES, { changed: false })
    })
  }

  @Action
  async submitTrainingLocationDateServices(steps: ISteps): Promise<void> {
    await TrainingService.submitTrainingLocationDateServices(
      this.context.getters[trainingGetters.TRAINING_LOCATION_DATE_SERVICES]
    ).then((response: ITrainingFormLocationDateService[]) => {
      const { activeStep, completedStep } = steps
      this.context.dispatch(trainingActions.BACKUP_DATA)
      this.context.commit(trainingMutations.SET_TRAINING_LOCAITON_DATE_SERVICES, response)
      this.context.dispatch(trainingActions.APPEND_TO_TRAINING, { activeStep, completedStep })
      this.context.dispatch(trainingActions.SET_STATES, { changed: false })
    })
  }

  @Action
  async publishTraining(): Promise<void> {
    await this.context.dispatch(trainingActions.APPEND_TO_TRAINING, { isFinished: true })

    const training: ITrainingDataForm = this.context.getters[trainingGetters.TRAINING_BASE]
    await TrainingService.submitTrainingInfo(training, training.activeStep, training.completedStep).then(() => {
      this.context.dispatch(trainingActions.RESET_TRAINING)
    })
  }

  @Action
  clearRelatedDates(locationUuid: string): void {
    const updatedDates = this.trainingLocationDates.filter((date) => {
      return date.trainingLocationUuid !== locationUuid
    })
    this.context.commit('setTrainingLocationDates', updatedDates)
  }

  @Action
  deleteTrainingLocationDateServices(): void {
    this.context.commit('setTrainingLocationDateServices', [])
  }

  @Action
  deleteTrainingLocationDates(): void {
    this.context.commit('setTrainingLocationDates', [])
    this.context.dispatch('deleteTrainingLocationDateServices')
  }

  @Action
  deleteTrainingLocations(): void {
    this.context.commit('setTrainingLocations', [])
    this.context.dispatch('deleteTrainingLocationDates')
  }

  @Action
  deleteTypeClassifierData(): void {
    this.trainingData.title = null
    this.trainingData.trainingTypeClassifierId = null
    this.context.dispatch('deleteTrainingLocations')
  }

  @Action
  deleteStandardClassifierData(): void {
    this.trainingData.standardClassifierId = null
    this.context.dispatch('deleteTypeClassifierData')
  }

  @Action
  deleteIndustryData(): void {
    this.trainingData.industryClassifierId = null
    this.context.dispatch('deleteStandardClassifierData')
  }

  get trainingBase(): any {
    return {
      uuid: this.trainingData.uuid ? this.trainingData.uuid : null,
      title: this.trainingData.title,
      isFinished: this.trainingData.isFinished,
      industryClassifierId: this.trainingData.industryClassifierId,
      standardClassifierId: this.trainingData.standardClassifierId,
      trainingTypeClassifierId: this.trainingData.trainingTypeClassifierId,
      trainingTypeDescription: this.trainingData.trainingTypeDescription
        ? this.trainingData.trainingTypeDescription
        : null,
    }
  }

  get loading(): boolean {
    return this.loadingState
  }

  get training(): ITrainingDataForm {
    return this.trainingData
  }

  get oldTraining(): ITrainingDataForm {
    return this.oldTrainingData
  }

  get trainingLocations(): ITrainingFormLocation[] {
    return this.trainingData.trainingLocations
  }

  get trainingLocationDates(): ITrainingFormLocationDate[] {
    return this.trainingData.trainingLocationDates
  }

  get trainingLocationDateServices(): ITrainingFormLocationDateService[] {
    return this.trainingData.trainingLocationDateServices
  }

  get trainingIsChanged(): boolean {
    return this.trainingIsChangedState
  }

  get trainingIsInitialized(): boolean {
    return this.trainingIsInitializedState
  }
}

export default Training
