import { ITrainingCenterLocationResponse } from '@/interfaces/user/TrainingCenterLocationInterface'
import Model from '@/classes/model'

export default class TrainingCenterLocation extends Model {
  userId!: number
  country!: string
  city!: string
  address!: string
  addressComplement!: string
  post!: string

  constructor(data: ITrainingCenterLocationResponse) {
    super(data)

    this.userId = data.user_id
    this.country = data.country
    this.city = data.city
    this.address = data.address
    this.addressComplement = data.address_complement
    this.post = data.post
  }

  get formattedLocation(): string {
    const { address, city, country } = this

    if (!Object.values({ address, city, country }).filter(Boolean).length) return '-'

    return Object.values({ address, city, country }).filter(Boolean).join(', ')
  }
}
