
















import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import ISnackbar from '@/interfaces/SnackbarInterface'

const Global = namespace('Global')

@Component
export default class Snackbar extends Vue {
  @Global.Getter
  private snackbar!: ISnackbar

  @Global.Action
  private hideSnackbar!: () => void

  private timer: number | null = null

  created(): void {
    this.$watch(
      () => this.snackbar,
      () => {
        if (this.snackbar) {
          this.timer = setTimeout(() => this.hideSnackbar(), this.snackbar.timeout || 5000)

          return
        }

        /* @ts-ignore */
        clearTimeout(this.timer)
      }
    )
  }
}
