






































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AxiosError } from 'axios'
import _ from 'lodash'
import { namespace } from 'vuex-class'
import Company from '@/classes/user/company'
import CompanyService from '@/services/CompanyService'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'
import CompanyDiscountService from '@/services/CompanyDiscountService'
import { ICompanyDiscountStoreData } from '@/interfaces/CompanyDiscountInterface'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import SelectOption from '@/interfaces/SelectOption'
import DatePickerField from '@/components/form/fields/DatePickerField.vue'

enum DiscountFields {
  companyId = 'company_id',
  percent = 'percent',
  startDate = 'start_date',
  endDate = 'end_date',
}

const Global = namespace('Global')

@Component({
  components: { DatePickerField },
})
export default class DiscountModal extends Vue {
  @Prop() refreshList!: () => void

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  DiscountFields = DiscountFields
  companies: Company[] = []
  data: ICompanyDiscountStoreData = {
    [DiscountFields.companyId]: null,
    [DiscountFields.percent]: null,
    [DiscountFields.startDate]: null,
    [DiscountFields.endDate]: null,
  }

  formErrors: any = {}

  created(): void {
    this.loadCompanies()
  }

  private loadCompanies(): void {
    CompanyService.list(9999).then((response: IPaginatedResponse<Company[]>) => (this.companies = response.data))
  }

  private submit(): void {
    CompanyDiscountService.store(this.data)
      .then(() => {
        this.refreshList()

        this.showSnackbar({
          type: SnackbarType.success,
          text: 'Discount successfully applied',
        })

        this.$bvModal.hide('addDiscount')
      })
      .catch((error: AxiosError) => {
        this.formErrors = error.response?.data.errors

        this.showSnackbar({
          type: SnackbarType.error,
          text: error.response?.data.message,
        })
      })
  }

  private getError(key: string): string[] | null {
    return _.get(this.formErrors, `${key}.0`, null)
  }

  private removeError(key: string): void {
    delete this.formErrors[key]
  }

  get isValid(): boolean {
    const { company_id, percent, start_date, end_date } = this.data

    return !!(
      company_id &&
      percent &&
      start_date &&
      end_date &&
      start_date <= end_date &&
      percent > 0 &&
      percent <= 100
    )
  }

  get companySelections(): SelectOption[] {
    return [
      ...this.companies.map((company: Company) => ({
        value: company.id,
        text: company.name,
      })),
    ]
  }
}
