





















































import { Component, Prop } from 'vue-property-decorator'
import SelectOption from '@/interfaces/SelectOption'
import AbstractField from '@/components/form/fields/AbstractField.vue'

@Component
export default class SelectField extends AbstractField {
  @Prop({ default: false }) private readonly searchable!: boolean
  @Prop({ default: [] }) private readonly options!: SelectOption[]
  @Prop({ default: false }) private readonly multiple!: boolean
  @Prop({ default: false }) private readonly isFilter!: boolean
  open: boolean = false
  searchText: string | null = null
  inputValue: string = ''
  currentOptions: SelectOption[] = []

  created(): void {
    this.$watch(
      () => this.options,
      () => this.setDefaultValue(),
      { immediate: true }
    )
  }

  resetInputValue(): void {
    this.setDefaultValue(this.value)
  }

  onSearch(): void {
    if (!this.searchText) this.currentOptions = this.options

    this.currentOptions = this.options.filter((item: SelectOption) =>
      item.text.toLowerCase().startsWith(String(this.searchText).toLowerCase())
    )
  }

  select(option: SelectOption | null): void {
    if (option && this.multiple) {
      let value = [...this.value, option.value]

      if (this.isSelected(option)) {
        value = value.filter((item: any) => String(item) !== String(option.value))
      }

      this.setDefaultValue(value)
      this.emitChange(value)

      this.searchText = null
      this.currentOptions = this.options

      return
    }

    this.searchText = null
    this.currentOptions = this.options
    this.inputValue = option ? `${option.text}${option.description ? ` (${option.description})` : ''}` : ''
    this.emitChange(option ? option.value : null)
    this.close()
  }

  private isSelected(option: SelectOption): boolean {
    if (this.multiple) return this.value.some((item: any) => String(item) === String(option.value))

    return String(this.value) === String(option.value)
  }

  private setDefaultValue(value: any = null): void {
    const defaultValue: any = value || this.value
    this.currentOptions = this.options

    if (this.multiple) {
      const arrayOfValue: any = []

      defaultValue.forEach((val: any) => {
        const selected = this.options.find((item: SelectOption) => String(item.value) === String(val))

        if (selected) arrayOfValue.push(selected.text.toString())
      })

      this.inputValue = arrayOfValue.join(', ')

      return
    }

    const selected = this.options.find((item: SelectOption) => String(item.value) === String(defaultValue))

    if (selected) this.inputValue = selected.text.toString()
  }

  private toggle(): void {
    if (this.readonly) return

    this.open = !this.open

    this.$nextTick(() => {
      const { searchInputField }: any = this.$refs

      if (searchInputField) searchInputField.focus()
    })
  }

  private close(): void {
    this.open = false
  }
}
