import { AxiosResponse } from 'axios'
import http from '@/classes/http'
import {
  IAddEmployeeData,
  IAddEmployeePayload,
  IEmployeePositionClassifierId,
} from '@/interfaces/EmployeeListInterface'

export default class EmployeesService {
  static setStatus(uuid: string, status: string): Promise<AxiosResponse> {
    return http.put(`company-employees/${uuid}/update-status`, { status })
  }

  static addNewEmployee(data: IAddEmployeeData): Promise<AxiosResponse> {
    const payload = this.addNewEmployeePayload(data)

    return http.post(`company-employees`, payload)
  }

  // Payloads

  private static addNewEmployeePayload(data: IAddEmployeeData): IAddEmployeePayload {
    const employeePositions: IEmployeePositionClassifierId[] = []

    // Temporary workaround disabling employee position multiselect
    if (Array.isArray(data.position_classifier_id)) {
      data.position_classifier_id.forEach((item) => {
        employeePositions.push({
          position_classifier_id: item,
        })
      })
    } else {
      employeePositions.push({ position_classifier_id: data.position_classifier_id })
    }

    return {
      employee: {
        ...data.employee,
      },
      company_employee_position: employeePositions,
    }
  }
}
