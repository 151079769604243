import { ICartResponseData, ICartLocalStorage, ICartsWithEmployees } from '@/interfaces/CartInterface'

const cartKey = 'cart'

const getCart = (): ICartLocalStorage | null => {
  const cart: any = localStorage.getItem(cartKey)
  try {
    return JSON.parse(cart)
  } catch (error) {
    clearCart()

    return null
  }
}

const setCart = (
  products: ICartResponseData[],
  totalPrice: string,
  employees: ICartsWithEmployees = [],
  totalDiscount: string,
  totalVat: string
): void => {
  localStorage.setItem('cart', JSON.stringify({ products, totalPrice, employees, totalDiscount, totalVat }))
}

const clearCart = (): void => localStorage.removeItem(cartKey)

const getItemDiscount = (training: ICartResponseData): string => {
  return `-${(Number(training.discount) * training.count).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })}`
}

const getItemVat = (training: ICartResponseData): string => {
  return `${(Number(training.vat) * training.count).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })}`
}

const cartHelper = {
  getCart,
  setCart,
  clearCart,
  getItemDiscount,
  getItemVat,
}

export default cartHelper
