




























































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper.scss'
import { StepData } from '@/configs/training-steps'
import TrainingData from '@/classes/training/training'
import Routes from '@/router/routes'

const Training = namespace('Training')

@Component({
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
})
export default class CreateTraining extends Vue {
  StepData = StepData

  @Training.Getter
  private training!: TrainingData

  @Training.Getter
  private trainingIsChanged!: boolean

  @Training.Getter
  private trainingIsInitialized!: boolean

  @Training.Action
  private resetTraining!: () => void

  @Training.Action
  private resetDataToOld!: () => void

  @Training.Action
  private appendToTraining!: (trainingData: any) => Promise<void>

  @Training.Action
  private createTraining!: (steps: any) => Promise<void>

  @Training.Action
  private submitTrainingData!: (steps: any) => Promise<void>

  @Training.Action
  private publishTraining!: () => Promise<void>

  async stepForward(step: number): Promise<void> {
    const nextStep: number = step + 1

    const nextCompletedStep: number = nextStep > this.training.completedStep ? nextStep : this.training.completedStep

    await this.submitStep(nextStep, nextCompletedStep)
  }

  async stepBackwards(step: number): Promise<void> {
    const nextStep: number = step - 1

    await this.submitStep(nextStep, this.training.completedStep)
  }

  async moveToStep(step: number): Promise<void> {
    const nextStep: number = step >= this.training.completedStep ? this.training.completedStep : step

    await this.submitStep(nextStep, this.training.completedStep)
  }

  async resetAllSettings(): Promise<void> {
    await this.resetTraining()
    this.scrollToTop()
  }

  resetStepData(): void {
    this.$root.$emit('resetStepData', this.training.activeStep)
  }

  async submitStep(activeStep: number, completedStep: number): Promise<void> {
    this.swiper.slideTo(activeStep - 1, 200)

    if (!this.trainingIsChanged) {
      await this.appendToTraining({
        activeStep,
        completedStep,
      })
      this.scrollToTop()

      return
    }

    if (!this.trainingIsInitialized) {
      await this.createTraining({ activeStep, completedStep })
      this.scrollToTop()

      return
    }

    await this.submitTrainingData({ activeStep, completedStep })

    this.scrollToTop()
  }

  async startTrainingPublishing(): Promise<void> {
    if (this.trainingIsChanged)
      await this.submitTrainingData({
        activeStep: this.training.activeStep,
        completedStep: this.training.completedStep,
      })

    this.publishTraining().then(() => {
      this.$router.push({ name: Routes.PROFILE_TRAININGS })
    })
  }

  get allStepsAreValid(): boolean {
    const validArray: boolean[] = Object.keys(StepData).map((step: string) => StepData[step].isValid(this.training))

    return validArray.every((element: boolean) => element)
  }

  private scrollToTop(): void {
    const element = document.querySelector('.heading-page')
    element!.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  private tabIsActive(step: string): boolean {
    return StepData[step].isActive(this.training, step)
  }

  private isCompleted(step: string): boolean {
    return (
      StepData[step].isValid(this.training) &&
      !StepData[step].isActive(this.training, step) &&
      StepData[step].isCompleted(this.training, step)
    )
  }

  private isNotCompleted(step: string): boolean {
    return (
      !StepData[step].isValid(this.training) &&
      !StepData[step].isActive(this.training, step) &&
      StepData[step].isCompleted(this.training, step)
    )
  }

  get mobileStepsSwiperOptions(): any {
    return {
      slidesPerView: 2.5,
      centeredSlides: true,
      grabCursor: true,
    }
  }

  get swiper(): any {
    return (this.$refs.swiper as any)?.$swiper
  }
}
