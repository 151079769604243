








































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AxiosResponse } from 'axios'
import _ from 'lodash'
import { namespace } from 'vuex-class'
import http from '@/classes/http'
import { ISearchResult } from '@/interfaces/SearchInterface'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'

const Global = namespace('Global')

@Component
export default class Search extends Vue {
  @Prop() value!: string
  @Prop() endpoint!: string
  @Prop({ default: 'blue' }) variant!: string
  @Prop({ default: 'Search by training name' }) placeholder!: string
  @Prop() searchAction!: (searchKeyword: string) => void

  searchText: string = ''
  active: boolean = false
  loading: boolean = false
  submitted: boolean = false
  results: ISearchResult[] | null = null

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  private onSearchKeyDown = _.debounce(this.searchChanged, 500)

  created(): void {
    this.searchText = this.value

    this.$watch(
      () => this.value,
      () => (this.searchText = this.value)
    )
  }

  search(): void {
    if (this.submitted) return

    if (!this.endpoint) return

    this.loading = true
    this.results = []

    http
      .get(`${this.endpoint}?q=${this.searchText}&per_page=4`)
      .then((response: AxiosResponse<IPaginatedResponse<ISearchResult[]>>) => {
        if (this.submitted) {
          this.results = null
          this.loading = false

          return
        }

        this.results = response.data.data
      })
      .catch((error: AxiosResponse) => {
        this.results = []

        this.showSnackbar({
          type: SnackbarType.error,
          text: String(error),
        })
      })
      .finally(() => (this.loading = false))
  }

  submit(searchText: string): void {
    const { input }: any = this.$refs

    input.blur()

    this.loading = false
    this.active = false
    this.results = null
    this.submitted = true

    this.searchAction(searchText)
  }

  clear(): void {
    this.searchText = ''

    this.submit(this.searchText)
  }

  onFocusOut(): void {
    this.active = false
    this.results = null
  }

  onFocusIn(): void {
    this.submitted = false
    this.active = true
  }

  setSearchText(search: string): void {
    this.searchText = search
    this.submit(search)
  }

  private searchChanged(): void {
    if (this.searchText) {
      this.search()

      return
    }

    this.results = null
  }
}
