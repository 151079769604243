import { IUserLocalStorage } from '@/interfaces/AuthInterface'
import { UserType } from '@/classes/user/user'

const storageKey = 'user'

const clearUser = (): void => localStorage.removeItem(storageKey)

const getUser = (): null | IUserLocalStorage => {
  const user: string | null = localStorage.getItem(storageKey)
  try {
    if (user) {
      return JSON.parse(user)
    }
    throw Error
  } catch (error) {
    clearUser()

    return null
  }
}

const getToken = (): null | string => {
  const user: IUserLocalStorage | null = getUser()

  return user ? user.token : null
}

const setUser = async (token: string, userType: UserType, loggedInAs: boolean = false): Promise<void> => {
  return Promise.resolve().then(() => {
    localStorage.setItem(storageKey, JSON.stringify({ token, userType, loggedInAs }))
  })
}

const getLoggedInAs = (): null | boolean => {
  const user: IUserLocalStorage | null = getUser()

  return user ? user.loggedInAs : null
}

const setToken = (token: string): void => {
  localStorage.setItem(storageKey, JSON.stringify({ token, userType: UserType.user }))
}

const tokenHelper = {
  getToken,
  setToken,
  getUser,
  setUser,
  clearUser,
  getLoggedInAs,
}

export default tokenHelper
