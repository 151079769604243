import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'
import Company from '@/classes/user/company'
import http from '@/classes/http'
import { ICompanyResponse } from '@/interfaces/user/UserInterface'

export default class CompanyService {
  static list(perPage: number = 15): Promise<IPaginatedResponse<Company[]>> {
    return http
      .get('companies', {
        params: {
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: ICompanyResponse) => new Company(item)),
        meta: response.data.meta,
      }))
  }
}
