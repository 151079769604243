


































































































































import { Component, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import { namespace } from 'vuex-class'
import AbstractField from '@/components/form/fields/AbstractField.vue'
import TrainingLocation from '@/classes/training/training-location'
import TrainingData from '@/classes/training/training'
import { LocationTypes, LocationTypesData } from '@/configs/training-steps'
import { ICountries } from '@/interfaces/AuthInterface'
import TrainingService from '@/services/TrainingService'
import { ITrainingFormLocation } from '@/interfaces/form-interfaces/TrainingDataFormInterface'
import { ISteps } from '@/store/modules/training.module'

const Training = namespace('Training')

@Component
export default class LocationField extends AbstractField {
  LocationTypes = LocationTypes
  countries: ICountries[] = require('@/storage/countries.json')

  @Training.Getter
  private training!: TrainingData

  @Training.Action
  private backupData!: () => void

  @Training.Action
  private setStates!: (states: any) => void

  @Training.Action
  private clearRelatedDates!: (locationUuid: string) => void

  @Training.Action
  private deleteTrainingLocations!: () => void

  @Training.Mutation
  private setStepData!: (stepData: ISteps) => void

  @Training.Mutation
  private setTrainingLocations!: (locations: ITrainingFormLocation) => void

  created(): void {
    if (!this.model) this.model = []

    if (this.training.trainingLocations.length > 0) return

    this.addLocationLine()
    this.backupData()
    this.setStates({ changed: false })

    this.$root.$on('resetStepData', (step: number) => {
      if (step === 4) {
        this.clearData()
      }
    })
  }

  clearData(): void {
    this.deleteTrainingLocations()
    this.setStepData({
      activeStep: 4,
      completedStep: 4,
    })
  }

  @Watch('model', { deep: true }) private onLocationChange(): void {
    this.setTrainingLocations(this.model)
    this.setStates({ changed: true })
  }

  addLocationLine(): void {
    const newTrainingLocation: ITrainingFormLocation = {
      location: LocationTypes.local,
      trainingUuid: this.training.uuid,
    }
    this.model.push(newTrainingLocation)
  }

  showField(location: TrainingLocation, allowed: string[]): boolean {
    return !location.location ? false : allowed.includes(location.location)
  }

  removeLocationLine(index: number): void {
    if (_.has(this.model[index], 'uuid')) {
      const locationUuid = this.model[index].uuid
      TrainingService.removeLocationLineFromDB(index, this.model).then(() => {
        this.model.splice(index, 1)
        this.clearRelatedDates(locationUuid)
      })

      return
    }

    this.model.splice(index, 1)
  }

  get locationTypes(): any {
    return Object.keys(LocationTypes).map((locationType: string) => ({
      value: locationType,
      text: LocationTypesData[locationType],
    }))
  }

  get countryOptions(): any {
    return this.countries.map((country: ICountries) => ({
      value: country.name,
      text: country.name,
    }))
  }
}
