import { ITrainingFormLocationDateService } from '@/interfaces/form-interfaces/TrainingDataFormInterface'
import ITrainingLocationDateServiceResponse from '@/interfaces/training/TrainingLocationDateServiceInterface'
import Model from '@/classes/model'
import TrainingLocationDateServiceClassifier from '@/classes/training/training-location-date-service-classifier'

export default class TrainingLocationDateService extends Model {
  isActive!: boolean
  serviceClassifierId!: number
  trainingUuid!: string
  trainingLocationUuid!: string
  trainingLocationDateUuid!: string
  serviceClassifier!: TrainingLocationDateServiceClassifier

  constructor(data: ITrainingLocationDateServiceResponse) {
    super(data)

    this.isActive = data.is_active
    this.serviceClassifierId = data.service_classifier_id
    this.trainingUuid = data.training_uuid
    this.trainingLocationUuid = data.training_location_uuid
    this.trainingLocationDateUuid = data.training_location_date_uuid
    this.serviceClassifier = data.service_classifier
  }

  toFormData(): ITrainingFormLocationDateService {
    return {
      id: this.id,
      isActive: this.isActive,
      serviceClassifierId: this.serviceClassifierId,
      trainingUuid: this.trainingUuid,
      trainingLocationUuid: this.trainingLocationUuid,
      trainingLocationDateUuid: this.trainingLocationDateUuid,
      serviceClassifier: this.serviceClassifier,
    }
  }
}
