







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class EmptyList extends Vue {
  @Prop() readonly title!: string
  @Prop() readonly description!: string
}
