
































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AxiosError } from 'axios'
import { namespace } from 'vuex-class'
import ClassifierService from '@/services/ClassifierService'
import Classifier from '@/classes/training/classifier'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import Routes from '@/router/routes'

const Global = namespace('Global')

@Component
export default class IndustriesInfo extends Vue {
  @Prop({ default: 'All trainings for each industry in one place' }) title!: string
  classifiers: Classifier[] | null = null

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  created(): void {
    ClassifierService.getIndustriesListWithTrainingsCount()
      .then((classifiers: Classifier[]) => (this.classifiers = classifiers))
      .catch((error: AxiosError) => {
        this.showSnackbar({
          type: SnackbarType.error,
          text: String(error),
        })
      })
  }

  goToTrainings(industryCode: string): void {
    this.$router.push({
      name: Routes.TRAININGS,
      query: {
        industry: industryCode,
      },
    })
  }

  private getStripedHTMLContent(content: string): string {
    return content.replace(/(<([^>]+)>)/gi, ' ')
  }
}
