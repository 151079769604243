import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'
import TrainingLocationDate from '@/classes/training/training-location-date'
import http from '@/classes/http'
import ITrainingLocationDate from '@/interfaces/training/TrainingLocationDateInterface'

export default class TrainingLocationDateService {
  static list(query: any = {}, perPage: number = 15): Promise<IPaginatedResponse<TrainingLocationDate[]>> {
    return http
      .get('training-location-dates', {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: ITrainingLocationDate) => new TrainingLocationDate(item)),
        meta: response.data.meta,
      }))
  }

  static newest(query: any = {}, perPage: number = 15): Promise<IPaginatedResponse<TrainingLocationDate[]>> {
    return http
      .get('training-location-dates/newest', {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: ITrainingLocationDate) => new TrainingLocationDate(item)),
        meta: response.data.meta,
      }))
  }

  static profileList(query: any = {}, perPage: number = 15): Promise<IPaginatedResponse<TrainingLocationDate[]>> {
    return http
      .get('training-location-dates/profile/list', {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: ITrainingLocationDate) => new TrainingLocationDate(item)),
        meta: response.data.meta,
      }))
  }

  static datesByAuth(): Promise<TrainingLocationDate[]> {
    return http
      .get('training-location-dates/dates-by-auth/list')
      .then((response: AxiosResponse) =>
        response.data.data.map((item: ITrainingLocationDate) => new TrainingLocationDate(item))
      )
  }

  static activeTrainings(query: any = {}, perPage: number = 15): Promise<IPaginatedResponse<TrainingLocationDate[]>> {
    return http
      .get('training-location-dates/active', {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: ITrainingLocationDate) => new TrainingLocationDate(item)),
        meta: response.data.meta,
      }))
  }
}
