import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import tokenHelper from '@/helpers/token-helper'
import store from '@/store'
import router from '@/router'
import Routes from '@/router/routes'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://127.0.0.1:8000/api',
})

http.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = tokenHelper.getToken()

  if (token) config.headers.Authorization = `Bearer ${token}`

  config.headers['Content-Type'] = 'application/json'

  config.headers['Access-Control-Allow-Origin'] = '*'

  return config
})

http.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error: AxiosError<any>) => {
    if (error.response?.status === 401) {
      if (tokenHelper.getToken()) {
        store.commit('Auth/logout')
        tokenHelper.clearUser()
        router.push({ name: Routes.LOGIN })
      }
    }
    throw error
  }
)

export default http
