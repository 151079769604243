var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-date-picker',{staticClass:"w-100 custom-datepicker",class:_vm.additionalClass,attrs:{"masks":_vm.masks,"popover":{ visibility: 'click' },"is-range":_vm.isRange,"update-on-input":!_vm.isRange,"min-date":_vm.minDate},on:{"input":_vm.onDatePickerChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('label',{staticClass:"input-label"},[(_vm.title)?_c('span',{domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),_c('div',{staticClass:"position-relative",class:{ 'is-invalid': _vm.hasError }},[_c('input',_vm._g({class:{
            input: true,
            'input-small': _vm.small,
            'input-large': _vm.large,
            'is-invalid': _vm.hasError,
          },attrs:{"id":_vm.id,"disabled":_vm.readonly,"name":_vm.id,"placeholder":_vm.placeholder,"required":_vm.required,"state":_vm.hasErrorState},domProps:{"value":_vm.getValue(inputValue)}},_vm.isRange ? inputEvents.start : inputEvents)),_c('img',{staticClass:"datepicker-icon",attrs:{"src":require("@/assets/images/icons/date.svg")}})]),_c('b-form-invalid-feedback',{attrs:{"id":(_vm.id + "-feedback")}},_vm._l((_vm.fieldErrors),function(errorMsg,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(errorMsg.msg)+" ")])}),0)],1)]}}]),model:{value:(_vm.initialValue),callback:function ($$v) {_vm.initialValue=$$v},expression:"initialValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }