import { AxiosResponse } from 'axios'
import {
  IBaseResponse,
  ILoginResponse,
  IPasswordResetData,
  IRegister,
  IRegisterPayload,
} from '@/interfaces/AuthInterface'
import tokenHelper from '@/helpers/token-helper'
import http from '@/classes/http'
import { UserType } from '@/classes/user/user'
import store from '@/store'
import { AuthActions } from '@/store/actions/auth.actions'
import router from '@/router'
import Routes from '@/router/routes'

export default class AuthService {
  static login(email: string, password: string): Promise<ILoginResponse> {
    return http
      .post('auth/login', {
        email,
        password,
      })
      .then((response) => {
        if (response.data.data.token) {
          tokenHelper.setUser(response.data.data.token, UserType.user)
        }

        return response.data.data
      })
  }

  static emailVerify(urlWithParams: string): Promise<IBaseResponse> {
    return http.get(urlWithParams).then((response) => response.data)
  }

  static confirmEmployee(url: string): Promise<IBaseResponse> {
    return http.put(url).then((response) => response.data)
  }

  static setCompanyUserPassword(payload: IPasswordResetData): Promise<IBaseResponse> {
    return http.post('auth/password/set-for-company-user', payload).then((response) => response.data)
  }

  static sendPasswordResetEmail(email: string): Promise<IBaseResponse> {
    return http.post('auth/password/email', { email }).then((response) => response.data)
  }

  static resetPassword(payload: IPasswordResetData): Promise<IBaseResponse> {
    return http.post('auth/password/reset', payload).then((response) => response.data)
  }

  static logout(): void {
    http.post('auth/logout').then((response) => {
      if (tokenHelper.getLoggedInAs()) {
        tokenHelper.setUser(response.data.data.token, UserType.user)
        store.dispatch(AuthActions.GET_ME).then(() => {
          router.push({ name: Routes.ADMIN_USERS_LIST })
        })

        return
      }

      tokenHelper.clearUser()
      router.push({ name: Routes.LOGIN })
    })
  }

  static register(params: IRegister): Promise<AxiosResponse> {
    const payload: IRegisterPayload = {
      email: params.email,
      password: params.password,
      password_confirmation: params.passwordConfirmation,
      name: params.name,
      phone_number: params.phone,
      type: params.type,
      has_term_and_conditions: params.terms,
      company: {
        name: params.companyName,
      },
      training_center: {
        name: params.trainingCenterName,
      },
      user_location: {
        country: params.country,
        city: params.city,
        address: params.address,
      },
      'g-recaptcha-response': params['g-recaptcha-response'],
    }

    return http.post('auth/register', payload)
  }

  static logInAsUser(uuid: string): Promise<ILoginResponse> {
    return http
      .post(`auth/login/as-other-user/${uuid}`)
      .then((response) => {
        if (response.data.data.token) {
          tokenHelper.setUser(response.data.data.token, UserType.user, true)
        }

        return response
      })
      .then((response) => {
        store.dispatch(AuthActions.GET_ME)

        return response.data.data
      })
  }
}
