
















































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import TrainingLocationDate from '@/classes/training/training-location-date'
import AbstractField from '@/components/form/fields/AbstractField.vue'
import TrainingLocationDateServiceModel from '@/classes/training/training-location-service'
import Classifier from '@/classes/training/classifier'
import { LocationTypesData } from '@/configs/training-steps'
import TrainingService from '@/services/TrainingService'
import TrainingLocationInfo from '@/components/TrainingLocationInfo.vue'
import FakeCheckboxField from '@/components/form/fields/FakeCheckboxField.vue'
import ITrainingDataForm, {
  ITrainingFormLocationDate,
  ITrainingFormLocationDateService,
} from '@/interfaces/form-interfaces/TrainingDataFormInterface'

const Training = namespace('Training')

@Component({ components: { TrainingLocationInfo, FakeCheckboxField } })
export default class ServicesField extends AbstractField {
  @Prop({ default: [] }) private readonly services!: Classifier[]
  LocationTypesData = LocationTypesData

  @Training.Getter
  private training!: ITrainingDataForm

  @Training.Mutation
  private setTrainingLocationDateServices!: (data: ITrainingFormLocationDateService) => void

  @Training.Action
  private backupData!: () => void

  @Training.Action
  private setStates!: (states: any) => void

  @Watch('model', { deep: true }) private onServicesChange(): void {
    this.setTrainingLocationDateServices(this.model)
    this.setStates({ changed: true })
  }

  async created(): Promise<void> {
    if (!this.model) this.model = []
    await TrainingService.generateTrainingServices(this.training.uuid)
      .then((response: ITrainingFormLocationDateService[]) => {
        this.model = response
      })
      .then(() => {
        this.backupData()
        this.setStates({ changed: false })
      })

    this.$root.$on('resetStepData', (step: number) => {
      if (step === 7) {
        this.clearData()
      }
    })
  }

  clearData(): void {
    this.services.forEach((service) => {
      this.setAll(false, service)
    })
  }

  findServiceField(date: TrainingLocationDate, classifier: Classifier): TrainingLocationDateServiceModel | undefined {
    return this.model.find(
      (service: TrainingLocationDateServiceModel) =>
        service.serviceClassifierId === classifier.id && service.trainingLocationDateUuid === date.uuid
    )
  }

  filterDates(locationUuid: string | undefined): any {
    return this.training.trainingLocationDates.filter(
      (date: ITrainingFormLocationDate) => date.trainingLocationUuid === locationUuid
    )
  }

  setAll(value: boolean, service: Classifier): void {
    this.model.forEach((dateService: any) => {
      if (service.id === dateService.serviceClassifierId) dateService.isActive = value
    })
  }

  getValue(service: Classifier): void {
    const results: any = this.model.filter((dateService: any) => dateService.serviceClassifierId === service.id)

    return results.every((dateService: any) => dateService.isActive === true)
  }
}
