




import { Component, Prop, Vue } from 'vue-property-decorator'
import LocationField from '@/components/form/fields/training/LocationField.vue'
import ITrainingDataForm from '@/interfaces/form-interfaces/TrainingDataFormInterface'

@Component({
  components: {
    LocationField,
  },
})
export default class TrainingStepLocation extends Vue {
  @Prop() private readonly data!: ITrainingDataForm
}
