


































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import TrainingFilter from '@/components/form/trainings/TrainingFilter.vue'
import TrainingCard from '@/components/TrainingCard.vue'
import Pagination from '@/components/Pagination.vue'
import TrainingLocationDate from '@/classes/training/training-location-date'
import TrainingCenterListUser from '@/classes/user/training-center-list-user'
import Filter from '@/classes/filter'
import TrainingService from '@/services/TrainingService'
import ITrainingLocationDate from '@/interfaces/training/TrainingLocationDateInterface'
import Classifier from '@/classes/training/classifier'
import SelectOption from '@/interfaces/SelectOption'
import TrainingCenterService from '@/services/TrainingCenterService'
import TrainingCenterDescription from '@/components/TrainingCenterDescription.vue'
import EmptyList from '@/components/EmptyList.vue'
import scrollToRef from '@/helpers/scroll-to-ref-helper'

@Component({
  components: { EmptyList, TrainingFilter, TrainingCard, Pagination, Breadcrumbs, TrainingCenterDescription },
})
export default class TrainingCenterInner extends Vue {
  private activeTrainingCenters: number = 0
  private trainingCenter: TrainingCenterListUser | null = null
  private trainings: TrainingLocationDate[] = []
  private meta: any = null
  private page: number = 1
  private debouncedSearch = _.debounce(this.getTrainingDates, 400)
  private industryOptions: Classifier[] = []
  private standardOptions: Classifier[] = []
  private countryOptions: string[] = []
  private loading: Record<string, boolean> = {
    main: false,
    dates: true,
  }
  private filters: Record<string, Filter> = {
    industry: new Filter({ field: 'training-industry_classifier_id' }),
    standard: new Filter({ field: 'training-standard_classifier_id' }),
    trainingType: new Filter({ field: 'training-training_type_classifier_id' }),
    trainingCenterId: new Filter({ field: 'training-user-training_center_id' }),
    from: new Filter({ field: 'from', modifier: 'between' }),
    location: new Filter({ field: 'trainingLocation-country', modifier: 'like' }),
  }

  private breadcumbsOverrides: any = {}

  async created(): Promise<void> {
    this.trainingCenter = await TrainingCenterService.getTrainingCenter(this.$route.params.slug)

    this.filters.trainingCenterId.value = this.trainingCenter.trainingCenter.id.toString()
    this.breadcumbsOverrides.inner = this.trainingCenter.trainingCenter.name

    this.industryOptions = await TrainingService.getClassifiers('get-industry-list')
    this.countryOptions = await TrainingService.getCountryFilterList()

    await this.getTrainingDates()

    if (this.$route.query.scrollToFilters) {
      const trainingFilterRef: any = this.$refs.filter
      trainingFilterRef.$el.scrollIntoView({ behavior: 'smooth' })
    }
  }

  @Watch('filters.trainingId.value', { deep: true })
  private onFilterChange(): void {
    this.trainings = []
    this.getTrainingDates()
  }

  async getTrainingDates(): Promise<void> {
    this.loading.dates = true
    await TrainingService.getTrainingActiveList(this.page, '', this.filters).then((response: any) => {
      this.trainings = response.data.map((training: ITrainingLocationDate) => new TrainingLocationDate(training))
      this.meta = response.meta
      this.loading.dates = false
      this.activeTrainingCenters = response.meta.total
    })
  }

  async getStandards(id: number | string): Promise<void> {
    this.filters.standard.value = ''
    await this.debouncedSearch()

    if (id === '') {
      this.standardOptions = []

      return
    }
    this.standardOptions = await TrainingService.getClassifiersByParent(id)
  }

  private onPageInput(id: string): void {
    this.loading.dates = true

    scrollToRef(id)
  }

  private clearAllFilters(): void {
    this.$set(this.filters.industry, 'value', '')
    this.$set(this.filters.standard, 'value', '')
    this.$set(this.filters.from, 'value', '')
    this.$set(this.filters.location, 'value', '')

    const { industryFilter, locationFilter, standardFilter, dateFilter }: any = this.$refs

    if (industryFilter) industryFilter.inputValue = ''

    if (locationFilter) locationFilter.inputValue = ''

    if (standardFilter) standardFilter.inputValue = ''

    if (dateFilter) dateFilter.initialValue = ''

    this.getTrainingDates()
  }

  get standardSelections(): SelectOption[] {
    return this.standardOptions.map((industry: Classifier) => ({ value: industry.id, text: industry.title }))
  }

  get industrySelections(): SelectOption[] {
    return this.industryOptions.map((industry: Classifier) => ({ value: industry.id, text: industry.title }))
  }

  get countrySelections(): SelectOption[] {
    return this.countryOptions.map((option: string) => ({ value: option, text: option }))
  }
}
