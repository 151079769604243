















import { Component, Vue, Watch } from 'vue-property-decorator'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import PageHeader from '@/components/PageHeader.vue'
import JobPositionsService from '@/services/JobPositionsService'
import JobCard from '@/components/JobCard.vue'
import Pagination from '@/components/Pagination.vue'

@Component({
  components: { Pagination, JobCard, PageHeader, Breadcrumbs },
})
export default class JobPositions extends Vue {
  private jobPositions: any[] = []
  private jobPositionMeta: any = null
  private currentPage: number = 1

  created(): void {
    this.getPaginatedJobs()
  }

  @Watch('currentPage')
  private goToPage(): void {
    this.getPaginatedJobs()
  }

  private async getPaginatedJobs(): Promise<void> {
    const jobPositionResponse = await JobPositionsService.getJobPositions(this.currentPage)
    this.jobPositions = jobPositionResponse.data
    this.jobPositionMeta = jobPositionResponse.meta
  }
}
