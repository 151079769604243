import { NavigationGuardNext, RawLocation } from 'vue-router'
import tokenHelper from '@/helpers/token-helper'
import User, { UserType } from '@/classes/user/user'
import store from '@/store'
import { AuthActions } from '@/store/actions/auth.actions'
import Routes from '@/router/routes'

export const beforeEach = async (
  to: RawLocation | any,
  from: RawLocation | any,
  next: NavigationGuardNext | any
): Promise<void> => {
  const token = tokenHelper.getToken()

  if (to.meta.requiresAuth && !token) {
    return next(Routes.LOGIN)
  }

  const user: User | null = store.getters['Auth/user']

  if (token && !user) {
    await store.dispatch(AuthActions.GET_ME)
  }

  const userData: User | null = store.getters['Auth/user']

  if (to.meta.requiresAdminAuth && userData?.type !== UserType.admin) {
    return next(Routes.LOGIN)
  }

  return next()
}
