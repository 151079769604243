











































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { AxiosError } from 'axios'
import { namespace } from 'vuex-class'
import { BvTableFieldArray } from 'bootstrap-vue/src/components/table'
import Routes from '@/router/routes'
import TrainingLocationDate from '@/classes/training/training-location-date'
import { IMeta, IPaginatedResponse } from '@/interfaces/ResponseInterface'
import Student from '@/classes/student'
import UserService from '@/services/UserService'
import { ITableProperties, ITableSortChanged } from '@/interfaces/TableInterface'
import TrainingService from '@/services/TrainingService'
import Classifier from '@/classes/training/classifier'
import IConfirmDialog from '@/interfaces/ConfirmDialogInterface'
import OrderItemUserService from '@/services/OrderItemUserService'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import SelectOption from '@/interfaces/SelectOption'
import EmptyList from '@/components/EmptyList.vue'
import Pagination from '@/components/Pagination.vue'
import Search from '@/components/Search.vue'
import { LocationTypesData } from '@/configs/location-type'
import TrainingEditModal from '@/components/profile/edit/TrainingEditModal.vue'
import ChangeStudentStatus from '@/components/ChangeStudentStatus.vue'
import StudentUserStatus, { StudentUserStatusTitle } from '@/configs/student-user-status'

const Global = namespace('Global')

@Component({
  components: { ChangeStudentStatus, Search, EmptyList, Pagination, TrainingEditModal },
})
export default class TrainingTable extends Vue {
  @Prop() private meta!: IMeta
  @Prop() private trainingLocationDates!: TrainingLocationDate[]
  @Prop() private tableProperties!: ITableProperties
  @Prop() private refreshList!: () => void
  Routes = Routes
  LocationTypesData = LocationTypesData
  StudentUserStatus = StudentUserStatus
  StudentUserStatusTitle = StudentUserStatusTitle

  public trainingLocationDateStudents: { [x: string]: Student[] } = {}

  public search: string = ''
  public date: string = ''
  public opened: boolean = false
  private industryClassifiers: Classifier[] = []
  private standardClassifiers: Classifier[] = []
  private loading: boolean = false

  @Global.Action
  private showConfirmDialog!: (confirmDialog: IConfirmDialog) => void

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  private onDateChange = _.debounce(this.dateChanged, 500)

  created(): void {
    this.$root.$on('trainingUpdate:success', async () => {
      this.loading = true
      await this.refreshList()
      this.loading = false
    })

    this.search = this.tableProperties.filters.search
    this.date = this.tableProperties.filters.from

    this.$watch(() => this.tableProperties.filters.from, this.onDateChanged, { deep: true })

    this.loadFiltersData()
  }

  loadFiltersData(): void {
    TrainingService.getClassifiersByCategory('industry').then(
      (classifiers: Classifier[]) => (this.industryClassifiers = classifiers)
    )

    TrainingService.getClassifiersByCategory('standard').then(
      (classifiers: Classifier[]) => (this.standardClassifiers = classifiers)
    )
  }

  refund(userId: number): void {
    this.showConfirmDialog({
      text: 'Refunding will empty one seat in training.',
      confirmText: 'Refund',
      cancelText: 'Do not refund',
      confirmAction: async () => {
        await OrderItemUserService.refund(userId)
          .then(async () => {
            this.showSnackbar({
              type: SnackbarType.success,
              text: 'Refund is successfully submitted',
            })

            await this.refreshList()
            this.trainingLocationDateStudents = {}
          })
          .catch((error: AxiosError) => {
            this.showSnackbar({
              type: SnackbarType.error,
              text: error.response?.data.message,
            })
          })
      },
    })
  }

  private onToggleStudentList(currentState: boolean, uuid: string, toggle: () => void): void {
    if (!currentState && !this.trainingLocationDateStudents[uuid]) {
      UserService.getStudentsByTrainingLocationDate(uuid, {}, 9999).then(
        (response: IPaginatedResponse<Student[]>) =>
          (this.trainingLocationDateStudents = {
            ...this.trainingLocationDateStudents,
            [uuid]: response.data,
          })
      )
    }

    toggle()
  }

  private onSearch(search: string): void {
    this.search = search

    this.tableProperties.filters = {
      ...this.tableProperties.filters,
      search: this.search,
    }
  }

  private dateChanged(): void {
    if (this.date === this.tableProperties.filters.from) return

    this.tableProperties.filters = {
      ...this.tableProperties.filters,
      from: this.date,
    }
  }

  private onTableSortChange(event: ITableSortChanged): void {
    this.tableProperties.sortBy = event.sortBy
    this.tableProperties.order = event.sortDesc ? 'desc' : 'asc'
  }

  private onPageChange(page: number): void {
    this.tableProperties.currentPage = page
  }

  private getToggleOrderEvent(order: string): any {
    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'asc') {
      return {
        sortBy: '',
        sortDesc: false,
      }
    }

    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'desc') {
      return {
        sortBy: order,
        sortDesc: false,
      }
    }

    return {
      sortBy: order,
      sortDesc: true,
    }
  }

  private getFilterOrderClass(order: string): string {
    if (this.tableProperties.sortBy === order) {
      return this.tableProperties.order || ''
    }

    return ''
  }

  private clearAllFilters(): void {
    this.tableProperties.filters.industryClassifierId = []
    this.tableProperties.filters.standardClassifierId = ''
    this.tableProperties.filters.from = ''
    this.tableProperties.filters.status = []
    this.tableProperties.filters.search = ''
    this.date = ''
    this.search = ''

    const { industryFilter, standardFilter, dateFilter }: any = this.$refs

    if (industryFilter) industryFilter.inputValue = ''

    if (standardFilter) standardFilter.inputValue = ''

    if (dateFilter) dateFilter.initialValue = ''
  }

  private getFields(): BvTableFieldArray {
    return [
      {
        key: 'status',
        label: '',
        class: 'profile-training-table__status ',
      },
      {
        key: 'training.title',
        label: 'Training name',
        sortable: true,
        class: 'profile-training-table__name',
      },
      {
        key: 'from',
        label: 'Starts<br><span>Duration</span>',
        sortable: true,
      },
      {
        key: 'seats',
        label: 'Seats<br><span>(all/available)</span>',
        sortable: true,
      },
      {
        key: 'training.industryClassifier.title',
        label: 'Industry',
        sortable: true,
      },
      {
        key: 'training.standardClassifier.title',
        label: 'Standard',
        sortable: true,
      },
      {
        key: 'trainingLocation',
        label: 'Location',
        sortable: false,
      },
      {
        key: 'price',
        label: 'Price',
        sortable: true,
      },
      {
        key: 'list',
        label: '',
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
      },
    ]
  }

  private toggle(): void {
    this.opened = !this.opened
  }

  private onDateChanged(): void {
    this.date = this.tableProperties.filters.from

    const trainingFilterRef: any = this.$refs.trainingFilter
    trainingFilterRef.scrollIntoView({ behavior: 'smooth' })
  }

  private async changeUserStatus(event: Record<string, any>): Promise<void> {
    await OrderItemUserService.setStatus(event.uuid, event.status)

    this.showSnackbar({
      type: SnackbarType.success,
      text: 'Successfully updated student status!',
    })

    event.object.attendedStatus = event.status

    this.$bvModal.hide(`changeStudentStatus-${event.uuid}`)
  }

  get industryClassifierSelections(): SelectOption[] {
    return this.industryClassifiers.map((industry: Classifier) => ({
      value: industry.id,
      text: industry.title,
    }))
  }

  get standardClassifierSelections(): SelectOption[] {
    return this.standardClassifiers.map((standard: Classifier) => ({
      value: standard.id,
      text: standard.title,
    }))
  }

  getVindaID(student: Student) {
    return student.user?.userProfile?.vindaId
  }

  get statusOptions(): SelectOption[] {
    return [
      {
        value: StudentUserStatus.attended,
        text: StudentUserStatusTitle[StudentUserStatus.attended],
      },
      {
        value: StudentUserStatus.notAttended,
        text: StudentUserStatusTitle[StudentUserStatus.notAttended],
      },
      {
        value: StudentUserStatus.refunded,
        text: StudentUserStatusTitle[StudentUserStatus.refunded],
      },
    ]
  }
}
