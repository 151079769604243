




















import { Component, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AbstractField from '@/components/form/fields/AbstractField.vue'
import Classifier from '@/classes/training/classifier'
import TrainingService from '@/services/TrainingService'
import { ISteps } from '@/store/modules/training.module'

const Training = namespace('Training')

@Component
export default class IndustryPicker extends AbstractField {
  @Prop() private readonly options!: Classifier[]
  industryOptions: Classifier[] = []

  async created(): Promise<void> {
    this.industryOptions = await TrainingService.getClassifiers('get-industry-list')

    this.$root.$on('resetStepData', (step: number) => {
      if (step === 1) {
        this.clearData()
      }
    })
  }

  @Training.Action
  private appendToTraining!: (data: any) => void

  @Training.Action
  private setStates!: (states: any) => void

  @Training.Action
  private deleteIndustryData!: () => void

  @Training.Mutation
  private setStepData!: (stepData: ISteps) => void

  clearData(): void {
    this.deleteIndustryData()
    this.setStepData({
      activeStep: 1,
      completedStep: 1,
    })
  }

  private setTraining(event: any): void {
    this.appendToTraining({
      industryClassifierId: Number(event?.target?.value),
      trainingTypeClassifierId: null,
      standardClassifierId: null,
    })
    this.setStates({ changed: true })
  }
}
