


















































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { BvTableFieldArray } from 'bootstrap-vue/src/components/table'
import { DateTime } from 'luxon'
import { namespace } from 'vuex-class'
import { AxiosError } from 'axios'
import { ITableProperties, ITableSortChanged } from '@/interfaces/TableInterface'
import Classifier from '@/classes/training/classifier'
import TrainingLocation from '@/classes/training/training-location'
import TrainingService from '@/services/TrainingService'
import { IMeta, IPaginatedResponse } from '@/interfaces/ResponseInterface'
import TrainingLocationService from '@/services/TrainingLocationService'
import Student from '@/classes/student'
import UserService from '@/services/UserService'
import SelectOption from '@/interfaces/SelectOption'
import EmptyList from '@/components/EmptyList.vue'
import AddEmployeesModal from '@/components/AddEmployeesModal.vue'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import Pagination from '@/components/Pagination.vue'
import Search from '@/components/Search.vue'
import IConfirmDialog from '@/interfaces/ConfirmDialogInterface'
import EmployeesService from '@/services/EmployeesService'
import ITrainingProfileListResponse from '@/interfaces/training/TrainingProfileListInterface'
import StudentStatusType, { StudentStatusTypeTitle } from '@/configs/user-status-type'
import ChangeStudentStatus from '@/components/ChangeStudentStatus.vue'
import scrollToRef from '@/helpers/scroll-to-ref-helper'

enum EmployeesFilter {
  search = 'q',
  training = 'filter-training-uuid',
  industry = 'filter-user_trainings-industry_classifier_id',
  trainingLocation = 'filter-trainingLocation-location',
  trainingCountry = 'filter-trainingLocation-country',
  expireDate = 'filter-between-expire_date',
  gteExpire = 'filter-gte-days_to_expire',
  gtExpire = 'filter-gt-days_to_expire',
  ltExpire = 'filter-lt-days_to_expire',
  lteExpire = 'filter-lte-days_to_expire',
}

enum ProfileEmployeesFilterValidity {
  moreThan60 = 'moreThan60',
  lessThan60 = 'lessThan60',
  expired = 'expired',
}

const Global = namespace('Global')

@Component({
  components: { Search, EmptyList, CartAddEmployees: AddEmployeesModal, Pagination, ChangeStudentStatus },
})
export default class Employees extends Vue {
  private loading: boolean = false
  private allEmployeesEmails: Array<string> = []
  ProfileEmployeesFilterValidity = ProfileEmployeesFilterValidity
  StudentStatusType = StudentStatusType
  StudentStatusTypeTitle = StudentStatusTypeTitle

  public tableProperties: ITableProperties = {
    currentPage: 1,
    perPage: 15,
    filters: {
      search: '',
      trainingId: null,
      industryClassifierId: null,
      trainingLocationId: null,
      expiryDate: '',
      validity: null,
    },
  }

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Global.Action
  private showConfirmDialog!: (confirmDialog: IConfirmDialog) => void

  private employees: Student[] = []
  private meta: IMeta | any = {}
  public opened: boolean = false

  private filterTrainings: ITrainingProfileListResponse[] = []
  private classifiers: Classifier[] = []
  private trainingLocations: TrainingLocation[] = []

  private onDateChange = _.debounce(this.changeRouteByProperties, 500)

  async created(): Promise<void> {
    this.loading = true

    this.$root.$on('newEmployeesAdded:success', this.refreshAfterEmployeeAdd)
    this.parseQueryParams()
    await this.loadEmployees()
    await this.loadFiltersData()

    this.loading = false

    this.$watch(() => this.$route, this.loadEmployees)
  }

  private async refreshAfterEmployeeAdd() {
    this.loading = true
    await this.loadEmployees()
    this.$bvModal.hide('newEmployees')
    this.loading = false
  }

  private async loadEmployees(): Promise<void> {
    const { query } = this.$router.currentRoute

    const page = query.page || this.tableProperties.currentPage

    try {
      const response = await UserService.getStudents({ page, ...query }, this.tableProperties.perPage)
      this.employees = response.data
      this.allEmployeesEmails = this.employees.map((item) => item.user.email)
      this.meta = response.meta
    } catch (error: any) {
      this.loading = false
      this.showSnackbar({
        type: SnackbarType.error,
        text: `Employee's list cannot be retrieved. ${error.message}`,
      })
    }
  }

  private loadFiltersData(): void {
    TrainingService.profileTrainingList({}, 9999).then(
      (response: IPaginatedResponse<ITrainingProfileListResponse[]>) => (this.filterTrainings = response.data)
    )

    TrainingService.getClassifiersByCategory('industry').then(
      (classifiers: Classifier[]) => (this.classifiers = classifiers)
    )

    TrainingLocationService.countries({}, 9999).then(
      (response: IPaginatedResponse<TrainingLocation[]>) => (this.trainingLocations = response.data)
    )
  }

  private getTableTrClass(item: Student): string {
    if (!item) return ''

    const timeRemaining = item.daysToExpire

    if (timeRemaining < 0) return 'expired'

    if (timeRemaining < 60) return 'warning'

    return 'valid'
  }

  private expirationToString(date: string): string {
    if (!date) return ''

    const dateFormat = new Date(date)

    if (dateFormat <= new Date()) return 'expired'

    const days = DateTime.fromJSDate(dateFormat).diff(DateTime.now(), 'days').days.toFixed(0)

    return `expires ${days} days`
  }

  private onSearch(search: string): void {
    this.tableProperties.filters.search = search

    this.changeRouteByProperties()
  }

  private onTableSortChange(event: ITableSortChanged): void {
    this.tableProperties.sortBy = event.sortBy
    this.tableProperties.order = event.sortDesc ? 'desc' : 'asc'
    this.changeRouteByProperties()
  }

  public getToggleOrderEvent(order: string): any {
    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'asc') {
      return {
        sortBy: '',
        sortDesc: false,
      }
    }

    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'desc') {
      return {
        sortBy: order,
        sortDesc: false,
      }
    }

    return {
      sortBy: order,
      sortDesc: true,
    }
  }

  private getFilterOrderClass(order: string): string {
    if (this.tableProperties.sortBy === order) {
      return this.tableProperties.order || ''
    }

    return ''
  }

  private parseQueryParams(): void {
    const { query } = this.$router.currentRoute

    this.tableProperties.currentPage = Number(_.get(query, 'page', 1))
    this.tableProperties.sortBy = _.get(query, 'sort_by') as string
    this.tableProperties.order = _.get(query, 'order') as string
    this.tableProperties.filters.search = _.get(query, EmployeesFilter.search, '')
    this.tableProperties.filters.trainingId = _.get(query, EmployeesFilter.training, '')
    this.tableProperties.filters.industryClassifierId = _.get(query, EmployeesFilter.industry, '')
    this.tableProperties.filters.trainingLocationId = _.get(query, EmployeesFilter.trainingCountry, '')
    this.tableProperties.filters.expiryDate = _.get(query, EmployeesFilter.expireDate, '')

    if (Object.keys(query).some((key: string) => this.validityFiltersVariations.some((item: string) => item === key))) {
      if (Number(_.get(query, EmployeesFilter.gteExpire, 0)) === 60) {
        this.tableProperties.filters.validity = ProfileEmployeesFilterValidity.moreThan60
      } else if (
        Number(_.get(query, EmployeesFilter.ltExpire, 0)) === 60 &&
        Number(_.get(query, EmployeesFilter.gtExpire, 0)) === 0
      ) {
        this.tableProperties.filters.validity = ProfileEmployeesFilterValidity.lessThan60
      } else if (Number(_.get(query, EmployeesFilter.lteExpire, 0)) === 0) {
        this.tableProperties.filters.validity = ProfileEmployeesFilterValidity.expired
      }
    }
  }

  private changeRouteByProperties(): void {
    const { name, query, params }: any = this.$router.currentRoute

    const { currentPage, sortBy, order, filters } = this.tableProperties

    const routeQuery = {
      ...query,
      page: currentPage,
      sort_by: sortBy,
      order,
    }

    if (sortBy) routeQuery.sort_by = sortBy
    else delete routeQuery.sort_by

    if (order) routeQuery.order = order
    else delete routeQuery.order

    if (filters.search !== '') routeQuery[EmployeesFilter.search] = filters.search
    else delete routeQuery[EmployeesFilter.search]

    Object.keys(routeQuery).forEach((key: string) => {
      if (key.startsWith('filter-')) delete routeQuery[key]
    })

    if (filters.trainingId) routeQuery[EmployeesFilter.training] = filters.trainingId

    if (filters.industryClassifierId) routeQuery[EmployeesFilter.industry] = filters.industryClassifierId

    if (filters.trainingLocationId) routeQuery[EmployeesFilter.trainingCountry] = filters.trainingLocationId

    if (filters.expiryDate !== '') routeQuery[EmployeesFilter.expireDate] = filters.expiryDate

    if (filters.validity && filters.validity !== '') {
      switch (filters.validity) {
        case ProfileEmployeesFilterValidity.moreThan60:
          routeQuery[EmployeesFilter.gteExpire] = 60
          break
        case ProfileEmployeesFilterValidity.lessThan60:
          routeQuery[EmployeesFilter.ltExpire] = 60
          routeQuery[EmployeesFilter.gtExpire] = 0
          break
        default:
          routeQuery[EmployeesFilter.lteExpire] = 0
          break
      }
    }

    this.$router
      .replace({
        name,
        params,
        query: routeQuery,
      })
      .catch(() => {})
  }

  private onPageInput(id: string): void {
    this.loading = true

    scrollToRef(id)
  }

  async onPageChange(): Promise<void> {
    this.loading = true
    await this.loadEmployees()
    this.loading = false
  }

  private getFields(): BvTableFieldArray {
    return [
      {
        key: 'user_trainings.users_name',
        label: 'Student name',
        sortable: true,
      },
      {
        key: 'user_trainings.training_title',
        label: 'Training',
        sortable: true,
      },
      {
        key: 'user_trainings.industry_title',
        label: 'Industry',
        sortable: true,
      },
      {
        key: 'user_trainings.location',
        label: 'Location',
        sortable: true,
      },
      {
        key: 'expire_date',
        label: 'Training expiry date',
        sortable: true,
      },
      {
        key: 'delete',
        label: '',
      },
    ]
  }

  private toggle(): void {
    this.opened = !this.opened
  }

  get validityFiltersVariations(): string[] {
    return [EmployeesFilter.gteExpire, EmployeesFilter.gtExpire, EmployeesFilter.ltExpire, EmployeesFilter.lteExpire]
  }

  get trainingSelections(): SelectOption[] {
    return this.filterTrainings.map((training: any) => ({
      value: training.uuid,
      text: training.text,
    }))
  }

  get industryClassifierSelections(): SelectOption[] {
    return this.classifiers.map((industry: Classifier) => ({
      value: industry.id,
      text: industry.title,
    }))
  }

  get trainingLocationSelections(): SelectOption[] {
    return [
      ...new Set(this.trainingLocations.map((trainingLocation: TrainingLocation) => trainingLocation.location)),
    ].map((location: string) => ({
      value: location,
      text: location,
    }))
  }

  private async setStatus(event: Record<string, any>): Promise<void> {
    await EmployeesService.setStatus(event.uuid, event.status)
      .then(() => {
        this.loadEmployees()
        this.$bvModal.hide(`changeStudentStatus-${event.uuid}`)
        this.showSnackbar({
          type: SnackbarType.success,
          text: 'Employee status was successfully changed',
        })
      })
      .catch((error: AxiosError) =>
        this.showSnackbar({
          type: SnackbarType.error,
          text: `Something wrong, employee status was not changed. More details: ${error.response?.data.message}`,
        })
      )
  }

  private clearAllFilters(): void {
    this.tableProperties.filters.trainingId = null
    this.tableProperties.filters.industryClassifierId = null
    this.tableProperties.filters.trainingLocationId = null
    this.tableProperties.filters.expiryDate = ''
    this.tableProperties.filters.search = ''
    this.tableProperties.filters.validity = false

    const { locationFilter, industryFilter, trainingFilter, dateFilter }: any = this.$refs

    if (locationFilter) locationFilter.inputValue = ''

    if (industryFilter) industryFilter.inputValue = ''

    if (trainingFilter) trainingFilter.inputValue = ''

    if (dateFilter) dateFilter.initialValue = ''

    this.changeRouteByProperties()
  }

  beforeDestroy() {
    this.$root.$off('newEmployeesAdded:success')
  }

  get statusOptions(): SelectOption[] {
    return [
      {
        value: StudentStatusType.active,
        text: StudentStatusTypeTitle[StudentStatusType.active].title,
      },
      {
        value: StudentStatusType.waitingApproval,
        text: StudentStatusTypeTitle[StudentStatusType.waitingApproval].title,
      },
      {
        value: StudentStatusType.disabled,
        text: StudentStatusTypeTitle[StudentStatusType.disabled].title,
      },
    ]
  }
}
