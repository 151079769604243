import { AxiosResponse } from 'axios'
import http from '@/classes/http'
import ListUser from '@/classes/admin/list-user'
import { IAdminListUserResponse } from '@/interfaces/user/UserInterface'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'
import { IAdminBoughtTrainings } from '@/interfaces/OrderItemInterface'

export default class AdminPanelService {
  static usersListByType(query: any, type: string, perPage: number = 15): Promise<IPaginatedResponse<ListUser[]>> {
    return http
      .get(`users/by-type/${type}`, {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: IAdminListUserResponse) => new ListUser(item)),
        meta: response.data.meta,
      }))
  }

  static getBoughtTrainings(query: any, perPage: number = 15): Promise<IPaginatedResponse<IAdminBoughtTrainings[]>> {
    return http
      .get('order-item-users/bought-trainings', {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data,
        meta: response.data.meta,
      }))
  }

  static setUserActivityStatus(userUuid: string, isActive: boolean): Promise<AxiosResponse> {
    return http.put(`/users/${userUuid}/set-activity`, { isActive: !isActive })
  }

  static setTrainingCenterApprovedStatus(userUuid: string): Promise<AxiosResponse> {
    return http.put(`/users/${userUuid}/approve`)
  }
}
