











































import { Component, Prop } from 'vue-property-decorator'
import AbstractField from '@/components/form/fields/AbstractField.vue'
import DocumentClass from '@/classes/user/document'
import { DocumentTypes } from '@/configs/document-type'
import resizeAndCompress from '@/helpers/image-compress'

@Component
export default class CompanyLogoField extends AbstractField {
  @Prop() private readonly existingLogo!: DocumentClass
  logoModel: DocumentClass | null = null

  async saveFiles(event: any): Promise<void> {
    if (!event.target.files[0]) return

    if (this.existingLogo) this.$emit('removeImage', this.existingLogo, this.id)

    await resizeAndCompress(event.target.files[0]).then((image: File) => {
      this.logoModel = new DocumentClass({
        type: DocumentTypes.logo,
        id: 0,
        file: image,
      })
      this.emitChange(this.logoModel)
    })
  }

  get previewImage(): DocumentClass | null {
    if (this.existingLogo) return this.existingLogo

    if (this.logoModel) return this.logoModel

    return null
  }
}
