import { AxiosResponse } from 'axios'
import Classifier from '@/classes/training/classifier'
import http from '@/classes/http'
import IClassifierResponse, {
  IClassifierForm,
  IClassifiersById,
  INewClassifier,
} from '@/interfaces/training/ClassifierInterface'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'

export default class ClassifierService {
  static prefix: string = 'classifiers'

  static getIndustriesListWithTrainingsCount(): Promise<Classifier[]> {
    return http
      .get(`${this.prefix}/industry-list-with-training-count`)
      .then((response: AxiosResponse<IPaginatedResponse<IClassifierResponse[]>>) =>
        response.data.data.map((item: IClassifierResponse) => new Classifier(item))
      )
  }

  static getIndustryList(query: any = {}, perPage: number = 15): Promise<IPaginatedResponse<Classifier[]>> {
    return http
      .get(`${ClassifierService.prefix}/get-industry-list`, {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: IClassifierResponse) => new Classifier(item)),
        meta: response.data.meta,
      }))
  }

  static getStandardList(query: any = {}, perPage: number = 15): Promise<IPaginatedResponse<Classifier[]>> {
    return http
      .get(`${ClassifierService.prefix}/standards`, {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: IClassifierResponse) => new Classifier(item)),
        meta: response.data.meta,
      }))
  }

  static getTrainingTypeList(query: any = {}, perPage: number = 15): Promise<IPaginatedResponse<Classifier[]>> {
    return http
      .get(`${ClassifierService.prefix}/training-types`, {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: IClassifierResponse) => new Classifier(item)),
        meta: response.data.meta,
      }))
  }

  static update(id: number, data: IClassifierForm): Promise<Classifier> {
    return http
      .put(`${ClassifierService.prefix}/${id}`, data)
      .then((response: AxiosResponse) => new Classifier(response.data.data))
  }

  static create(data: INewClassifier): Promise<Classifier> {
    return http.post('classifiers', data).then((response: AxiosResponse) => new Classifier(response.data.data))
  }

  static getClassifiersByCategoryOptions(category: string): Promise<IClassifiersById[]> {
    return http.get(`classifiers/by-category-name-options/${category}`).then(async (response) => response.data.data)
  }
}
