

































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { IRegisterIndividual, IRegisterResponse, IValidationParams } from '@/interfaces/AuthInterface'
import Routes from '@/router/routes'
import { UserType } from '@/classes/user/user'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'

const Global = namespace('Global')

const Auth = namespace('Auth')

@Component
export default class IndividualRegister extends Vue {
  @Prop() private bus: any
  @Prop() private agree: any
  private passwordRevealed: boolean = false
  private showPassword(): void {
    this.passwordRevealed = !this.passwordRevealed
  }
  private user: IRegisterIndividual = { name: '', email: '', password: '', type: UserType.user }
  private validationParams: IValidationParams = {
    passwordConfirmation: '',
    terms: '',
  }
  private submitted: boolean = false
  private successful: boolean = false
  private message: string = ''

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Auth.Getter
  private isLoggedIn!: boolean

  @Auth.Action
  private register!: (data: IRegisterIndividual) => Promise<IRegisterResponse>

  mounted(): void {
    if (this.isLoggedIn) {
      this.$router.push({ name: Routes.PROFILE })
    }
    this.bus.$on('submit', this.submit)
  }

  beforeDestroy(): void {
    this.bus.$off('submit', this.submit)
  }

  private async submit(token: string) {
    try {
      this.message = ''
      this.submitted = true
      this.validationParams.terms = this.agree
      this.validationParams['g-recaptcha-response'] = token

      await this.register({ ...this.user, ...this.validationParams })

      this.successful = true
      this.$emit('register:success', {
        success: true,
        email: this.user.email,
      })

      this.showSnackbar({
        type: SnackbarType.success,
        text: 'Successfully registered',
      })
    } catch (error: any) {
      this.$emit('register:failed')
      this.message = error.message
      this.scrollToErrorMessage()
      this.$validator.errors.clear()

      if (error.status !== 422) return

      const errorFields = Object.keys(error.errors)
      errorFields.forEach((field: any) => {
        const errorString = error.errors[field].join(', ')
        this.$validator.errors.add({
          field,
          msg: errorString,
        })
      })
      this.successful = false
    }
  }

  private scrollToErrorMessage(): void {
    const element = document.getElementById('message')

    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}
