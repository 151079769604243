






























































































import { Component, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { LocationTypesData, TrainingLanguages } from '@/configs/training-steps'
import AbstractField from '@/components/form/fields/AbstractField.vue'
import TrainingLocationInfo from '@/components/TrainingLocationInfo.vue'
import ITrainingDataForm, {
  ITrainingFormLocation,
  ITrainingFormLocationDate,
} from '@/interfaces/form-interfaces/TrainingDataFormInterface'
import TrainingService from '@/services/TrainingService'
import DatePickerField from '@/components/form/fields/DatePickerField.vue'
import { ISteps } from '@/store/modules/training.module'

const Training = namespace('Training')

interface FilteredDateWithIndex {
  date: ITrainingFormLocationDate
  index: number
}

@Component({ components: { DatePickerField, TrainingLocationInfo } })
export default class DateField extends AbstractField {
  LocationTypesData = LocationTypesData
  TrainingLanguages = TrainingLanguages

  @Watch('model', { deep: true }) private onDatesChange(): void {
    this.setTrainingLocationDates(this.model)
    this.setStates({ changed: true })
  }

  @Training.Getter
  private training!: ITrainingDataForm

  @Training.Mutation
  private setTrainingLocationDates!: (data: ITrainingFormLocationDate) => void

  @Training.Action
  private backupData!: () => void

  @Training.Action
  private setStates!: (states: any) => void

  @Training.Action
  private addTrainingLocationDate!: (index: any) => void

  @Training.Action
  private deleteTrainingLocationDates!: () => void

  @Training.Mutation
  private setStepData!: (stepData: ISteps) => void

  created(): void {
    if (!this.model) this.model = []

    this.setInitialDates()
    this.backupData()
    this.setStates({ changed: false })

    this.$root.$on('resetStepData', (step: number) => {
      if (step === 5) {
        this.clearData()
      }
    })
  }

  clearData(): void {
    this.deleteTrainingLocationDates()
    this.setStepData({
      activeStep: 5,
      completedStep: 5,
    })

    this.setInitialDates()
  }

  private setInitialDates() {
    this.training.trainingLocations.forEach((location: ITrainingFormLocation) => {
      if (this.filterDates(location.uuid).length > 0 || !location.uuid) return

      this.addLocationDate(location.uuid)
    })
  }

  setEndDate(event: string, dateTo: string, date: FilteredDateWithIndex): void {
    if (new Date(event).valueOf() > new Date(dateTo).valueOf()) {
      this.model[date.index].to = event
    }
  }

  private addLocationDate(locationUuid: string) {
    this.addTrainingLocationDate(locationUuid)
  }

  removeDateLine(date: FilteredDateWithIndex): void {
    if ('uuid' in date.date) {
      TrainingService.removeLocationDateFromDB(date.date.uuid).then(() => {
        this.model = this.model.filter((localDate: ITrainingFormLocationDate) => localDate.uuid !== date.date.uuid)
      })

      return
    }

    this.model = this.model.filter((localDate: ITrainingFormLocationDate, index: number) => index !== date.index)
  }

  filterDates(locationUuid: string | undefined): any {
    return this.training.trainingLocationDates.reduce(
      (array: FilteredDateWithIndex[], date: ITrainingFormLocationDate, index: number) => {
        if (date.trainingLocationUuid === locationUuid) array.push({ date, index })

        return array
      },
      []
    )
  }

  changeDate(value: any, date: FilteredDateWithIndex): void {
    const [from, to] = value.split(',')

    this.model[date.index].from = from
    this.model[date.index].to = to
  }

  getMinEndDate(startDate: string): Date {
    const today: Date = new Date()

    const startJSDate: Date = new Date(startDate)

    if (startJSDate.valueOf() > today.valueOf()) return startJSDate

    return today
  }
}
