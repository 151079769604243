








































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { namespace } from 'vuex-class'
import { DocumentTypes } from '@/configs/document-type'
import { LocationTypesData } from '@/configs/location-type'
import DocumentClass from '@/classes/user/document'
import Routes from '@/router/routes'
import CartActionButton from '@/components/form/cart/CartActionButton.vue'
import TrainingLocationDate from '@/classes/training/training-location-date'

const Auth = namespace('Auth')

@Component({
  components: {
    CartActionButton,
  },
})
export default class TrainingCard extends Vue {
  @Prop() private readonly trainingDate!: TrainingLocationDate
  @Prop() private readonly cartRemove!: boolean
  @Prop({ default: true }) private readonly cartShow!: boolean
  LocationTypesData = LocationTypesData
  Routes = Routes

  @Auth.Getter
  private userIsTrainingCenter!: boolean

  private favoriteToggle(): void {
    this.$emit('favoriteToggle', this.trainingDate.uuid)
  }

  get image(): DocumentClass | undefined {
    return _.find(
      this.trainingDate.training?.user?.documents,
      (document: DocumentClass) => document.type === DocumentTypes.logo
    )
  }
}
