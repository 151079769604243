






























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import { namespace } from 'vuex-class'
import _ from 'lodash'
import { AxiosError } from 'axios'
import { IJobData } from '@/interfaces/JobPositionsInterface'
import JobPositionApplicationModal from '@/components/JobPositionApplicationModal.vue'
import User from '@/classes/user/user'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import JobPositionsService from '@/services/JobPositionsService'
import CertificateService from '@/services/certificateService'
import ITrainingDataResponse from '@/interfaces/training/TrainingDataInterface'

const Auth = namespace('Auth')

const Global = namespace('Global')

@Component({
  components: { JobPositionApplicationModal },
})
export default class JobDetails extends Vue {
  @Prop() data!: IJobData

  @Auth.Getter
  private user!: User

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  private applicationComplete: boolean = false

  private userCertificates: ITrainingDataResponse[] | null = null

  created(): void {
    if (this.user !== null && this.user.type === 'cadet') {
      CertificateService.getMyCertificateList(1, 10).then((response) => (this.userCertificates = response.data))
    }
  }

  private processDate(date: string): string {
    const dateObject = DateTime.fromJSDate(new Date(date))

    return dateObject.toFormat('yyyy-MM-dd')
  }

  private sendApplicationRegistered(): void {
    this.applicationComplete = true
    JobPositionsService.applyAsRegisteredUser(this.data.id)
      .then(() => {
        this.showSnackbar({
          type: SnackbarType.success,
          text: 'Successfully applied for the position',
        })
      })
      .catch((error: AxiosError) => {
        let errorText = 'Application error'

        if (typeof error.response !== 'undefined') {
          errorText = error.response.data.message
        }
        this.applicationComplete = false
        this.showSnackbar({
          type: SnackbarType.error,
          text: errorText,
        })
      })
  }

  private checkCertificate(certificateID: number): string {
    if (_.some(this.userCertificates, ['training_type_classifier_id', certificateID])) {
      return 'pass'
    }

    return 'fail'
  }

  get logoUrl(): string {
    if (this.data.company.user.logo !== null) {
      return this.data.company.user.logo.file_path
    }

    return require('@/assets/images/logo-thumbnail.png')
  }
}
