



















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AxiosError } from 'axios'
import _ from 'lodash'
import { namespace } from 'vuex-class'
import { VueEditor } from 'vue2-editor'
import { INewClassifier } from '@/interfaces/training/ClassifierInterface'
import ClassifierService from '@/services/ClassifierService'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import TextArea from '@/components/form/fields/TextArea.vue'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'
import Classifier from '@/classes/training/classifier'
import IClassifierOption from '@/interfaces/ClassifierOptionInterface'

const Global = namespace('Global')

@Component({
  components: { TextArea, VueEditor },
})
export default class CreateClassifierModal extends Vue {
  @Prop() refreshList!: () => void

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  visible: boolean = false
  data: INewClassifier = {
    title: '',
    description: '',
    validity: 0,
    classifier_parent_id: '',
    class_category_id: '3',
  }

  loading: boolean = false
  formErrors: any = {}
  standardsList: IClassifierOption[] = []

  created(): void {
    ClassifierService.getStandardList({}, 999).then((response: IPaginatedResponse<Classifier[]>) => {
      this.standardsList = this.mapCertificateClassifiers(response.data)
    })
  }

  private submit(): void {
    this.loading = true

    ClassifierService.create(this.data)
      .then(() => {
        this.refreshList()

        this.showSnackbar({
          type: SnackbarType.success,
          text: 'Classifier successfully created',
        })

        this.close()
      })
      .catch((error: AxiosError) => {
        this.formErrors = error.response?.data.errors

        this.showSnackbar({
          type: SnackbarType.error,
          text: error.response?.data.message,
        })
      })
      .finally(() => (this.loading = false))
  }

  public toggle(): void {
    this.visible = !this.visible
  }

  public close(): void {
    this.visible = false
  }

  private getError(key: string): string[] | null {
    return _.get(this.formErrors, `${key}.0`, null)
  }

  private removeError(key: string): void {
    delete this.formErrors[key]
  }

  private mapCertificateClassifiers(data: Classifier[]): IClassifierOption[] {
    return data.map((item: Classifier) => ({
      value: item.id,
      text: `${item.industryTitle} | ${item.title}`,
      industryId: item.industryId || null,
      standardId: item.standardId || null,
    }))
  }
}
