











































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { BvTableFieldArray } from 'bootstrap-vue/src/components/table'
import _ from 'lodash'
import { namespace } from 'vuex-class'
import { IMeta, IPaginatedResponse } from '@/interfaces/ResponseInterface'
import TrainingLocationDate from '@/classes/training/training-location-date'
import { ITableProperties, ITableSortChanged } from '@/interfaces/TableInterface'
import Routes from '@/router/routes'
import Classifier from '@/classes/training/classifier'
import TrainingService from '@/services/TrainingService'
import Student from '@/classes/student'
import OrderItemUserService from '@/services/OrderItemUserService'
import SelectOption from '@/interfaces/SelectOption'
import EmptyList from '@/components/EmptyList.vue'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import { ICartResponseData } from '@/interfaces/CartInterface'
import Pagination from '@/components/Pagination.vue'
import Search from '@/components/Search.vue'
import scrollToRef from '@/helpers/scroll-to-ref-helper'

const Global = namespace('Global')

const Auth = namespace('Auth')

const Cart = namespace('Cart')

@Component({
  components: { Search, EmptyList, Pagination },
})
export default class CompanyTrainingsTable extends Vue {
  @Prop() private meta!: IMeta
  @Prop() private trainingLocationDates!: TrainingLocationDate[]
  @Prop() private tableProperties!: ITableProperties
  @Prop() private refreshList!: () => void
  @Prop({ default: false }) forUser!: boolean

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Cart.Action
  private addItemToCart!: (trainingUuid: string) => Promise<ICartResponseData>

  @Cart.Action
  private fetchCart!: () => Promise<ICartResponseData[]>

  @Auth.Getter
  private userIsDefault!: boolean

  private loading: boolean = false
  private addToCartLoading: boolean = false

  Routes = Routes

  public trainingLocationDateStudents: { [x: string]: Student[] } = {}

  public search: string = ''
  public date: string = ''
  public opened: boolean = false
  private industryClassifiers: Classifier[] = []
  private standardClassifiers: Classifier[] = []

  private onDateChange = _.debounce(this.dateChanged, 500)

  created(): void {
    this.search = this.tableProperties.filters.search
    this.date = this.tableProperties.filters.from

    this.$watch(() => this.tableProperties.filters.from, this.onDateChanged, { deep: true })

    this.loadFiltersData()
  }

  private async loadFiltersData(): Promise<void> {
    this.loading = true
    try {
      this.industryClassifiers = await TrainingService.getClassifiersByCategory('industry')
      this.standardClassifiers = await TrainingService.getClassifiersByCategory('standard')

      this.loading = false
    } catch (error: any) {
      this.loading = false
      this.showSnackbar({
        type: SnackbarType.error,
        text: `Trainings data cannot be retrieved. ${error.message}`,
      })
    }
  }

  private async buyMoreSeats(trainingUuid: string): Promise<void> {
    this.addToCartLoading = true
    try {
      await this.addItemToCart(trainingUuid)
      await this.fetchCart()
      this.addToCartLoading = false
      this.showSnackbar({
        type: SnackbarType.success,
        text: 'Training added to cart',
      })
      this.addToCartLoading = false
      await this.$router.push({ name: Routes.CART })
    } catch (error: any) {
      this.addToCartLoading = false
      this.showSnackbar({
        type: SnackbarType.error,
        text: `Trainings add to cart failed. ${error.message}`,
      })
    }
  }

  private onToggleStudentList(currentState: boolean, uuid: string, toggle: () => void): void {
    if (!currentState && !this.trainingLocationDateStudents[uuid]) {
      OrderItemUserService.getByTrainingLocationDate(
        uuid,
        {
          'filter-orderItem-order-status': 'completed',
        },
        9999
      ).then(
        (response: IPaginatedResponse<Student[]>) =>
          (this.trainingLocationDateStudents = {
            ...this.trainingLocationDateStudents,
            [uuid]: response.data,
          })
      )
    }

    toggle()
  }

  private onPageInput(id: string): void {
    this.loading = true

    scrollToRef(id)
  }

  private onSearch(search: string): void {
    this.search = search

    this.tableProperties.filters = {
      ...this.tableProperties.filters,
      search: this.search,
    }
  }

  private dateChanged(): void {
    if (this.date === this.tableProperties.filters.from) return

    this.tableProperties.filters = {
      ...this.tableProperties.filters,
      from: this.date,
    }
  }

  private onTableSortChange(event: ITableSortChanged): void {
    this.tableProperties.sortBy = event.sortBy
    this.tableProperties.order = event.sortDesc ? 'desc' : 'asc'
  }

  private onPageChange(page: number): void {
    this.tableProperties.currentPage = page
  }

  private getToggleOrderEvent(order: string): any {
    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'asc') {
      return {
        sortBy: '',
        sortDesc: false,
      }
    }

    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'desc') {
      return {
        sortBy: order,
        sortDesc: false,
      }
    }

    return {
      sortBy: order,
      sortDesc: true,
    }
  }

  private getFilterOrderClass(order: string): string {
    if (this.tableProperties.sortBy === order) {
      return this.tableProperties.order || ''
    }

    return ''
  }

  private clearAllFilters(): void {
    this.tableProperties.filters.industryClassifierId = []
    this.tableProperties.filters.standardClassifierId = ''
    this.tableProperties.filters.from = ''
    this.tableProperties.filters.status = []
    this.tableProperties.filters.search = ''
    this.date = ''
    this.search = ''

    const { industryFilter, standardFilter, filterDate }: any = this.$refs

    if (industryFilter) industryFilter.inputValue = ''

    if (standardFilter) standardFilter.inputValue = ''

    if (filterDate) filterDate.initialValue = ''
  }

  private getFields(): BvTableFieldArray {
    const fields = [
      {
        key: 'status',
        label: '',
        class: 'profile-training-table__status ',
      },
      {
        key: 'training.title',
        label: 'Training name',
        sortable: true,
        class: 'profile-training-table__name',
      },
      {
        key: 'attendedStatus',
        label: 'Status',
      },
      {
        key: 'from',
        label: 'Starts<br><span>Duration</span>',
        sortable: true,
      },
      {
        key: 'training.industryClassifier.title',
        label: 'Industry',
        sortable: true,
      },
      {
        key: 'training.standardClassifier.title',
        label: 'Standard',
        sortable: true,
      },
      {
        key: 'trainingLocation',
        label: 'Location',
        sortable: false,
      },
      {
        key: 'priceWithVat',
        label: this.userIsDefault ? 'Price' : 'Total',
        sortable: true,
      },
    ]

    if (!this.forUser)
      fields.push({
        key: 'list',
        label: '',
        sortable: false,
      })

    return fields
  }

  private toggle(): void {
    this.opened = !this.opened
  }

  private onDateChanged(): void {
    this.date = this.tableProperties.filters.from

    const trainingFilterRef: any = this.$refs.trainingFilter
    trainingFilterRef.scrollIntoView({ behavior: 'smooth' })
  }

  get industryClassifierSelections(): SelectOption[] {
    return this.industryClassifiers.map((industry: Classifier) => ({
      value: industry.id,
      text: industry.title,
    }))
  }

  get standardClassifierSelections(): SelectOption[] {
    return this.standardClassifiers.map((standard: Classifier) => ({
      value: standard.id,
      text: standard.title,
    }))
  }

  getVindaID(student: Student) {
    return student.user?.userProfile?.vindaId
  }
}
