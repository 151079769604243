
import _ from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class AbstractField extends Vue {
  @Prop() readonly id!: string
  @Prop() readonly title!: string
  @Prop() readonly placeholder!: string
  @Prop({ default: false }) readonly readonly!: boolean
  @Prop({ default: false }) readonly required!: boolean
  @Prop() value!: any
  @Prop({ default: '' }) readonly additionalClass!: string
  @Prop({ default: '' }) readonly additionalInputClass!: string
  @Prop({ default: null }) readonly max!: number
  @Prop({ default: null }) readonly min!: number
  @Prop({ default: false }) readonly small!: boolean
  @Prop({ default: false }) readonly large!: boolean

  created(): void {
    if (this.value) this.model = this.value
  }
  model: any = this.value || null

  @Watch('value') private onValueChange(newValue: boolean): void {
    this.model = newValue
  }

  emitChange(event: any): void {
    this.$emit('input', event)
    this.$emit('onChange', event)
    this.$emit('clearError', this.id)
  }

  emitKeydown(event: any): void {
    this.$emit('keydown', event)
  }

  get hasError(): boolean {
    return this.fieldErrors.length > 0
  }

  get hasErrorState(): boolean | null {
    return this.hasError ? false : null
  }

  get fieldErrors(): any {
    return _.filter(this.errors.items, { field: this.id })
  }
}
