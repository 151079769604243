















import { Component, Prop, Vue } from 'vue-property-decorator'
import Routes from '@/router/routes'

@Component
export default class TermsCheckBox extends Vue {
  @Prop({ default: false }) private readonly alignCenter!: boolean
  private agree: boolean = false
  Routes = Routes

  private toggle(): void {
    this.$emit('termsClick', this.agree)
  }
}
