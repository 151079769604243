























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { AxiosError } from 'axios'
import { IJobApplicationPayload, IJobData } from '@/interfaces/JobPositionsInterface'
import { IClassifiersById } from '@/interfaces/training/ClassifierInterface'
import SelectOption from '@/interfaces/SelectOption'
import ClassifierService from '@/services/ClassifierService'
import { ClassifiersCategory } from '@/components/AddEmployeesModal.vue'
import UserCvField from '@/components/form/fields/UserCvField.vue'
import JobFileUpload from '@/components/form/fields/jobs/JobFileUpload.vue'
import PhotoGalleryField from '@/components/form/fields/PhotoGalleryField.vue'
import JobPositionsService from '@/services/JobPositionsService'
import DocumentClass from '@/classes/user/document'
import Routes from '@/router/routes'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'

const Global = namespace('Global')

@Component({
  components: { PhotoGalleryField, JobFileUpload, UserCvField },
})
export default class JobPositionApplicationModal extends Vue {
  @Prop() jobData!: IJobData
  Routes = Routes

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  private positionTypes: IClassifiersById[] = []
  private nationalityTypes: IClassifiersById[] = []
  private userAgreement: boolean = false

  private applicationPayload: IJobApplicationPayload = {
    name: '',
    email: '',
    phone_number: '',
    user_profile: {
      position_classifier_id: null,
      nationality_classifier_id: null,
    },
    documents: [],
    'g-recaptcha-response': '',
  }

  async created(): Promise<void> {
    this.positionTypes = await ClassifierService.getClassifiersByCategoryOptions(ClassifiersCategory.employeePosition)
    this.nationalityTypes = await ClassifierService.getClassifiersByCategoryOptions(ClassifiersCategory.nationality)
  }

  get positionOptions(): SelectOption[] {
    return this.positionTypes.map((position: IClassifiersById) => ({
      value: position.value,
      text: position.label,
    }))
  }

  get nationalityOptions(): SelectOption[] {
    return this.nationalityTypes.map((nationality: IClassifiersById) => ({
      value: nationality.value,
      text: nationality.label,
    }))
  }

  async submitApplication(): Promise<void> {
    await this.$recaptchaLoaded()
    this.applicationPayload['g-recaptcha-response'] = await this.$recaptcha('login')

    const formData = new FormData()

    formData.append('name', this.applicationPayload.name)
    formData.append('email', this.applicationPayload.email)
    formData.append('phone_number', this.applicationPayload.phone_number)
    formData.append(
      'user_profile[position_classifier_id]',
      `${this.applicationPayload.user_profile.position_classifier_id}`
    )
    formData.append(
      'user_profile[nationality_classifier_id]',
      `${this.applicationPayload.user_profile.nationality_classifier_id}`
    )
    formData.append('g-recaptcha-response', this.applicationPayload['g-recaptcha-response'])
    JobPositionsService.applyAsNewUser(
      this.jobData.id,
      this.appendDocumentToFormData(formData, [this.applicationPayload.documents])
    )
      .then(() => {
        this.showSnackbar({
          type: SnackbarType.success,
          text: 'Successfully applied for the position',
        })
        this.$bvModal.hide('jobApplication')
      })
      .catch((error: AxiosError) => {
        this.setErrorsFromResponse(error)
        this.showSnackbar({
          type: SnackbarType.error,
          text: 'Application error',
        })
      })
  }

  appendDocumentToFormData(formData: FormData, data: any): FormData {
    data.forEach((document: DocumentClass, index: number) => {
      if (!document) return
      Object.keys(document).forEach((key: string) => {
        if (data[index][key]) formData.append(`documents[${index}][${key}]`, data[index][key])
      })
    })

    return formData
  }

  closeModal(): void {
    this.$bvModal.hide('jobApplication')
  }
}
