









































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { BvTableFieldArray } from 'bootstrap-vue/src/components/table'
import _ from 'lodash'
import { IApplicant, IJobData } from '@/interfaces/JobPositionsInterface'
import { ITableProperties } from '@/interfaces/TableInterface'
import Routes from '@/router/routes'
import { IMeta } from '@/interfaces/ResponseInterface'
import Pagination from '@/components/Pagination.vue'
import scrollToRef from '@/helpers/scroll-to-ref-helper'
import JobPositionsService from '@/services/JobPositionsService'

@Component({
  components: { Pagination },
})
export default class JobApplicantsTable extends Vue {
  @Prop() jobData!: IJobData[]
  @Prop() tableProperties!: ITableProperties
  @Prop() private meta!: IMeta

  Routes = Routes

  public jobApplicants: { [x: number]: IApplicant[] } = {}

  private tableFields: BvTableFieldArray = [
    {
      key: 'status',
      label: '',
      class: 'profile-training-table__status ',
    },
    {
      key: 'title',
      label: 'Job position',
      sortable: true,
      class: 'profile-training-table__name',
    },
    {
      key: 'start_date',
      label: 'Starts',
      sortable: true,
    },
    {
      key: 'end_date',
      label: 'Expires',
      sortable: true,
    },
    {
      key: 'country',
      label: 'Location',
      sortable: true,
    },
    {
      key: 'applicant_count',
      label: 'Applicants',
      sortable: true,
    },
    {
      key: 'list',
      label: '',
      sortable: false,
    },
  ]

  private onPageChange(page: number): void {
    this.tableProperties.currentPage = page
  }

  private onPageInput(id: string): void {
    scrollToRef(id)
  }

  private getJobPositionStatus(row: any): string {
    const startDate = new Date(row.item.start_date).getTime()

    const endDate = new Date(row.item.end_date).getTime()

    const now = new Date().getTime()

    if (now < startDate) {
      return 'upcoming'
    }

    if (now > endDate) {
      return 'ended'
    }

    return 'current'
  }

  private onToggleApplicantList(currentState: boolean, rowData: any, toggle: () => void): void {
    if (!currentState && typeof this.jobApplicants[rowData.id] === 'undefined') {
      JobPositionsService.getJobPositionApplicants(rowData.id).then((response) => {
        const updatedApplicants = _.cloneDeep(this.jobApplicants)
        updatedApplicants[rowData.id] = response
        this.jobApplicants = updatedApplicants
      })
    }

    toggle()
  }
}
