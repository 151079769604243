import Vue from 'vue'
import Vuex from 'vuex'
import Auth from '@/store/modules/auth.module'
import Global from '@/store/modules/global.module'
import Training from '@/store/modules/training.module'
import Cart from '@/store/modules/cart.module'
import Company from '@/store/modules/company.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Auth,
    Global,
    Training,
    Cart,
    Company,
  },
})
