


















import { Component, Vue } from 'vue-property-decorator'
import { AxiosError } from 'axios'
import { namespace } from 'vuex-class'
import TrainingLocationDateService from '@/services/TrainingLocationDateService'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'
import TrainingLocationDate from '@/classes/training/training-location-date'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import TrainingCard from '@/components/TrainingCard.vue'
import Routes from '@/router/routes'

const Global = namespace('Global')

@Component({
  components: { TrainingCard },
})
export default class NewTrainings extends Vue {
  Routes = Routes
  trainings: TrainingLocationDate[] | null = null

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  created(): void {
    TrainingLocationDateService.newest({}, 6)
      .then((response: IPaginatedResponse<TrainingLocationDate[]>) => (this.trainings = response.data))
      .catch((error: AxiosError) => {
        this.showSnackbar({
          type: SnackbarType.error,
          text: String(error),
        })
      })
  }

  goTo(name: string): void {
    this.$router.push({ name }).catch(() => {})
  }
}
