























import { Component, Prop } from 'vue-property-decorator'
import AbstractField from '@/components/form/fields/AbstractField.vue'

@Component
export default class FilterTextField extends AbstractField {
  @Prop({ default: 'text' }) private readonly type!: string
}
