import { ITrainingFormLocation } from '@/interfaces/form-interfaces/TrainingDataFormInterface'
import ITrainingLocationResponse from '@/interfaces/training/TrainingLocationInterface'
import Model from '@/classes/model'
import { LocationTypesData } from '@/configs/location-type'
import TrainingData from '@/classes/training/training'

export default class TrainingLocation extends Model {
  trainingId!: number
  location!: string
  country!: string
  city!: string
  address!: string
  post!: string
  url!: string
  training!: TrainingData

  constructor(data: ITrainingLocationResponse) {
    super(data)

    this.trainingId = data.training_id
    this.location = data.location || data.country
    this.country = data.country
    this.city = data.city
    this.address = data.address
    this.post = data.post
    this.url = data.url
    this.training = this.setRelation(data.training, TrainingData)
  }

  toFormData(): ITrainingFormLocation {
    return {
      uuid: this.uuid,
      trainingUuid: this.training.uuid,
      location: this.location,
      country: this.country,
      city: this.city,
      address: this.address,
      post: this.post,
      url: this.url,
    }
  }

  get formattedLocation(): string {
    const { country, city, location } = this

    return LocationTypesData[location] === LocationTypesData.online
      ? LocationTypesData.online
      : Object.values({ country, city }).filter(Boolean).join(', ')
  }

  get fullLocation(): string {
    const { country, city, address, post, location } = this

    return LocationTypesData[location] === LocationTypesData.online
      ? LocationTypesData.online
      : Object.values({ country, city, address, post }).filter(Boolean).join(', ')
  }
}
