import IOrderItemResponse from '@/interfaces/OrderItemInterface'
import Model from '@/classes/model'

export default class OrderItem extends Model {
  count!: number
  discount!: string
  price!: string
  total!: string
  totalNumber!: number
  totalPerUnit!: string
  totalPerUnitWithoutVat!: string
  trainingEndDate!: string
  vat!: string

  constructor(data: IOrderItemResponse) {
    super(data)

    this.count = data.count
    this.discount = data.discount
    this.price = data.price
    this.totalPerUnitWithoutVat = data.total_per_unit_without_vat
    this.total = data.total
    this.totalNumber = data.total_number
    this.totalPerUnit = data.total_per_unit
    this.trainingEndDate = data.training_end_date
  }
}
