

























import { Component, Vue } from 'vue-property-decorator'
import Search from '@/components/Search.vue'
import Routes from '@/router/routes'

@Component({
  components: { Search },
})
export default class Hero extends Vue {
  onSearch(searchKeyword: string): void {
    this.$router.push({
      name: Routes.TRAININGS,
      query: {
        q: searchKeyword,
      },
    })
  }
}
