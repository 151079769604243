


















import { Component, Prop } from 'vue-property-decorator'
import AbstractField from '@/components/form/fields/AbstractField.vue'

@Component
export default class TextArea extends AbstractField {
  @Prop({ default: 3 }) private readonly rows!: number
}
