

























































import { Component, Prop, Watch } from 'vue-property-decorator'
import { IDocumentResponse } from '@/interfaces/user/UserInterface'
import AbstractField from '@/components/form/fields/AbstractField.vue'
import DocumentClass from '@/classes/user/document'
import { DocumentTypes } from '@/configs/document-type'
import resizeAndCompress from '@/helpers/image-compress'

@Component
export default class PhotoGalleryField extends AbstractField {
  @Prop({ default: 6 }) private readonly maxLimit!: number
  @Prop({ default: 20 }) private readonly maxSizeLimit!: number
  @Prop() private readonly existingPhotos!: DocumentClass[]
  showImagePlaceholder: boolean = true
  galleryModel: DocumentClass[] = []

  @Watch('previewImages') private onFileChange(newValue: IDocumentResponse[]): void {
    this.showImagePlaceholder = !(newValue.length > 0)
  }

  created(): void {
    this.galleryModel = []
    this.emitChange(this.galleryModel)
  }

  validate(image: File): boolean {
    return this.imageLeftCounter !== 0 && this.handleSizeError(image)
  }

  async addFile(image: File): Promise<void> {
    const compressedImage: File = await resizeAndCompress(image)
    this.galleryModel.push(
      new DocumentClass({
        file: compressedImage,
        type: DocumentTypes.photo,
        id: this.galleryModel.length,
      })
    )

    this.emitChange(this.galleryModel)
  }

  saveFiles(event: any): void {
    this.galleryModel = []

    event.target.files.forEach((file: File) => {
      const valid = this.validate(file)

      if (valid) {
        this.addFile(file)
      }
    })
  }

  pushFiles(event: any): void {
    event.target.files.forEach((file: File) => {
      const valid = this.validate(file)

      if (valid) {
        this.addFile(file)
      }
    })
  }

  handleSizeError(image: File): boolean {
    const fileSize = image.size / 1024 / 1024

    if (fileSize > this.maxSizeLimit)
      this.$emit('appendError', { field: this.id, msg: `${image.name} is too large (${fileSize.toFixed(2)}MB).` })

    return fileSize < this.maxSizeLimit
  }

  removeImage(index: number): void {
    if (this.previewImages[index].file instanceof File) {
      this.galleryModel = this.galleryModel.filter(
        (document: DocumentClass) => document.id !== this.previewImages[index].id
      )
      this.emitChange(this.galleryModel)

      return
    }

    this.$emit('removeImage', this.previewImages[index], this.id)
  }

  get imageLeftCounter(): number {
    return !this.galleryModel ? this.maxLimit : this.maxLimit - this.previewImages.length
  }

  get previewImages(): DocumentClass[] {
    return this.existingPhotos.concat(this.galleryModel)
  }
}
