import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import ISnackbar from '@/interfaces/SnackbarInterface'
import { globalMutations } from '@/store/mutations/global.mutations'
import IConfirmDialog from '@/interfaces/ConfirmDialogInterface'

@Module({ namespaced: true })
class Global extends VuexModule {
  public snackbarInstance: ISnackbar | null = null
  public confirmDialogInstance: IConfirmDialog | null = null

  @Mutation
  setSnackbar(snackbar: ISnackbar | null): void {
    this.snackbarInstance = snackbar
  }

  @Mutation
  setConfirmDialog(confirmDialog: IConfirmDialog | null): void {
    this.confirmDialogInstance = confirmDialog
  }

  @Action
  showSnackbar(snackbar: ISnackbar): void {
    this.context.commit(globalMutations.SET_SNACKBAR, snackbar)
  }

  @Action
  hideSnackbar(): void {
    this.context.commit(globalMutations.SET_SNACKBAR, null)
  }

  @Action
  showConfirmDialog(confirmDialog: IConfirmDialog): void {
    this.context.commit(globalMutations.SET_CONFIRM_DIALOG, confirmDialog)
  }

  @Action
  hideConfirmDialog(): void {
    this.context.commit(globalMutations.SET_CONFIRM_DIALOG, null)
  }

  get snackbar(): ISnackbar | null {
    return this.snackbarInstance
  }

  get confirmDialog(): IConfirmDialog | null {
    return this.confirmDialogInstance
  }
}

export default Global
