
















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import _ from 'lodash'
import AddEmployeesModal from '@/components/AddEmployeesModal.vue'
import {
  ICartEmployee,
  ICartEmployeesModalProp,
  ICartItemUser,
  ICartResponseData,
  IUpdateCartEmployeesData,
} from '@/interfaces/CartInterface'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import Search from '@/components/Search.vue'
import OrderItemUserService from '@/services/OrderItemUserService'

const Global = namespace('Global')

const Cart = namespace('Cart')

const employeeAddLimit = 15

@Component({
  components: { CartAddEmployees: AddEmployeesModal, Search },
})
export default class CartSelectModal extends Vue {
  @Prop() private allEmployees!: ICartEmployee[]
  @Prop() private selectedCardData!: ICartEmployeesModalProp
  private checkedEmployees: Array<string> = []
  private addNewEmployees: boolean = false
  private loading: boolean = false
  private employeesLoading: boolean = false
  private initialSelections: Array<string> = []
  public search: string = ''
  private filteredEmployees: ICartEmployee[] = []
  private allEmployeesEmails: Array<string> = []
  private onSearchKeyDown = _.debounce(this.clearResultsIfEmpty, 300)
  private disabledEmployeesIds: Array<string> = []
  private modalOpen: boolean = false

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Cart.Action
  private fetchCart!: () => Promise<ICartResponseData[]>

  @Cart.Action
  private updateCartEmployeesList!: (data: IUpdateCartEmployeesData) => Promise<void>

  async mounted(): Promise<void> {
    this.$root.$on('bv::collapse::state', (collapseId: string, isJustShown: boolean) => {
      this.addNewEmployees = collapseId === 'newEmployees' && isJustShown
      this.checkedEmployees = Object.assign([], this.selectedCardData.cartEmployeeList).map(
        (item: ICartEmployee) => item.employee.uuid
      )
      this.initialSelections = [...this.checkedEmployees]
    })
    this.$root.$on('newEmployeesAdded:success', () => {
      this.modalOpen = false
      this.employeesLoading = true
      this.loading = true
    })

    this.loading = true
    this.filteredEmployees = this.allEmployees
    this.allEmployeesEmails = this.allEmployees.map((item) => item.employee.email)

    const response = await OrderItemUserService.getByTrainingLocationDate(
      this.selectedCardData.trainingUuid,
      {
        'filter-orderItem-order-status': 'completed',
      },
      9999
    )
    this.disabledEmployeesIds = response.data.map((item) => item.user.uuid)

    this.loading = false
  }

  @Watch('allEmployees') private onValueChange(): void {
    this.employeesLoading = false
    this.loading = false
    this.filteredEmployees = this.allEmployees
  }

  beforeDestroy() {
    this.$root.$off('bv::collapse::state')
    this.$root.$off('newEmployeesAdded:success')
  }

  private searchAction(): void {
    this.filteredEmployees = []
    this.filteredEmployees = this.allEmployees.filter((employee) => employee.employee.name.includes(this.search))

    this.clearResultsIfEmpty()
  }

  private clearResultsIfEmpty(): void {
    if (this.search === '') {
      this.filteredEmployees = this.allEmployees
    }
  }

  private async applyChanges() {
    try {
      if (!this.checkedEmployees.length) {
        this.showSnackbar({
          type: SnackbarType.warning,
          text: 'Please select at least one employee to continue',
        })

        return
      }

      if (this.checkedEmployees.length > employeeAddLimit) {
        this.showSnackbar({
          type: SnackbarType.warning,
          text: `Please select up to ${employeeAddLimit} employees`,
        })

        return
      }

      if (_.isEqual(this.initialSelections, this.checkedEmployees)) {
        this.$bvModal.hide('selectEmployees')

        return
      }

      this.loading = true

      const allEmployees = this.formAllEmployeesData()

      const employeesToAdd = this.checkedEmployees

      const employeesToRemove = allEmployees
        .filter((employee) => !this.checkedEmployees.includes(employee.employeeUuid))
        .map((item) => item.cartItemUserUuid as string)

      await this.updateCartEmployeesList({
        cartUuid: this.selectedCardData.cartItemUuid,
        add: employeesToAdd,
        remove: employeesToRemove,
      })
      await this.fetchCart()
      this.loading = false
      this.$root.$emit('bv::employeesUpdated')
      this.$bvModal.hide('selectEmployees')
      this.search = ''

      this.showSnackbar({
        type: SnackbarType.success,
        text: 'Employees list updated successfully',
      })
    } catch (error) {
      this.showSnackbar({
        type: SnackbarType.error,
        text: 'Could not update employees list',
      })
      this.loading = false
    }
  }

  private formAllEmployeesData() {
    return Object.assign([], this.selectedCardData.cartEmployeeList).map((item: ICartEmployee) => {
      return {
        employeeUuid: item.employee.uuid,
        userId: item.id,
        cartItemUserUuid: item.cart_item_user_uuid,
      }
    })
  }

  private isDisabledEmployee(employeeUuid: string): boolean {
    return this.disabledEmployeesIds.includes(employeeUuid)
  }

  private openAddEmployeesModal(): void {
    this.modalOpen = true
    this.$root.$emit('bv::toggle::collapse', 'newEmployees')
  }

  private getAssignedPosition(employee: ICartItemUser): string {
    return employee.user_profile.position_classifier ? employee.user_profile.position_classifier.title : ''
  }

  clear(): void {
    this.search = ''

    this.searchAction()
  }
}
