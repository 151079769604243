








import { Component, Vue } from 'vue-property-decorator'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

@Component({
  components: { Breadcrumbs },
})
export default class Prices extends Vue {}
