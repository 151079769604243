






















import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import User from '@/classes/user/user'
import TrainingCenter from '@/classes/user/training-center'
import TrainingCenterService from '@/services/TrainingCenterService'

const Auth = namespace('Auth')

@Component
export default class TrainingCenterDetails extends Vue {
  @Auth.Getter
  private user!: User

  public trainingCenter: TrainingCenter = null!
  public loading = true

  public created(): void {
    this.loadTrainingCenterDetails()
  }

  public loadTrainingCenterDetails(): void {
    this.loading = true

    TrainingCenterService.getDetails(this.user.trainingCenter.slug)
      .then((trainingCenter: TrainingCenter) => (this.trainingCenter = trainingCenter))
      .finally(() => (this.loading = false))
  }
}
