
































































import { Component, Vue } from 'vue-property-decorator'
import { AxiosError } from 'axios'
import Routes from '@/router/routes'
import NewsletterService from '@/services/NewsletterService'

@Component
export default class Footer extends Vue {
  Routes = Routes
  email: string = ''
  success: boolean = false

  submitEmail(): void {
    if (this.success) return
    NewsletterService.subscribeEmailToNewsletter(this.email)
      .then(() => (this.success = true))
      .catch((error: AxiosError) => this.setErrorsFromResponse(error))
  }

  resetForm(): void {
    this.success = false
    this.resetErrors()
  }
}
