enum trainingActions {
  SUBMIT_TRAINING_INFO = 'submitTrainingInfo',
  SUBMIT_TRAINING_LOCATIONS = 'submitTrainingLocations',
  SUBMIT_TRAINING_LOCATION_DATES = 'submitTrainingLocationDates',
  SUBMIT_TRAINING_LOCATION_DATE_SERVICES = 'submitTrainingLocationDateServices',
  BACKUP_DATA = 'backupData',
  SET_NEW_TRAINING = 'setNewTraining',
  SET_STATES = 'setStates',
  APPEND_TO_TRAINING = 'appendToTraining',
  RESET_TRAINING = 'resetTraining',
  CHANGE_LOADING_STATE = 'changeLoadingState',
}

export default trainingActions
