import { IUserCompanyForm } from '@/interfaces/form-interfaces/UserFormInterface'
import { ICompanyResponse } from '@/interfaces/user/UserInterface'
import Model from '@/classes/model'

export default class Company extends Model {
  name!: string
  code!: string
  vat!: string

  constructor(data: ICompanyResponse) {
    super(data)

    this.name = data.name
    this.code = data.code
    this.vat = data.vat
  }

  toFormData(): IUserCompanyForm {
    return {
      name: this.name || '',
      code: this.code || '',
      vat: this.vat || '',
    }
  }
}
