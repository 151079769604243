




























































import { Component, Prop, Vue } from 'vue-property-decorator'
import Routes from '@/router/routes'
import TrainingCenterListUser from '@/classes/user/training-center-list-user'

@Component
export default class TrainingCenterCard extends Vue {
  @Prop() private readonly trainingCenter!: TrainingCenterListUser
  Routes = Routes

  goToTrainingCenter(): void {
    this.$router.push({
      name: Routes.TRAINING_CENTER_INNER,
      params: {
        slug: this.trainingCenter.trainingCenter.slug,
      },
      query: {
        scrollToFilters: 'true',
      },
    })
  }

  get activeIndustries(): string[] {
    return this.trainingCenter && this.trainingCenter.trainingCenter
      ? this.trainingCenter.trainingCenter.industry.getActiveIndustries()
      : []
  }
}
