import { AxiosResponse } from 'axios'
import http from '@/classes/http'
import CompanyDiscount from '@/classes/company-discount'
import ICompanyDiscountResponse, { ICompanyDiscountStoreData } from '@/interfaces/CompanyDiscountInterface'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'

export default class CompanyDiscountService {
  static list(query: any, perPage: number = 15): Promise<IPaginatedResponse<CompanyDiscount[]>> {
    return http
      .get('company-discounts/my-companies-discounts', {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: ICompanyDiscountResponse) => new CompanyDiscount(item)),
        meta: response.data.meta,
      }))
  }

  static store(data: ICompanyDiscountStoreData): Promise<CompanyDiscount> {
    return http
      .post('company-discounts', data)
      .then((response: AxiosResponse) => new CompanyDiscount(response.data.data))
  }

  static destroy(id: number): Promise<void> {
    return http.delete(`company-discounts/${id}`)
  }
}
