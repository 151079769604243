






































































































import { Component, Vue } from 'vue-property-decorator'
import { BvTableFieldArray } from 'bootstrap-vue/src/components/table'
import _ from 'lodash'
import { IMeta, IPaginatedResponse } from '@/interfaces/ResponseInterface'
import { ITableProperties, ITableSortChanged } from '@/interfaces/TableInterface'
import EmptyList from '@/components/EmptyList.vue'
import Pagination from '@/components/Pagination.vue'
import Search from '@/components/Search.vue'
import AdminPanelService from '@/services/AdminPanelService'
import { IAdminBoughtTrainings } from '@/interfaces/OrderItemInterface'
import scrollToRef from '@/helpers/scroll-to-ref-helper'

enum BoughtTrainingsFilter {
  search = 'q',
}

@Component({
  components: { Search, EmptyList, Pagination },
})
export default class AdminBoughtTrainingsList extends Vue {
  private loading: boolean = false
  private boughtTrainings: any = []
  private meta: IMeta | any = {}

  public tableProperties: ITableProperties = {
    currentPage: 1,
    perPage: 15,
    filters: {
      search: '',
    },
  }

  public created(): void {
    this.parseQueryParams()
    this.boughtTrainingsList()

    this.$watch(() => this.$route, this.boughtTrainingsList)
  }

  private boughtTrainingsList(): void {
    this.loading = true

    const { query } = this.$router.currentRoute

    AdminPanelService.getBoughtTrainings(query).then((response: IPaginatedResponse<IAdminBoughtTrainings[]>) => {
      this.boughtTrainings = response.data
      this.meta = response.meta
      this.loading = false
    })
  }

  private onTableSortChange(event: ITableSortChanged): void {
    this.tableProperties.sortBy = event.sortBy
    this.tableProperties.order = event.sortDesc ? 'desc' : 'asc'

    this.changeRouteByProperties()
  }

  private onPageChange(page: number): void {
    this.tableProperties.currentPage = page

    this.changeRouteByProperties()
  }

  private onSearch(search: string): void {
    this.tableProperties.filters.search = search

    this.changeRouteByProperties()
  }

  private changeRouteByProperties(): void {
    const { name, query, params }: any = this.$router.currentRoute

    const { currentPage, sortBy, order, filters } = this.tableProperties

    const routeQuery = {
      ...query,
      page: currentPage,
      sort_by: sortBy,
      order,
    }

    if (filters.search !== '') routeQuery[BoughtTrainingsFilter.search] = filters.search
    else delete routeQuery[BoughtTrainingsFilter.search]

    this.$router
      .replace({
        name,
        params,
        query: routeQuery,
      })
      .catch(() => {})
  }

  private parseQueryParams(): void {
    const { query } = this.$router.currentRoute

    this.tableProperties.currentPage = Number(_.get(query, 'page', 1))
    this.tableProperties.filters.search = _.get(query, BoughtTrainingsFilter.search, '')
    this.tableProperties.filters.sortBy = _.get(query, 'sort_by')
    this.tableProperties.filters.order = _.get(query, 'order')
  }

  private onPageInput(id: string): void {
    this.loading = true

    scrollToRef(id)
  }

  public getToggleOrderEvent(order: string): any {
    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'asc') {
      return {
        sortBy: '',
        sortDesc: false,
      }
    }

    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'desc') {
      return {
        sortBy: order,
        sortDesc: false,
      }
    }

    return {
      sortBy: order,
      sortDesc: true,
    }
  }

  private getFilterOrderClass(order: string): string {
    if (this.tableProperties.sortBy === order) {
      return this.tableProperties.order || ''
    }

    return ''
  }

  private getFields(): BvTableFieldArray {
    return [
      {
        key: 'trainings.title',
        label: 'Training name',
        sortable: true,
      },
      {
        key: 'companies.name',
        label: 'Company name',
        sortable: true,
      },
      {
        key: 'purchasedOrderUser.name',
        label: 'User',
        sortable: true,
      },
      {
        key: 'trainingLocationDates.from',
        label: 'Date',
        sortable: true,
      },
      {
        key: 'orderItems.total',
        label: 'Price',
        sortable: true,
      },
    ]
  }
}
