






























import { Component, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AbstractField from '@/components/form/fields/AbstractField.vue'
import Classifier from '@/classes/training/classifier'
import WysiwygField from '@/components/form/fields/WysiwygField.vue'
import { ISteps } from '@/store/modules/training.module'

const Training = namespace('Training')
@Component({
  components: { WysiwygField },
})
export default class RadioPicker extends AbstractField {
  @Prop({ default: [] }) private readonly options!: Classifier[]

  @Training.Action
  private appendToTraining!: (data: any) => void

  @Training.Action
  private setStates!: (states: any) => void

  @Training.Action
  private deleteTypeClassifierData!: () => void

  @Training.Mutation
  private setStepData!: (stepData: ISteps) => void

  created(): void {
    this.$root.$on('resetStepData', (step: number) => {
      if (step === 3) {
        this.clearData()
      }
    })
  }

  clearData(): void {
    this.deleteTypeClassifierData()
    this.setStepData({
      activeStep: 3,
      completedStep: 3,
    })
  }

  private setTraining(option: Classifier): void {
    this.appendToTraining({
      trainingTypeClassifierId: option.id,
      trainingTypeDescription: option.description,
      title: option.title,
    })
    this.setStates({ changed: true })
  }

  private getStripedHTMLContent(content: string, limit: number = 140): string {
    const text = content.replace(/(<([^>]+)>)/gi, ' ')

    if (text.length > limit) {
      return `${text.slice(0, limit)}...`
    }

    return text
  }
}
