



























































import { Component, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Pagination from '@/components/Pagination.vue'
import TrainingCard from '@/components/TrainingCard.vue'
import TrainingFilter from '@/components/form/trainings/TrainingFilter.vue'
import TrainingDescription from '@/components/TrainingDescription.vue'
import TrainingLocationDate from '@/classes/training/training-location-date'
import TrainingService from '@/services/TrainingService'
import Filter from '@/classes/filter'
import ITrainingLocationDate from '@/interfaces/training/TrainingLocationDateInterface'
import EmptyList from '@/components/EmptyList.vue'
import SelectOption from '@/interfaces/SelectOption'
import scrollToRef from '@/helpers/scroll-to-ref-helper'

@Component({
  components: { EmptyList, TrainingDescription, TrainingFilter, TrainingCard, Pagination, Breadcrumbs },
})
export default class TrainingInner extends Vue {
  private trainingDate: TrainingLocationDate | null = null
  private trainings: TrainingLocationDate[] = []
  private meta: any = null
  private page: number = 1
  private loading: Record<string, boolean> = {
    main: true,
    dates: true,
  }
  private debouncedSearch = _.debounce(this.getTrainingDates, 400)
  private countryOptions: string[] = []
  private filters: Record<string, Filter> = {
    trainingId: new Filter({ field: 'uuid', modifier: 'notIn' }),
    trainingType: new Filter({ field: 'training-training_type_classifier_id' }),
    from: new Filter({ field: 'from', modifier: 'between' }),
    location: new Filter({ field: 'trainingLocation-country', modifier: 'like' }),
  }

  @Watch('filters.trainingId.value', { deep: true }) private onFilterChange(): void {
    this.trainings = []
    this.getTrainingDates()
  }

  @Watch('$route.params.slug', { deep: true }) private onTrainingChange(newValue: string): void {
    if (this.trainingDate && newValue !== this.trainingDate.slug) this.getTraining(newValue)
  }

  private breadcrumbsOverrides: any = {}

  async created(): Promise<void> {
    await this.getTraining(this.$route.params.slug)
    this.countryOptions = await TrainingService.getCountryFilterList()
  }

  async getTraining(slug: string): Promise<void> {
    this.loading.main = true
    this.loading.dates = true

    this.trainingDate = await TrainingService.getTraining(slug).then((response: TrainingLocationDate) => {
      this.filters.trainingType.value = response.training?.trainingTypeClassifier?.id.toString()
      this.filters.trainingId.value = response.uuid.toString()

      window.scrollTo(0, 0)
      this.$root.$emit('top')
      this.loading.main = false

      return response
    })

    this.breadcrumbsOverrides.inner = this.trainingDate?.training.title
  }

  private onPageInput(id: string): void {
    this.loading.dates = true

    scrollToRef(id)
  }
  async getTrainingDates(): Promise<void> {
    this.loading.dates = true
    await TrainingService.getTrainingActiveList(this.page, '', this.filters).then((response: any) => {
      this.trainings = response.data.map((training: ITrainingLocationDate) => new TrainingLocationDate(training))
      this.meta = response.meta
      this.loading.dates = false
    })
  }

  get countrySelections(): SelectOption[] {
    return this.countryOptions.map((option: string) => ({ value: option, text: option }))
  }

  get toValue(): any {
    if (this.filters.to) return this.filters.to.value

    return null
  }
}
