import { IUserLocationResponse } from '@/interfaces/user/UserInterface'
import Model from '@/classes/model'
import { IUserLocationForm } from '@/interfaces/form-interfaces/UserFormInterface'

export default class UserLocation extends Model {
  country!: string
  city!: string
  address!: string
  addressComplement!: string
  post!: string

  constructor(data: IUserLocationResponse) {
    super(data)

    this.country = data.country
    this.city = data.city
    this.address = data.address
    this.addressComplement = data.address_complement
    this.post = data.post
  }

  toFormData(): IUserLocationForm {
    return {
      id: this.id,
      country: this.country,
      city: this.city,
      address: this.address,
      addressComplement: this.addressComplement,
      post: this.post,
    }
  }

  get formattedLocation(): string {
    const { address, city, country } = this

    if (!Object.values({ address, city, country }).filter(Boolean).length) return '-'

    return Object.values({ address, city, country }).filter(Boolean).join(', ')
  }
}
