





































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { TrainingDate } from '@/interfaces/ProfileTrainingDetails'
import TrainingLocationDate from '@/classes/training/training-location-date'
import TrainingLocationDateService from '@/services/TrainingLocationDateService'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'

const Global = namespace('Global')

@Component
export default class TrainingCalendar extends Vue {
  private loading: boolean = false
  private trainingLocationCalendarDates: TrainingDate[] = null!
  public calendarMask: any = {
    weekdays: 'WWW',
  }

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  public created(): void {
    this.loadCalendarTrainingLocationDates()
  }

  async loadCalendarTrainingLocationDates(): Promise<void> {
    this.loading = true
    try {
      const response = await TrainingLocationDateService.datesByAuth()
      this.trainingLocationCalendarDates = response.map((item: TrainingLocationDate) => ({
        start: new Date(item.from as string),
        end: new Date(item.from as string),
      }))
      this.loading = false
    } catch (error: any) {
      this.loading = false
      this.showSnackbar({
        type: SnackbarType.error,
        text: `Calendar data cannot be retrieved. ${error.message}`,
      })
    }
  }

  onDateClick(data: any): void {
    this.$router.replace({
      name: this.$route.name as string,
      params: {
        ...this.$route.params,
      },
      query: {
        ...this.$route.query,
        'filter-between-from': `${data.id},${data.id}`,
      },
    })
  }

  get calendarData(): any {
    return [
      {
        highlight: {
          start: { fillMode: 'solid' },
          base: { fillMode: 'solid' },
          end: { fillMode: 'solid' },
        },
        dates: this.trainingLocationCalendarDates,
      },
    ]
  }
}
