









import { Component, Vue } from 'vue-property-decorator'
import JobPositionsService from '@/services/JobPositionsService'
import JobApplicantsTable from '@/components/JobApplicantsTable.vue'
import { ITableProperties } from '@/interfaces/TableInterface'
import { IMeta } from '@/interfaces/ResponseInterface'

@Component({
  components: { JobApplicantsTable },
})
export default class ProfileJobPositions extends Vue {
  private jobPositions: any = null
  meta: IMeta | null = null

  tableProperties: ITableProperties = {
    currentPage: 1,
    perPage: 10,
    sortBy: null,
    order: null,
  }

  created(): void {
    this.getJobPositionData()
    this.$watch(() => this.tableProperties, this.getJobPositionData, { deep: true })
  }

  private getJobPositionData(): void {
    JobPositionsService.getMyJobPositions(this.tableProperties.currentPage, this.tableProperties.perPage).then(
      (response) => {
        this.jobPositions = response.data
        this.meta = response.meta
      }
    )
  }
}
