

















































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { AxiosError } from 'axios'
import _ from 'lodash'
import SelectOption from '@/interfaces/SelectOption'
import TrainingLocationDateService from '@/services/TrainingLocationDateService'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'
import TrainingLocationDate from '@/classes/training/training-location-date'
import http from '@/classes/http'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import User from '@/classes/user/user'

interface IStudentData {
  uniqueId: string
  user: {
    name: null | string
    email: null | string
    phone_number: null | string
  }
  training_location_dates: IStudentTrainingLocationDate[]
  saved: boolean
  errors: any
}

interface IStudentTrainingLocationDate {
  uuid: string
}

const Global = namespace('Global')

const Auth = namespace('Auth')

@Component
export default class AddStudentsModal extends Vue {
  students: IStudentData[] = []
  trainingOptions: SelectOption[] = []
  loading: boolean = false

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Auth.Getter
  private user!: User

  created(): void {
    this.addStudent()
    this.loadTrainingOptions()
  }

  private apply(): void {
    this.loading = true

    const call = this.students
      .filter((item: IStudentData) => !item.saved)
      .map((data: IStudentData) =>
        http
          .put('orders/create-order-with-new-user', this.formatData(data))
          .then(() => {
            data.saved = true

            return true
          })
          .catch((error: AxiosError) => {
            data.errors = error.response?.data.errors

            return false
          })
      )

    Promise.all(call)
      .then((response: boolean[]) => {
        if (response.every((item: boolean) => item)) {
          this.showSnackbar({
            type: SnackbarType.success,
            text: 'All students successfully added!',
          })

          this.students = []
          this.addStudent()

          this.$bvModal.hide('addStudents')
          this.$emit('studentsAdded')

          return
        }

        this.showSnackbar({
          type: SnackbarType.error,
          text: 'Some or all students not added, check information and try again!',
        })
      })
      .finally(() => (this.loading = false))
  }

  formatData(data: IStudentData): IStudentData {
    const filteredData: IStudentData = _.cloneDeep(data)

    let filteredDates = filteredData.training_location_dates.filter(
      (object: IStudentTrainingLocationDate) => !!object.uuid
    )
    filteredDates = filteredDates.length === 0 ? [{ uuid: '' }] : filteredDates
    data.training_location_dates = filteredDates
    filteredData.training_location_dates = filteredDates

    return filteredData
  }

  private addStudent(): void {
    this.students.push({
      uniqueId: Math.random().toString(),
      user: {
        name: null,
        email: null,
        phone_number: null,
      },
      training_location_dates: [{ uuid: '' }],
      saved: false,
      errors: null,
    })
  }

  private addStudentRow(index: number): void {
    this.students[index].training_location_dates.push({ uuid: '' })
  }

  private deleteStudent(index: number): void {
    this.students = this.students.filter((item: IStudentData, i: number) => i !== index)
  }

  private deleteStudentRow(studentIndex: number, index: number): void {
    this.students[studentIndex].training_location_dates = this.students[studentIndex].training_location_dates.filter(
      (item: IStudentTrainingLocationDate, i: number) => i !== index
    )
  }

  private loadTrainingOptions(): void {
    TrainingLocationDateService.activeTrainings(
      {
        'filter-training-user-training_center_id': this.user.trainingCenter.id,
      },
      9999
    ).then((response: IPaginatedResponse<TrainingLocationDate[]>) => {
      this.trainingOptions = response.data.map((item: TrainingLocationDate) => ({
        value: item.uuid,
        text: item.training.title,
        description: item.from,
      }))
    })
  }

  private getError(student: IStudentData, field: string): null | string {
    if (!student.errors) return null

    const error = student.errors[field]

    return error ? error[0] : null
  }

  private removeError(student: IStudentData, field: string): void {
    if (!student.errors) return

    delete student.errors[field]
  }

  private getFilteredTrainingOptions(uuidList: IStudentTrainingLocationDate[]): SelectOption[] {
    const simpleList: string[] = uuidList.map((object: IStudentTrainingLocationDate) => object.uuid)

    return this.trainingOptions.filter(
      (trainingOption: SelectOption) => trainingOption.value && !simpleList.includes(trainingOption.value.toString())
    )
  }

  private handleStudentDeleteButtonClick(student: IStudentData, index: number, rowIndex: number): void {
    if (!student.saved && student.training_location_dates.length > 1) {
      this.deleteStudentRow(index, rowIndex)

      return
    }

    this.deleteStudent(index)
  }
}
