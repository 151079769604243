






























































































































import { Component, Vue } from 'vue-property-decorator'
import { headroom } from 'vue-headroom'
import { namespace } from 'vuex-class'
import CartDropdown from '@/components/form/cart/CartDropdown.vue'
import Routes from '@/router/routes'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import User from '@/classes/user/user'

const Cart = namespace('Cart')

const Auth = namespace('Auth')

const Global = namespace('Global')

headroom.computed.style = () => ''

@Component({
  components: { CartDropdown, headroom },
})
export default class Header extends Vue {
  Routes = Routes

  @Cart.Getter
  private cartItemCount!: number

  @Auth.Getter
  private isLoggedIn!: boolean

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Auth.Getter
  private userIsTrainingCenter!: boolean

  @Auth.Getter
  private userIsAdmin!: boolean

  @Auth.Getter
  private user!: User

  @Cart.Action
  private clearCart!: () => Promise<void>

  @Auth.Action
  private logOut!: () => Promise<void>

  navigationOpen: boolean = false
  active: boolean = true
  lastScrollPosition: number = 0

  created(): void {
    this.$root.$on('cartDropdownHide', this.hideCartDropdown)
    window.addEventListener('scroll', this.onScroll)
  }

  toggleNavigation(): void {
    this.navigationOpen = !this.navigationOpen
  }

  goTo(routeName: string): void {
    this.$router.push({ name: routeName }).catch(() => {})
    this.close()
  }

  async logout(): Promise<void> {
    this.close()
    await this.logOut()
    await this.clearCart()
    this.showSnackbar({
      type: SnackbarType.success,
      text: 'Successfully logged out',
    })
  }

  close(): void {
    this.navigationOpen = false
  }

  private onScroll(): void {
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop

    if (currentScrollPosition < 0) return

    this.active = currentScrollPosition < this.lastScrollPosition

    if (!this.active) this.navigationOpen = false

    this.lastScrollPosition = currentScrollPosition
  }

  private hideDropdown() {
    this.hideCartDropdown()
  }

  private hideCartDropdown(): void {
    const dropdownRef: any = this.$refs.cartDropdown

    if (dropdownRef) dropdownRef.hide()
  }

  private openCart(): void {
    this.$router.push({ name: Routes.CART })
  }

  beforeDestroy() {
    this.$root.$off('cartDropdownHide')
    window.removeEventListener('scroll', this.onScroll)
  }
}
