



































































































































import { Component } from 'vue-property-decorator'
import _ from 'lodash'
import ProfileEditAbstract from '@/components/profile/edit/ProfileEditAbstract.vue'
import ProfileHeader from '@/components/profile/edit/ProfileHeader.vue'
import Unsubscribe from '@/components/profile/edit/Unsubscribe.vue'
import Routes from '@/router/routes'
import IUserForm from '@/interfaces/form-interfaces/UserFormInterface'
import { IClassifiersById } from '@/interfaces/training/ClassifierInterface'
import { ClassifiersCategory } from '@/components/AddEmployeesModal.vue'
import SelectOption from '@/interfaces/SelectOption'
import ClassifierService from '@/services/ClassifierService'
import DocumentClass from '@/classes/user/document'
import { DocumentTypes } from '@/configs/document-type'
import ProfileService from '@/services/ProfileService'
import UserCvField from '@/components/form/fields/UserCvField.vue'

@Component({
  components: {
    ProfileHeader,
    Unsubscribe,
    UserCvField,
  },
})
export default class UserProfileEdit extends ProfileEditAbstract {
  user: IUserForm = this.data.toFormData()
  userCV: DocumentClass | null = null
  Routes = Routes

  private positionTypes: IClassifiersById[] = []
  private nationalityTypes: IClassifiersById[] = []

  async created(): Promise<void> {
    this.positionTypes = await ClassifierService.getClassifiersByCategoryOptions(ClassifiersCategory.employeePosition)
    this.nationalityTypes = await ClassifierService.getClassifiersByCategoryOptions(ClassifiersCategory.nationality)
  }

  async submitUser(): Promise<void> {
    const formData: FormData = new FormData()

    await this.submit(this.user, this.appendDocumentToFormData(formData, [this.userCV]))
  }

  appendDocumentToFormData(formData: FormData, data: any): FormData {
    data.forEach((document: DocumentClass, index: number) => {
      if (!document) return
      Object.keys(document).forEach((key: string) => {
        if (data[index][key]) formData.append(`documents[${index}][${key}]`, data[index][key])
      })
    })

    return formData
  }

  get positionOptions(): SelectOption[] {
    return this.positionTypes.map((position: IClassifiersById) => ({
      value: position.value,
      text: position.label,
    }))
  }

  get nationalityOptions(): SelectOption[] {
    return this.nationalityTypes.map((nationality: IClassifiersById) => ({
      value: nationality.value,
      text: nationality.label,
    }))
  }

  get documentCVFromData(): DocumentClass | undefined {
    return _.find(this.user.documents, (document: DocumentClass) => document.type === DocumentTypes.cv)
  }

  removeDBDocument(image: DocumentClass, fieldId: string): void {
    ProfileService.deleteDBImage(image)
      .then(() => {
        if (!this.user.documents) this.user.documents = []
        this.user.documents = this.user.documents.filter((document: DocumentClass) => document.id !== image.id)
      })
      .catch(() => this.addError(fieldId, `Could not remove document ${image.fileName}`))
  }

  get documentArray(): DocumentClass[] {
    const documents: DocumentClass[] = []

    if (this.userCV) {
      documents.push(this.userCV)
    }

    return documents
  }
}
