import Classifier from '@/classes/training/classifier'
import TrainingLocation from '@/classes/training/training-location'
import User from '@/classes/user/user'
import IStudentResponse from '@/interfaces/StudentInterface'
import TrainingData from '@/classes/training/training'
import Model from '@/classes/model'
import OrderItem from '@/classes/order-item'
import StudentUserStatus, { StudentUserStatusTitle } from '@/configs/student-user-status'

export default class Student extends Model {
  expireDate!: string
  userId!: number
  from!: string
  userStatus!: string
  daysToExpire!: number | any
  companyEmployeeId!: number
  isActivated!: boolean
  canRefresh!: boolean
  attendedStatus!: string
  orderItemUsersUuid!: string
  companyEmployeeStatus!: string
  training!: TrainingData
  industryClassifier!: Classifier
  standardClassifier!: Classifier
  trainingLocation!: TrainingLocation
  orderItem?: OrderItem
  user!: User
  totalPerUnit!: number

  constructor(data: IStudentResponse) {
    super(data)

    this.userId = data.id
    this.expireDate = data.expire_date
    this.from = data.from
    this.userStatus = data.user_status || data.status
    this.attendedStatus = data.attended_status
    this.daysToExpire = data.days_to_expire
    this.isActivated = data.is_activated
    this.canRefresh = data.can_refresh
    this.orderItemUsersUuid = data.order_item_users_uuid
    this.companyEmployeeStatus = data.company_employee_status
    this.companyEmployeeId = data.company_employee_id
    this.totalPerUnit = Number(data.total_per_unit)

    this.training = this.setRelation(data.training, TrainingData)
    this.industryClassifier = this.setRelation(data.industry_classifier, Classifier)
    this.standardClassifier = this.setRelation(data.standard_classifier, Classifier)
    this.trainingLocation = this.setRelation(data.training_location, TrainingLocation)
    this.orderItem = this.setRelation(data.order_item, OrderItem)
    this.user = this.setRelation(data.user, User)
  }

  get readableUserStatus(): string {
    if (this.attendedStatus === StudentUserStatus.attended) return StudentUserStatusTitle[StudentUserStatus.attended]

    if (this.attendedStatus === StudentUserStatus.notAttended)
      return StudentUserStatusTitle[StudentUserStatus.notAttended]

    if (this.attendedStatus === StudentUserStatus.refunded) return StudentUserStatusTitle[StudentUserStatus.refunded]

    return ''
  }
}
