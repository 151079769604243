import { IAdminListUserResponse } from '@/interfaces/user/UserInterface'
import Model from '@/classes/model'
import TrainingCenter from '@/classes/user/training-center'
import Company from '@/classes/user/company'

export enum UserType {
  admin = 'admin',
  user = 'cadet',
  company = 'company',
  trainingCenter = 'training_center',
}

export default class ListUser extends Model {
  email!: string
  name!: string
  phoneNumber!: string
  approvedAt!: string
  isActive!: boolean
  trainingCenter!: TrainingCenter
  company!: Company
  emailVerifiedAt!: string | null

  constructor(data: IAdminListUserResponse) {
    super(data)

    this.email = data.email
    this.approvedAt = data.approved_at
    this.name = data.name
    this.phoneNumber = data.phone_number
    this.isActive = data.is_active
    this.emailVerifiedAt = data.email_verified_at

    this.trainingCenter = this.setRelation(data.training_center, TrainingCenter, null)
    this.company = this.setRelation(data.company, Company, {})
  }
}
