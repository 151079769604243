




















import { Component, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import TrainingCard from '@/components/TrainingCard.vue'
import { IMeta, IPaginatedResponse } from '@/interfaces/ResponseInterface'
import WishlistModel from '@/classes/wishlist'
import WishlistService from '@/services/WishlistService'
import { ITableProperties } from '@/interfaces/TableInterface'
import Pagination from '@/components/Pagination.vue'
import scrollToRef from '@/helpers/scroll-to-ref-helper'

@Component({
  components: { TrainingCard, Pagination },
})
export default class Wishlist extends Vue {
  private loading: boolean = false
  wishlist: WishlistModel[] = null!
  meta: IMeta = null!

  public tableProperties: ITableProperties = {
    currentPage: 1,
    perPage: 15,
    filters: {},
  }

  created(): void {
    this.parseQueryParams()
    this.loadWishlist()

    this.$watch(() => this.$route, this.loadWishlist)
  }

  private loadWishlist(): void {
    this.loading = true

    const { query } = this.$router.currentRoute

    WishlistService.list(query, this.tableProperties.perPage).then((response: IPaginatedResponse<WishlistModel[]>) => {
      this.wishlist = response.data
      this.meta = response.meta
    })
    this.loading = false
  }

  private onPageChange(page: number): void {
    this.tableProperties.currentPage = page
    this.changeRouteByProperties()
  }

  private changeRouteByProperties(): void {
    const { name, query, params }: any = this.$router.currentRoute

    const { currentPage } = this.tableProperties

    const routeQuery = {
      ...query,
      page: currentPage,
    }

    this.$router
      .replace({
        name,
        params,
        query: routeQuery,
      })
      .catch(() => {})
  }

  private onPageInput(id: string): void {
    this.loading = true

    scrollToRef(id)
  }

  private parseQueryParams(): void {
    const { query } = this.$router.currentRoute

    this.tableProperties.currentPage = Number(_.get(query, 'page', 1))
  }
}
