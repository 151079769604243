











import { Component, Prop } from 'vue-property-decorator'
import AbstractField from '@/components/form/fields/AbstractField.vue'
import { LocationTypesData } from '@/configs/training-steps'
import TrainingLocation from '@/classes/training/training-location'

@Component
export default class TrainingLocationInfo extends AbstractField {
  @Prop() private readonly location!: TrainingLocation
  @Prop({ default: () => ['location', 'country', 'city', 'address', 'post'] }) private readonly allowedFields!: string[]
  LocationTypesData = LocationTypesData
}
