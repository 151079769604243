






import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import TrainingCenterTrainings from '@/views/Profile/TrainingCenterTrainings.vue'
import CompanyTrainings from '@/views/Profile/CompanyTrainings.vue'
import User, { UserType } from '@/classes/user/user'
import UserTrainings from '@/views/Profile/UserTrainings.vue'
import MissingInfoModal from '@/components/MissingInfoModal.vue'

const Auth = namespace('Auth')

@Component({
  components: {
    CompanyTrainings,
    TrainingCenterTrainings,
    MissingInfoModal,
  },
})
export default class Trainings extends Vue {
  @Auth.Getter
  private user!: User

  get components(): any {
    return {
      [UserType.company]: CompanyTrainings,
      [UserType.trainingCenter]: TrainingCenterTrainings,
      [UserType.user]: UserTrainings,
    }
  }
}
