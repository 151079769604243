











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Button extends Vue {
  @Prop() readonly text!: string
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: null }) readonly additionalClass!: string
  @Prop({ default: false }) readonly loading!: boolean

  emitClick(event: InputEvent): void {
    this.$emit('click', event)
  }
}
