




import { Component, Prop, Vue } from 'vue-property-decorator'
import DateField from '@/components/form/fields/training/DateField.vue'
import TrainingData from '@/classes/training/training'

@Component({
  components: {
    DateField,
  },
})
export default class TrainingStepDate extends Vue {
  @Prop() private readonly data!: TrainingData
}
