







import { Component, Vue } from 'vue-property-decorator'
import { IJobData } from '@/interfaces/JobPositionsInterface'
import JobPositionsService from '@/services/JobPositionsService'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import JobDetails from '@/components/JobDetails.vue'
import JobPositionsRelated from '@/views/JobPositionsRelated.vue'

@Component({
  components: { JobPositionsRelated, JobDetails, Breadcrumbs },
})
export default class JobPositionInner extends Vue {
  private jobData: IJobData | null = null
  private breadcrumbsOverrides: any = {}
  private relatedJobs: IJobData[] = []

  created(): void {
    this.getJobData()
  }

  getJobData(): void {
    JobPositionsService.getJobPosition(this.$route.params.slug).then((response) => {
      this.jobData = response.data.data
      this.breadcrumbsOverrides.inner = this.jobData?.title

      if (typeof this.jobData?.company !== 'undefined') {
        this.getRelatedJobs(this.jobData.id, this.jobData?.company.id)
      }
    })
  }

  private getRelatedJobs(currentJobId: number, companyId: number): void {
    JobPositionsService.getJobPositions(1, 10).then((response) => {
      const jobs = response.data

      const filteredJobs = jobs.filter((job: IJobData) => {
        return job.company.id === companyId && job.id !== currentJobId
      })
      this.relatedJobs = filteredJobs
    })
  }
}
