import Model from '@/classes/model'
import TrainingLocationDateServiceClassifier from '@/classes/training/training-location-date-service-classifier'
import ITrainingLocationDateServiceInterface from '@/interfaces/training/TrainingLocationDateServiceInterface'

export default class TrainingLocationDateService extends Model {
  isActive!: boolean
  serviceClassifierId!: number
  trainingUuid!: string
  trainingLocationUuid!: string
  trainingLocationDateUuid!: string
  serviceClassifier!: TrainingLocationDateServiceClassifier

  constructor(data: ITrainingLocationDateServiceInterface) {
    super(data)

    this.isActive = data.is_active
    this.serviceClassifierId = data.service_classifier_id
    this.trainingUuid = data.training_uuid
    this.trainingLocationUuid = data.training_location_uuid
    this.trainingLocationDateUuid = data.training_location_date_uuid
    this.serviceClassifier = data.service_classifier
  }
}
