import ITrainingDataResponse from '@/interfaces/training/TrainingDataInterface'
import TrainingLocation from '@/classes/training/training-location'
import TrainingLocationDate from '@/classes/training/training-location-date'
import TrainingLocationDateService from '@/classes/training/training-location-service'
import Classifier from '@/classes/training/classifier'
import Model from '@/classes/model'
import ITrainingDataForm from '@/interfaces/form-interfaces/TrainingDataFormInterface'
import User from '../user/user'

export default class TrainingData extends Model {
  activeStep!: number
  completedStep!: number
  title!: string
  isFinished!: boolean
  industryClassifierId!: number
  standardClassifierId!: number
  trainingTypeClassifierId!: number
  trainingLocations!: TrainingLocation[]
  trainingLocationDates!: TrainingLocationDate[]
  trainingLocationDateServices!: TrainingLocationDateService[]
  industryClassifier!: Classifier
  standardClassifier!: Classifier
  trainingTypeClassifier!: Classifier
  user!: User
  trainingTypeDescription!: string | undefined

  constructor(data: ITrainingDataResponse) {
    super(data)

    this.activeStep = data.active_step
    this.completedStep = data.completed_step
    this.title = data.title
    this.isFinished = data.is_finished
    this.industryClassifierId = data.industry_classifier_id
    this.standardClassifierId = data.standard_classifier_id
    this.trainingTypeClassifierId = data.training_type_classifier_id
    this.trainingTypeDescription = data.training_type_description

    this.trainingLocations = this.setRelation(data.training_locations, TrainingLocation, [])
    this.trainingLocationDates = this.setRelation(data.training_location_dates, TrainingLocationDate, [])
    this.trainingLocationDateServices = this.setRelation(
      data.training_location_date_services,
      TrainingLocationDateService,
      []
    )
    this.industryClassifier = this.setRelation(data.industry_classifier, Classifier)
    this.standardClassifier = this.setRelation(data.standard_classifier, Classifier)
    this.trainingTypeClassifier = this.setRelation(data.training_type_classifier, Classifier)
    this.user = this.setRelation(data.user, User)
  }

  toFormData(): ITrainingDataForm {
    return {
      uuid: this.uuid,
      activeStep: this.activeStep,
      completedStep: this.completedStep,
      title: this.title,
      isFinished: this.isFinished,
      industryClassifierId: this.industryClassifierId,
      standardClassifierId: this.standardClassifierId,
      trainingTypeClassifierId: this.trainingTypeClassifierId,
      trainingLocations: this.toFormDataGeneric(this.trainingLocations),
      trainingLocationDates: this.toFormDataGeneric(this.trainingLocationDates),
      trainingLocationDateServices: this.toFormDataGeneric(this.trainingLocationDateServices),
      trainingTypeDescription: this.trainingTypeDescription,
    }
  }
}
