

















































































































































































































import { Component } from 'vue-property-decorator'
import _ from 'lodash'
import PhotoGalleryField from '@/components/form/fields/PhotoGalleryField.vue'
import CompanyLogoField from '@/components/form/fields/CompanyLogoField.vue'
import ProfileEditAbstract from '@/components/profile/edit/ProfileEditAbstract.vue'
import DocumentClass from '@/classes/user/document'
import ProfileHeader from '@/components/profile/edit/ProfileHeader.vue'
import Unsubscribe from '@/components/profile/edit/Unsubscribe.vue'
import { DocumentTypes } from '@/configs/document-type'
import ProfileService from '@/services/ProfileService'
import Routes from '@/router/routes'
import TrainingService from '@/services/TrainingService'
import IUserForm from '@/interfaces/form-interfaces/UserFormInterface'
import { Industry } from '@/interfaces/training/ClassifierInterface'
import TextArea from '@/components/form/fields/TextArea.vue'

@Component({
  components: {
    TextArea,
    CompanyLogoField,
    PhotoGalleryField,
    ProfileHeader,
    Unsubscribe,
  },
})
export default class TrainingCenterProfileEdit extends ProfileEditAbstract {
  user: IUserForm = this.data.toFormData()
  companyLogo: DocumentClass | null = null
  photoGallery: DocumentClass[] = []
  Routes = Routes
  industryOptions: Record<string, any>[] = []

  async created(): Promise<void> {
    const industries = await TrainingService.getClassifiers('get-industry-list')

    this.industryOptions = industries.map((classifier) => ({
      ...classifier,
      idKey: Industry[classifier.code as keyof typeof Industry],
      dataKey: _.camelCase(Industry[classifier.code as keyof typeof Industry]),
    }))
  }

  async submitTrainingCenter(): Promise<void> {
    const formData: FormData = new FormData()
    await this.submit(this.user, this.appendImagesToFormData(formData, [...this.photoGallery, this.companyLogo]))
  }

  appendImagesToFormData(formData: FormData, data: any): FormData {
    data.forEach((document: DocumentClass, index: number) => {
      if (!document) return
      Object.keys(document).forEach((key: string) => {
        if (data[index][key] && key !== 'id') formData.append(`documents[${index}][${key}]`, data[index][key])
      })
    })

    return formData
  }

  appendError(data: any): void {
    this.addError(data.field, data.msg)
  }

  removeDBImage(image: DocumentClass, fieldId: string): void {
    ProfileService.deleteDBImage(image)
      .then(() => {
        if (!this.user.documents) this.user.documents = []
        this.user.documents = this.user.documents.filter((document: DocumentClass) => document.id !== image.id)
      })
      .catch(() => this.addError(fieldId, `Could not remove image ${image.fileName}`))
  }

  get documentPhotosFromData(): DocumentClass[] {
    return _.isArray(this.user?.documents)
      ? this.user.documents.filter((document: DocumentClass) => document.type === DocumentTypes.photo)
      : []
  }

  get documentLogosFromData(): DocumentClass | undefined {
    return _.find(this.user.documents, (document: DocumentClass) => document.type === DocumentTypes.logo)
  }

  get documentArray(): DocumentClass[] {
    const documents: DocumentClass[] = []

    if (this.companyLogo) {
      documents.push(this.companyLogo)
    }

    if (this.photoGallery) {
      this.photoGallery.forEach((image: DocumentClass) => {
        documents.push(image)
      })
    }

    return documents
  }
}
