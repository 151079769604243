/* eslint-disable func-names */
import BootstrapVue, { BootstrapVueIcons } from 'bootstrap-vue'
import vueHeadroom from 'vue-headroom'
import VueStickyKit from 'vue-stickykit'
import VCalendar from 'v-calendar'
import VeeValidate from 'vee-validate'
import _ from 'lodash'
import { AxiosError } from 'axios'
import Vue from 'vue'
import Component from 'vue-class-component'
import VuePictureSwipe from 'vue-picture-swipe'
import VueMask from 'v-mask'
import TextField from '@/components/form/fields/TextField.vue'
import CheckboxField from '@/components/form/fields/CheckboxField.vue'
import Button from '@/components/Button.vue'
import FilterDateField from '@/components/form/fields/filter/FilterDateField.vue'
import FilterTextField from '@/components/form/fields/filter/FilterTextField.vue'
import SelectField from '@/components/form/fields/SelectField.vue'
import TermsCheckBox from '@/components/form/fields/TermsCheckBox.vue'

export default {
  install(): void {
    Vue.use(VeeValidate, { fieldsBagName: 'veeFields' })
    Vue.use(BootstrapVue)
    Vue.use(BootstrapVueIcons)
    Vue.use(vueHeadroom)
    Vue.use(VueStickyKit)
    Vue.use(VCalendar, {})
    Vue.use(VueMask)
    Vue.component('vue-picture-swipe', VuePictureSwipe)
    Vue.component('TextField', TextField)
    Vue.component('CheckboxField', CheckboxField)
    Vue.component('SelectField', SelectField)
    Vue.component('Button', Button)
    Vue.component('FilterSelectField', SelectField)
    Vue.component('FilterDateField', FilterDateField)
    Vue.component('FilterTextField', FilterTextField)
    Vue.component('TermsCheckBox', TermsCheckBox)

    registerClickOutsideDirective()
    registerMarkBoldMatchDirective()

    Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate'])

    Vue.prototype.setErrorsFromResponse = function (response: AxiosError) {
      if (response.response?.status === 422) {
        const { errors } = response.response.data
        _.each(errors, (error, field) => {
          this.errors.add({ field, msg: error[0] })
        })
      }
    }

    Vue.prototype.addError = function (field: string, msg: string) {
      this.errors.add({ field, msg })
    }

    Vue.prototype.resetErrors = function () {
      this.errors.clear()
    }

    Vue.prototype.removeError = function (id: string) {
      this.errors.remove(id)
    }
  },
}

const registerClickOutsideDirective = () => {
  Vue.directive('click-outside', {
    bind(el: any, binding: any, vnode: any) {
      el.clickOutsideEvent = function (event: any) {
        // here I check that click was outside the el and his children
        if (!(el === event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          vnode.context[binding.expression](event)
        }
      }
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind(el: any) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    },
  })
}

const registerMarkBoldMatchDirective = () => {
  Vue.directive('mark-bold-match', {
    bind(el: HTMLElement, binding: any) {
      el.innerHTML = el.innerHTML.replace(new RegExp(`(${binding.value})`, 'gi'), '<b>$1</b>')
    },
  })
}
