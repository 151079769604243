




import { Component, Prop, Vue } from 'vue-property-decorator'
import StandardPicker from '@/components/form/fields/training/StandardPicker.vue'
import TrainingData from '@/classes/training/training'
import Classifier from '@/classes/training/classifier'
import TrainingService from '@/services/TrainingService'

@Component({
  components: {
    StandardPicker,
  },
})
export default class TrainingStepStandard extends Vue {
  @Prop() private readonly data!: TrainingData
  standardOptions: Classifier[] = []

  async created(): Promise<void> {
    this.standardOptions = await TrainingService.getClassifiersByParent(this.data.industryClassifierId)
  }
}
