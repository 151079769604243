import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'
import Wishlist from '@/classes/wishlist'
import http from '@/classes/http'
import IWishlistResponse from '@/interfaces/WishlistInterface'

export default class WishlistService {
  static list(query: any = {}, perPage: number = 15): Promise<IPaginatedResponse<Wishlist[]>> {
    return http
      .get('wishlists', {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: IWishlistResponse) => new Wishlist(item)),
        meta: response.data.meta,
      }))
  }
}
