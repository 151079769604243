































import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import PageHeader from '@/components/PageHeader.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Routes from '@/router/routes'

@Component({
  components: {
    Breadcrumbs,
    PageHeader,
    Header,
  },
})
export default class AdminPanel extends Vue {
  Routes = Routes
}
