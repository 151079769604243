export default interface ISnackbar {
  type: SnackbarType
  title?: string
  text: string
  timeout?: number
}

export enum SnackbarType {
  success = 'success',
  info = 'info',
  error = 'error',
  warning = 'warning',
}
