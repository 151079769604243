
























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TrainingFilter extends Vue {
  @Prop({ default: 'Active trainings' }) private readonly title!: string
  opened: boolean = false

  toggle(): void {
    this.opened = !this.opened
  }
}
