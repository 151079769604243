


























































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Routes from '@/router/routes'
import User, { UserType } from '@/classes/user/user'
import { ICountries } from '@/interfaces/AuthInterface'
import {
  ICartCheckoutResponseData,
  ICartResponseData,
  ICartsWithEmployees,
  ICheckoutUser,
  IOrderPayResponseData,
  IPaymentPayloadData,
  OrderStatusType,
} from '@/interfaces/CartInterface'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'

const Global = namespace('Global')

const Auth = namespace('Auth')

const Cart = namespace('Cart')

interface IOption {
  value: string
  text: string
}

@Component({
  components: { Breadcrumbs },
})
export default class Checkout extends Vue {
  private submitted: boolean = false
  private message: string = ''
  private countries: ICountries[] = require('@/storage/countries.json')
  private loading: boolean = false
  private loadingButton: boolean = false
  Routes = Routes
  private agree: boolean = false
  private checkoutDetails: ICartCheckoutResponseData | null = null
  private checkoutUser: ICheckoutUser = {
    name: '',
    email: '',
    company: '',
    address: '',
    city: '',
    country: '',
    companyCode: '',
    phoneNumber: '',
    companyVat: '',
  }

  private card = {
    number: '',
    expiry: '',
    cvc: '',
  }

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Auth.Getter
  private user!: User | null

  @Cart.Getter
  private cartTotalDiscount!: string

  @Cart.Getter
  private cartItems!: ICartResponseData[]

  @Cart.Action
  private checkout!: () => Promise<ICartCheckoutResponseData>

  @Cart.Action
  private orderPay!: (data: IPaymentPayloadData) => Promise<IOrderPayResponseData>

  @Cart.Getter
  private cartEmployees!: ICartsWithEmployees

  @Cart.Getter
  private cartTotalUsersCount!: number

  async mounted(): Promise<void> {
    this.$validator.pause()
    try {
      this.loading = true

      this.checkoutDetails = await this.checkout()

      this.checkoutUser.name = this.user?.name || ''
      this.checkoutUser.email = this.user?.email || ''
      this.checkoutUser.phoneNumber = this.user?.phoneNumber || ''
      this.checkoutUser.address = this.user?.userLocation.address || ''
      this.checkoutUser.city = this.user?.userLocation.city || ''
      this.checkoutUser.country = this.user?.userLocation.country || ''

      if (this.userIsCompany()) {
        this.checkoutUser.company = (this.user as User).company.name || ''
        this.checkoutUser.companyCode = (this.user as User).company.code || ''
        this.checkoutUser.companyVat = (this.user as User).company.vat || ''
      }

      this.loading = false
    } catch (error) {
      this.loading = false
    }
  }

  private clearErrorOnChange(id: string): void {
    this.errors.remove(id)
  }

  private userIsCompany() {
    return this.user?.type === UserType.company
  }

  private trainingCount(count: number): string {
    return this.cartItems.length === 1 ? '1 training' : `${count} trainings`
  }

  private async proceedPayment(): Promise<void> {
    this.$validator.errors.clear()
    this.submitted = true
    this.message = ''
    this.loadingButton = true
    this.$validator.resume()
    this.$validator.validate().then(async (isValid) => {
      if (!isValid) {
        this.$validator.pause()
        this.loadingButton = false

        return
      }
      try {
        const paymentData: IPaymentPayloadData = {
          ...this.checkoutUser,
          orderUuid: this.checkoutDetails?.uuid,
          card: {
            number: Number(this.card.number.replace(/ /g, '')),
            exp_month: Number(this.card.expiry.split('/')[0]),
            exp_year: Number(this.card.expiry.split('/')[1]),
            cvc: Number(this.card.cvc),
          },
        }

        const response = await this.orderPay(paymentData)

        if (response.status && response.status === OrderStatusType.completed) {
          await this.processSuccessPayment(response)
        }

        if (response.url) {
          window.location.href = response.url
        }
      } catch (error: any) {
        this.showSnackbar({
          type: SnackbarType.error,
          text: 'Payment process failed',
        })
        this.loadingButton = false

        this.$emit('register:failed')
        this.message = error.message
        this.scrollToErrorMessage()
        this.$validator.errors.clear()

        if (error.status !== 422) return

        const errorFields = Object.keys(error.errors)
        errorFields.forEach((field: any) => {
          const errorString = error.errors[field].join(', ')
          this.$validator.errors.add({
            field,
            msg: errorString,
          })
        })
      }
    })
  }

  private async processSuccessPayment(orderData: IOrderPayResponseData): Promise<void> {
    const routeParams = {
      totalPrice: orderData.total,
      email: orderData.order_address?.email,
      orderId: orderData.code,
      discount: orderData.discount,
      vat: orderData.vat,
    }

    this.showSnackbar({
      type: SnackbarType.success,
      text: 'Payment accepted successfully',
    })

    this.loadingButton = false
    await this.$router.push({ name: Routes.SUCCESS, params: { data: JSON.stringify(routeParams) } })
  }

  get countryOptions(): IOption[] {
    return this.countries.map((country: ICountries) => ({
      value: country.name,
      text: country.name,
    }))
  }

  get totalSeatsCount(): string {
    return this.cartTotalUsersCount === 1 ? '1 seat' : `${this.cartTotalUsersCount} seats`
  }

  private scrollToErrorMessage(): void {
    const element = document.getElementById('message')

    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}
