




























































































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import TrainingCard from '@/components/TrainingCard.vue'
import CartSelectModal from '@/components/form/cart/CartSelectModal.vue'
import Routes from '@/router/routes'
import {
  ICartEmployee,
  ICartEmployeesModalProp,
  ICartItemUser,
  ICartResponseData,
  ICartsWithEmployees,
  IUpdateCartEmployeesData,
} from '@/interfaces/CartInterface'
import TrainingLocationDate from '@/classes/training/training-location-date'
import User, { UserType } from '@/classes/user/user'
import cartHelper from '@/helpers/cart-helper'
import IConfirmDialog from '@/interfaces/ConfirmDialogInterface'

const Basket = namespace('Cart')

const Auth = namespace('Auth')

const Global = namespace('Global')

@Component({
  components: {
    CartSelectModal,
    Breadcrumbs,
    TrainingCard,
  },
})
export default class Cart extends Vue {
  loading: boolean = false
  Routes = Routes
  private cartTrainings: TrainingLocationDate[] = []
  private employeeCartList: ICartsWithEmployees = []
  private allEmployees: ICartEmployee[] = []
  private selectedCardData: ICartEmployeesModalProp | null = null

  @Basket.Getter
  private cartTotalPrice!: string

  @Global.Action
  private showConfirmDialog!: (confirmDialog: IConfirmDialog) => void

  @Basket.Getter
  private cartItems!: ICartResponseData[]

  @Auth.Getter
  private user!: User | null

  @Basket.Action
  private fetchCart!: () => Promise<ICartResponseData[]>

  @Basket.Getter
  private cartTotalUsersCount!: number

  @Basket.Action
  private getEmployeesByCartId!: (uuid: string) => Promise<ICartEmployee[]>

  @Basket.Action
  private setEmployeesList!: (data: ICartsWithEmployees) => Promise<void>

  @Basket.Action
  private updateCartEmployeesList!: (data: IUpdateCartEmployeesData) => Promise<void>

  async mounted(): Promise<void> {
    if (this.userIsCompany()) {
      this.$root.$on('bv::employeesUpdated', this.updateCart)
      this.$root.$on('newEmployeesAdded:success', this.refreshCartAfterEmployeeAdd)
    }
    await this.updateCart()
  }

  private async refreshCartAfterEmployeeAdd() {
    await this.fetchCart()
    await this.updateCart()
  }

  private async updateCart() {
    try {
      this.loading = true
      this.cartTrainings = this.cartItems.map((cartItem) => new TrainingLocationDate(cartItem.training_location_date))

      if (this.userIsCompany()) {
        // eslint-disable-next-line no-restricted-syntax
        for (const cartItem of this.cartItems) {
          // eslint-disable-next-line no-await-in-loop
          this.allEmployees = await this.getEmployeesByCartId(cartItem.uuid)
          this.$set(this.employeeCartList, cartItem.training_location_date.uuid, this.getCartEmployees())
        }
        await this.setEmployeesList(this.employeeCartList)
      }
      this.loading = false
    } catch (error) {
      this.loading = false
    }
  }

  private getCartEmployees() {
    return { ...this.allEmployees.filter((item: ICartEmployee) => item.cart_item_user_uuid) }
  }

  private checkoutDisabled() {
    return (
      (!!this.cartItems.find((item: ICartResponseData) => item.count === 0) && this.userIsCompany()) ||
      !this.cartItems.length
    )
  }

  private getCartItem(trainingUuid: string): ICartResponseData | undefined {
    return this.cartItems.find((item) => item.training_location_date.uuid === trainingUuid)
  }

  private getDiscount(trainingId: string): string {
    const cartItem = this.getCartItem(trainingId)

    const discount = cartHelper.getItemDiscount(cartItem as ICartResponseData)

    return `${discount} ${this.getPercentageDiscount(this.getCartItem(trainingId) as ICartResponseData)}`
  }

  private getVat(trainingId: string): string {
    const cartItem = this.getCartItem(trainingId)

    return cartHelper.getItemVat(cartItem as ICartResponseData)
  }

  private getPercentageDiscount(training: ICartResponseData): string {
    return `(${Math.round((Number(training.discount) * 100) / Number(training.price))} %)`
  }

  private userIsCompany() {
    return this.user?.type === UserType.company
  }

  private async deleteEmployee(employee: ICartEmployee, trainingUuid: string): Promise<void> {
    const cartUuid = this.getCartItem(trainingUuid)?.uuid as string
    this.showConfirmDialog({
      text: 'Are you sure you want to remove this employee?',
      confirmText: 'Remove',
      cancelText: 'Cancel',
      confirmAction: async () => {
        this.loading = true

        // @ts-ignore
        const newSelectedEmployeesList = Object.assign([], this.employeeCartList[trainingUuid]).filter(
          (item: ICartEmployee) => item.employee.uuid !== employee.employee.uuid
        )

        const employeesToAdd = newSelectedEmployeesList.map((item: ICartEmployee) => item.employee.uuid)

        await this.updateCartEmployeesList({
          cartUuid,
          add: employeesToAdd,
          remove: [employee.cart_item_user_uuid as string],
        })

        await this.refreshCartAfterEmployeeAdd()
        this.loading = false
      },
    })
  }

  private scrollToId(id: string): void {
    const element = document.getElementById(id)

    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  private getAssignedPosition(employee: ICartItemUser): string {
    return employee.user_profile.position_classifier ? employee.user_profile.position_classifier.title : ''
  }

  beforeDestroy() {
    this.$root.$off('bv::employeesUpdated')
    this.$root.$off('newEmployeesAdded:success')
  }
}
