







































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import VeeValidate from 'vee-validate'
import { IAddEmployeeData } from '@/interfaces/EmployeeListInterface'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import { IClassifiersById } from '@/interfaces/training/ClassifierInterface'
import ClassifierService from '@/services/ClassifierService'

export enum ClassifiersCategory {
  employeePosition = 'company_employee_positions',
  nationality = 'nationality',
}

const Global = namespace('Global')

Vue.use(VeeValidate, { fieldsBagName: 'veeFields' })

const Company = namespace('Company')

interface IOption {
  value: number
  text: string
}

@Component
export default class AddEmployeesModal extends Vue {
  @Prop() private allEmployeesEmails!: Array<string>

  private newEmployees: IAddEmployeeData[] = [
    {
      position_classifier_id: [],
      employee: {
        name: '',
        email: '',
        phone_number: '',
      },
      alreadyAdded: false,
    },
  ]
  private totalRows: number = 1
  private successful: boolean = false
  private message: string = ''
  private loading: boolean = false
  private positionTypes: IClassifiersById[] = []

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Company.Action
  private addNewEmployee!: (data: IAddEmployeeData) => Promise<void>

  async created(): Promise<void> {
    this.positionTypes = await ClassifierService.getClassifiersByCategoryOptions(ClassifiersCategory.employeePosition)
  }

  private addRow(): void {
    this.totalRows += 1
    this.newEmployees.push({
      position_classifier_id: [],
      employee: {
        name: '',
        email: '',
        phone_number: '',
      },
      alreadyAdded: false,
    })
  }

  private deleteRow(index: number): void {
    this.totalRows -= 1
    this.newEmployees.splice(index, 1)

    this.$nextTick(() => {
      const { positionTypeOptions }: any = this.$refs

      if (positionTypeOptions) {
        positionTypeOptions.forEach((option: any) => {
          option.resetInputValue()
        })
      }
    })
  }

  get positionOptions(): IOption[] {
    return this.positionTypes.map((position: IClassifiersById) => ({
      value: position.value,
      text: position.label,
    }))
  }

  private async saveNewEmployees(): Promise<void> {
    this.loading = true

    const emailInputList = this.newEmployees.map((item) => item.employee.email)

    const hasDuplicateEmails = emailInputList.some((item) => this.allEmployeesEmails.includes(item))

    if (hasDuplicateEmails) {
      this.loading = false
      this.showSnackbar({
        type: SnackbarType.error,
        text: 'Entered employee email already exists',
      })

      return
    }

    if (new Set(emailInputList).size !== emailInputList.length) {
      this.loading = false
      this.showSnackbar({
        type: SnackbarType.error,
        text: "Employee's emails must be unique",
      })

      return
    }

    await Promise.all(
      this.newEmployees.map(async (dataItem: IAddEmployeeData, index) => {
        if (!dataItem.alreadyAdded) await this.addNewEmployee({ ...dataItem, index })
        this.newEmployees[index].alreadyAdded = true
      })
    )
      .then(async () => {
        this.successful = true
        this.loading = false
        this.showSnackbar({
          type: SnackbarType.success,
          text: 'Employees added to list',
        })
        this.$root.$emit('bv::toggle::collapse', 'newEmployees')
        this.$root.$emit('newEmployeesAdded:success')
      })
      .catch((error: any) => {
        this.showSnackbar({
          type: SnackbarType.error,
          text: error.message || 'Fields has errors',
        })
        this.$validator.errors.clear()

        if (error.status !== 422) return

        const errorFields = Object.keys(error.errors)
        errorFields.forEach((field: any) => {
          const errorString = error.errors[field].join(', ')
          this.$validator.errors.add({
            field: `${field}_${error.itemIndex}`,
            msg: errorString,
          })
        })
        this.successful = false
        this.loading = false
      })
  }
}
