




import { Component, Prop, Vue } from 'vue-property-decorator'
import IndustryPicker from '@/components/form/fields/training/IndustryPicker.vue'
import TrainingData from '@/classes/training/training'

@Component({
  components: {
    IndustryPicker,
  },
})
export default class TrainingStepIndustry extends Vue {
  @Prop() private readonly data!: TrainingData
}
