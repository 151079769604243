








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ProfileHeader extends Vue {
  @Prop() private readonly title!: string
  @Prop() private readonly description!: string
}
