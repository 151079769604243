import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { AxiosError, AxiosResponse } from 'axios'
import AuthService from '@/services/AuthService'
import {
  IBaseResponse,
  ILoginData,
  ILoginResponse,
  IPasswordResetData,
  IRegister,
  IRegisterCompany,
  IRegisterIndividual,
  IRegisterResponse,
  IRegisterTrainingCenter,
} from '@/interfaces/AuthInterface'
import parsedErrorResponseData from '@/helpers/error-response-parser'
import { authMutations } from '@/store/mutations/auth.mutations'
import tokenHelper from '@/helpers/token-helper'
import User, { UserType } from '@/classes/user/user'
import ProfileService from '@/services/ProfileService'
import router from '@/router'
import Routes from '@/router/routes'

const storedUser = tokenHelper.getUser()

@Module({ namespaced: true })
class Auth extends VuexModule {
  public status = storedUser?.token ? { loggedIn: true } : { loggedIn: false }
  public token = storedUser?.token || null
  public userData: User | null = null

  @Mutation
  public loginSuccess(token: string): void {
    this.status.loggedIn = true
    this.token = token
  }

  @Mutation
  public loginFailure(): void {
    this.status.loggedIn = false
    this.token = null
  }

  @Mutation
  public setUser(user: User | null): void {
    this.userData = user
  }

  @Mutation
  public logout(): void {
    this.status.loggedIn = false
    this.userData = null
    this.token = null
  }

  @Mutation
  public registerSuccess(): void {
    this.status.loggedIn = false
  }

  @Mutation
  public registerFailure(): void {
    this.status.loggedIn = false
  }

  @Action({ rawError: true })
  login(data: ILoginData): Promise<ILoginResponse> {
    return AuthService.login(data.email, data.password).then(
      (data) => {
        this.context.commit(authMutations.LOGIN_SUCCESS_MUTATION, data.token)

        return Promise.resolve(data)
      },
      (error: AxiosError) => {
        this.context.commit(authMutations.LOGIN_FAILURE_MUTATION)

        const message = parsedErrorResponseData(error)

        return Promise.reject(message)
      }
    )
  }

  @Action({ rawError: true })
  logInAsUser(uuid: string): Promise<ILoginResponse> {
    return AuthService.logInAsUser(uuid).then(
      (data) => {
        this.context.commit(authMutations.LOGIN_SUCCESS_MUTATION, data.token)
        router.push({ name: Routes.HOME })

        return Promise.resolve(data)
      },
      (error: AxiosError) => {
        this.context.commit(authMutations.LOGIN_FAILURE_MUTATION)

        const message = parsedErrorResponseData(error)

        return Promise.reject(message)
      }
    )
  }

  @Action({ rawError: true })
  getMe(): Promise<User> {
    return ProfileService.getMe().then(
      (user: User) => {
        this.context.commit(authMutations.SET_USER_MUTATION, user)

        return Promise.resolve(user)
      },
      (error: AxiosError) => {
        this.context.commit(authMutations.LOGIN_FAILURE_MUTATION)

        const message = parsedErrorResponseData(error)

        return Promise.reject(message)
      }
    )
  }

  @Action({ rawError: true })
  passwordResetInit(email: string): Promise<IBaseResponse> {
    return AuthService.sendPasswordResetEmail(email).then(
      (data: IBaseResponse) => {
        return Promise.resolve(data)
      },
      (error: AxiosError) => {
        const message = parsedErrorResponseData(error)

        return Promise.reject(message)
      }
    )
  }

  @Action({ rawError: true })
  setCompanyUserPassword(userData: IPasswordResetData): Promise<IBaseResponse> {
    return AuthService.setCompanyUserPassword(userData).then(
      (response) => Promise.resolve(response),
      (error) => {
        const message = parsedErrorResponseData(error)

        return Promise.reject(message)
      }
    )
  }

  @Action({ rawError: true })
  passwordReset(userData: IPasswordResetData): Promise<IBaseResponse> {
    return AuthService.resetPassword(userData).then(
      (response) => Promise.resolve(response),
      (error) => {
        const message = parsedErrorResponseData(error)

        return Promise.reject(message)
      }
    )
  }

  @Action
  logOut(): void {
    if (!tokenHelper.getLoggedInAs()) this.context.commit(authMutations.LOGOUT_MUTATION)
    AuthService.logout()
  }

  @Action({ rawError: true })
  async register(data: IRegisterIndividual | IRegisterCompany | IRegisterTrainingCenter): Promise<IRegisterResponse> {
    try {
      const params: IRegister = {
        name: '',
        companyName: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        terms: '',
        country: '',
        address: '',
        city: '',
        phone: '',
        trainingCenterName: '',
        type: UserType.user,
      }

      const response: AxiosResponse<IRegisterResponse> = await AuthService.register({ ...params, ...data })
      this.context.commit(authMutations.REGISTER_SUCCESS_MUTATION)

      return response.data
    } catch (error: any) {
      this.context.commit(authMutations.REGISTER_FAILURE_MUTATION)
      throw parsedErrorResponseData(error)
    }
  }

  get isLoggedIn(): boolean {
    return this.status.loggedIn
  }

  get userIsTrainingCenter(): boolean {
    return this.userData?.type === UserType.trainingCenter
  }

  get userIsAdmin(): boolean {
    return this.userData?.type === UserType.admin
  }

  get userIsCompany(): boolean {
    return this.userData?.type === UserType.company
  }

  get userIsDefault(): boolean {
    return this.userData?.type === UserType.user
  }

  get user(): User | null {
    return this.userData
  }
}

export default Auth
