import TrainingStepIndustry from '@/components/training-create/steps/TrainingStepIndustry.vue'
import TrainingStepStandard from '@/components/training-create/steps/TrainingStepStandard.vue'
import TrainingStepTrainingType from '@/components/training-create/steps/TrainingStepTrainingType.vue'
import TrainingStepLocation from '@/components/training-create/steps/TrainingStepLocation.vue'
import TrainingStepDate from '@/components/training-create/steps/TrainingStepDate.vue'
import TrainingStepSeatsPrice from '@/components/training-create/steps/TrainingStepSeatsPrice.vue'
import TrainingStepServices from '@/components/training-create/steps/TrainingStepServices.vue'
import ITrainingDataForm from '@/classes/training/training'
import TrainingLocation from '@/classes/training/training-location'
import TrainingLocationDate from '@/classes/training/training-location-date'
import SelectOption from '@/interfaces/SelectOption'

export const StepData: any = {
  1: {
    index: 1,
    title: 'Industry',
    heading: 'Choose training industry',
    component: TrainingStepIndustry,
    isFirst: true,
    isValid: (data: ITrainingDataForm) => !!data.industryClassifierId,
    isCompleted: (data: ITrainingDataForm, step: string) => Number(step) <= data.completedStep,
    isActive: (data: ITrainingDataForm, step: string) => Number(step) === data.activeStep,
  },
  2: {
    index: 2,
    title: 'Standard',
    heading: 'Choose training standard',
    component: TrainingStepStandard,
    isValid: (data: ITrainingDataForm) => !!data.standardClassifierId,
    isCompleted: (data: ITrainingDataForm, step: string) => Number(step) <= data.completedStep,
    isActive: (data: ITrainingDataForm, step: string) => Number(step) === data.activeStep,
  },
  3: {
    index: 3,
    title: 'Training type',
    heading: 'Choose training type',
    component: TrainingStepTrainingType,
    isValid: (data: ITrainingDataForm) => !!data.trainingTypeClassifierId,
    isCompleted: (data: ITrainingDataForm, step: string) => Number(step) <= data.completedStep,
    isActive: (data: ITrainingDataForm, step: string) => Number(step) === data.activeStep,
  },
  4: {
    index: 4,
    title: 'Location',
    heading: 'Select training location',
    component: TrainingStepLocation,
    isValid: (data: ITrainingDataForm) =>
      data?.trainingLocations
        .map((location: TrainingLocation) => {
          if (location.location === LocationTypes.online) return true

          return !!location.country && !!location.city
        })
        .every((element: boolean) => element),
    isCompleted: (data: ITrainingDataForm, step: string) => Number(step) <= data.completedStep,
    isActive: (data: ITrainingDataForm, step: string) => Number(step) === data.activeStep,
  },
  5: {
    index: 5,
    title: 'Date from / to',
    heading: 'Select training dates',
    component: TrainingStepDate,
    isValid: (data: ITrainingDataForm) =>
      data?.trainingLocationDates
        .map((date: TrainingLocationDate) => {
          return !!date.from && !!date.to
        })
        .every((element: boolean) => element),
    isCompleted: (data: ITrainingDataForm, step: string) => Number(step) <= data.completedStep,
    isActive: (data: ITrainingDataForm, step: string) => Number(step) === data.activeStep,
  },
  6: {
    index: 6,
    title: 'Seats & Price',
    heading: 'Choose seats and prices',
    component: TrainingStepSeatsPrice,
    isValid: (data: ITrainingDataForm) =>
      data?.trainingLocationDates
        .map((date: TrainingLocationDate) => {
          return !!date.seats && Number(date.seats) !== 0 && !!date.price && Number(date.price) !== 0
        })
        .every((element: boolean) => element),
    isCompleted: (data: ITrainingDataForm, step: string) => Number(step) <= data.completedStep,
    isActive: (data: ITrainingDataForm, step: string) => Number(step) === data.activeStep,
  },
  7: {
    index: 7,
    title: 'Services',
    heading: 'Choose additional services',
    component: TrainingStepServices,
    isLast: true,
    isValid: () => true,
    isCompleted: (data: ITrainingDataForm, step: string) => Number(step) <= data.completedStep,
    isActive: (data: ITrainingDataForm, step: string) => Number(step) === data.activeStep,
  },
}

export enum LocationTypes {
  online = 'online',
  mixed = 'mixed',
  local = 'local',
}

export const LocationTypesData: any = {
  [LocationTypes.local]: 'Local',
  [LocationTypes.mixed]: 'Mixed',
  [LocationTypes.online]: 'Online',
}

export const TrainingLanguages: SelectOption[] = [
  { text: 'EN', value: 'EN' },
  { text: 'LT', value: 'LT' },
  { text: 'PL', value: 'PL' },
]
