


















































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Routes from '@/router/routes'
import { ICartResponseData } from '@/interfaces/CartInterface'
import cartHelper from '@/helpers/cart-helper'

const Cart = namespace('Cart')

const Auth = namespace('Auth')

@Component
export default class CartDropdown extends Vue {
  @Cart.Getter
  private cartTotalPrice!: string

  @Cart.Getter
  private cartTotalDiscount!: string

  @Auth.Getter
  private userIsCompany!: boolean

  @Cart.Getter
  private cartItems!: ICartResponseData[]

  private goToCart(): void {
    this.$root.$emit('cartDropdownHide')

    if (this.$router.currentRoute.name !== Routes.CART) this.$router.push({ name: Routes.CART })
  }

  private getDiscount(training: ICartResponseData): string {
    return cartHelper.getItemDiscount(training)
  }
}
