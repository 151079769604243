














import { Component, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import TrainingCalendar from '@/components/profile/TrainingCalendar.vue'
import TrainingTable from '@/components/profile/TrainingTable.vue'
import TrainingLocationDate from '@/classes/training/training-location-date'
import { IMeta, IPaginatedResponse } from '@/interfaces/ResponseInterface'
import TrainingLocationDateService from '@/services/TrainingLocationDateService'
import { ITableProperties } from '@/interfaces/TableInterface'

enum TrainingFilter {
  search = 'q',
  industry = 'filter-in-training-industry_classifier_id',
  standard = 'filter-training-standard_classifier_id',
  from = 'filter-between-from',
  status = 'filter-in-status',
}

@Component({
  components: { TrainingCalendar, TrainingTable },
})
export default class TrainingCenterTrainings extends Vue {
  trainingsLocationDates: TrainingLocationDate[] = null!
  meta: IMeta = null!

  tableProperties: ITableProperties = {
    currentPage: 1,
    perPage: 15,
    sortBy: null,
    order: null,
    filters: {
      search: '',
      industryClassifierId: [],
      standardClassifierId: '',
      from: '',
      status: [],
    },
  }

  created(): void {
    this.parseQueryParams()
    this.loadTrainingLocationDates()

    this.$watch(
      () => this.$route,
      () => {
        this.parseQueryParams()
        this.loadTrainingLocationDates()
      },
      { deep: true }
    )
    this.$watch(() => this.tableProperties, this.changeRouteByProperties, { deep: true })
  }

  private loadTrainingLocationDates(): Promise<void> {
    const { query } = this.$router.currentRoute

    return TrainingLocationDateService.profileList(query, this.tableProperties.perPage).then(
      (response: IPaginatedResponse<TrainingLocationDate[]>) => {
        this.trainingsLocationDates = response.data
        this.meta = response.meta
      }
    )
  }

  private changeRouteByProperties(): void {
    const { name, query, params }: any = this.$router.currentRoute

    const { currentPage, sortBy, order, filters } = this.tableProperties

    const routeQuery = {
      ...query,
      page: currentPage.toString(),
    }

    if (sortBy) routeQuery.sort_by = sortBy
    else delete routeQuery.sort_by

    if (order) routeQuery.order = order
    else delete routeQuery.order

    if (filters.search !== '') routeQuery[TrainingFilter.search] = filters.search
    else delete routeQuery[TrainingFilter.search]

    if (filters.industryClassifierId.length > 0) {
      routeQuery[TrainingFilter.industry] = filters.industryClassifierId.join(',')
    } else delete routeQuery[TrainingFilter.industry]

    if (filters.standardClassifierId !== '') routeQuery[TrainingFilter.standard] = filters.standardClassifierId
    else delete routeQuery[TrainingFilter.standard]

    if (filters.from !== '') routeQuery[TrainingFilter.from] = filters.from
    else delete routeQuery[TrainingFilter.from]

    const status = filters.status.filter((status: string) => status !== '')

    if (status.length > 0) routeQuery[TrainingFilter.status] = status.join(',')
    else delete routeQuery[TrainingFilter.status]

    this.$router
      .replace({
        name,
        params,
        query: routeQuery,
      })
      .catch(() => {})
  }

  private parseQueryParams(): void {
    const { query } = this.$router.currentRoute

    this.tableProperties.currentPage = Number(_.get(query, 'page', 1))
    this.tableProperties.sortBy = _.get(query, 'sort_by') as string
    this.tableProperties.order = _.get(query, 'order') as string

    this.tableProperties.filters.search = _.get(query, TrainingFilter.search, '')
    this.tableProperties.filters.industryClassifierId =
      (_.get(query, TrainingFilter.industry, null) as string)?.split(',') || []
    this.tableProperties.filters.from = _.get(query, TrainingFilter.from, '')
    this.tableProperties.filters.status = (_.get(query, TrainingFilter.status, '') as string)
      .split(',')
      .filter((status: string) => status !== '')
  }
}
