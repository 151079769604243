import _ from 'lodash'

export default class Model {
  id!: number
  uuid!: string
  detailsShowing: boolean = false

  constructor(data: any) {
    this.id = data.id
    this.uuid = data.uuid
  }

  protected setRelation(data: any, Submodel: any, defaultValue: any = null): any {
    if (!data) return defaultValue

    return _.isArray(data) ? data.map((item: any) => new Submodel(item)) : new Submodel(data)
  }

  protected toFormDataGeneric(data: any): any {
    if (_.isArray(data)) {
      return data.map((item) => {
        return 'toFormData' in data ? item.toFormData() : data
      })
    }

    return data.toFormData ? data.toFormData() : data
  }

  public toggleDetails(): void {
    this.detailsShowing = !this.detailsShowing
  }
}
