






















import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import User from '@/classes/user/user'
import UserService from '@/services/UserService'
import CompanyDetailsModel from '@/classes/company-details'

const Auth = namespace('Auth')

@Component
export default class CompanyDetails extends Vue {
  @Auth.Getter
  private user!: User

  public companyDetails: CompanyDetailsModel = null!
  public loading: boolean = true

  public created(): void {
    this.loadCompanyDetails()
  }

  public loadCompanyDetails(): void {
    this.loading = true

    UserService.getCompanyDetails(this.user.uuid)
      .then((companyDetails: CompanyDetailsModel) => (this.companyDetails = companyDetails))
      .finally(() => (this.loading = false))
  }
}
