import { IUserResponse } from '@/interfaces/user/UserInterface'
import UserLocation from '@/classes/user/user-location'
import UserProfile from '@/classes/user/user-profile'
import Model from '@/classes/model'
import IUserForm from '@/interfaces/form-interfaces/UserFormInterface'
import DocumentClass from './document'
import TrainingCenter from './training-center'
import Company from './company'

export enum UserType {
  admin = 'admin',
  user = 'cadet',
  company = 'company',
  trainingCenter = 'training_center',
}

export default class User extends Model {
  email!: string
  additionalEmail!: string | null
  moneySpent!: number
  name!: string
  phoneNumber!: string
  type!: UserType
  userProfile!: UserProfile
  userLocation!: UserLocation
  documents!: DocumentClass[]
  trainingCenter!: TrainingCenter
  company!: Company

  constructor(data: IUserResponse) {
    super(data)

    this.email = data.email
    this.additionalEmail = data.additional_email
    this.moneySpent = data.money_spent
    this.name = data.name
    this.phoneNumber = data.phone_number
    this.type = data.type

    this.userProfile = this.setRelation(data.user_profile, UserProfile, {})
    this.userLocation = this.setRelation(data.user_location, UserLocation, {})
    this.documents = this.setRelation(data.documents, DocumentClass, [])
    this.trainingCenter = this.setRelation(data.training_center, TrainingCenter, null)
    this.company = this.setRelation(data.company, Company, {})
  }

  toFormData(): IUserForm {
    const user: IUserForm = {
      uuid: this.uuid,
      email: this.email,
      additionalEmail: this.additionalEmail || '',
      moneySpent: this.moneySpent,
      name: this.name,
      phoneNumber: this.phoneNumber,
      type: this.type,
      userProfile: this.toFormDataGeneric(this.userProfile),
      userLocation: this.toFormDataGeneric(this.userLocation),
    }

    user.company = this.company ? this.toFormDataGeneric(this.company) : {}

    user.trainingCenter = this.trainingCenter ? this.toFormDataGeneric(this.trainingCenter) : { industry: {} }

    user.documents = this.documents || []

    return user
  }
}
