




























































































































































































import { Component } from 'vue-property-decorator'
import _ from 'lodash'
import { namespace } from 'vuex-class'
import { AxiosError } from 'axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import PageHeader from '@/components/PageHeader.vue'
import ProfileHeader from '@/components/profile/edit/ProfileHeader.vue'
import { IJobPositionsPayload } from '@/interfaces/JobPositionsInterface'
import ProfileEditAbstract from '@/components/profile/edit/ProfileEditAbstract.vue'
import TextArea from '@/components/form/fields/TextArea.vue'
import DatePickerField from '@/components/form/fields/DatePickerField.vue'
import JobPositionsService from '@/services/JobPositionsService'
import ClassifierService from '@/services/ClassifierService'
import Classifier from '@/classes/training/classifier'
import SelectField from '@/components/form/fields/SelectField.vue'
import User from '@/classes/user/user'
import Routes from '@/router/routes'
import IClassifierOption from '@/interfaces/ClassifierOptionInterface'
import WysiwygField from '@/components/form/fields/WysiwygField.vue'

const Auth = namespace('Auth')

@Component({
  components: { WysiwygField, DatePickerField, TextArea, ProfileHeader, PageHeader, Breadcrumbs },
})
export default class NewJobPosition extends ProfileEditAbstract {
  @Auth.Getter
  private user!: User
  loading: boolean = false

  private jobPositionData: IJobPositionsPayload = {
    title: '',
    short_description: '',
    positions_needed: '',
    requirements: '',
    start_date: '',
    end_date: '',
    is_worldwide: false,
    certificates: [],
  }
  private industriesList: IClassifierOption[] = []
  private standardsList: IClassifierOption[] = []
  private trainingTypesList: IClassifierOption[] = []

  private addCertificate(): void {
    this.jobPositionData.certificates?.push({
      id: this.jobPositionData.certificates?.length + 1,
      industry_classifier_id: null,
      standard_classifier_id: null,
      training_type_classifier_id: null,
    })
  }

  private removeCertificate(index: number): void {
    if (typeof this.jobPositionData?.certificates !== 'undefined') {
      const updatedCertificates = _.cloneDeep(this.jobPositionData?.certificates)
      updatedCertificates.splice(index, 1)
      this.jobPositionData.certificates = updatedCertificates
    }
  }

  private postJobPosition(): void {
    this.loading = true
    JobPositionsService.addNewJobPosition(this.jobPositionData)
      .then(() => {
        this.$router.push(`/${Routes.PROFILE_JOB_POSITIONS}`)
      })
      .catch((error: AxiosError) => {
        this.setErrorsFromResponse(error)
        this.loading = false
      })
  }

  private mapCertificateClassifiers(data: Classifier[]): IClassifierOption[] {
    return data.map((item: Classifier) => ({
      value: item.id,
      text: item.title,
      industryId: item.industryId || null,
      standardId: item.standardId || null,
    }))
  }

  private filterClassifierList(
    data: IClassifierOption[],
    industryId: number | null = null,
    standardId: number | null = null
  ): IClassifierOption[] {
    return data.filter((item) => {
      return industryId === item.industryId && standardId === item.standardId
    })
  }

  private resetUpdatedSelect(index: number, resetStandard: boolean = false, resetType: boolean = false): void {
    if (typeof this.jobPositionData.certificates !== 'undefined') {
      const standardSelect = this.$refs[`standardSelect${index}`] as SelectField[]

      const trainingSelect = this.$refs[`trainingSelect${index}`] as SelectField[]

      if (resetStandard && _.isArray(standardSelect) && _.size(standardSelect)) {
        standardSelect[0].select(null)
      }

      if (resetType && _.isArray(trainingSelect) && _.size(trainingSelect)) {
        trainingSelect[0].select(null)
      }
    }
  }

  private discardAllData(): void {
    this.$router.push(Routes.PROFILE_JOB_POSITIONS)
  }

  created(): void {
    if (this.user.type !== 'company') {
      window.location.replace('/')

      return
    }
    ClassifierService.getIndustryList({}, 9999).then((response) => {
      this.industriesList = this.mapCertificateClassifiers(response.data)
    })
    ClassifierService.getStandardList({}, 9999).then((response) => {
      this.standardsList = this.mapCertificateClassifiers(response.data)
    })
    ClassifierService.getTrainingTypeList({}, 9999).then((response) => {
      this.trainingTypesList = this.mapCertificateClassifiers(response.data)
    })
  }
}
