import Company from '@/classes/user/company'
import ICompanyDiscountResponse from '@/interfaces/CompanyDiscountInterface'
import Model from '@/classes/model'

export default class CompanyDiscount extends Model {
  companyId!: number
  percent!: number
  startDate!: string
  endDate!: string
  company!: Company

  constructor(data: ICompanyDiscountResponse) {
    super(data)

    this.companyId = data.company_id
    this.percent = data.percent
    this.startDate = data.start_date
    this.endDate = data.end_date
    this.company = this.setRelation(data.company, Company)
  }
}
