


















import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import TrainingCalendar from '@/components/profile/TrainingCalendar.vue'
import CompanyTrainingsTable from '@/components/profile/CompanyTrainingsTable.vue'
import AddCertificateModal from '@/components/AddCertificateModal.vue'
import CertificatesTable from '@/components/CertificatesTable.vue'
import CertificateService from '@/services/certificateService'
import { IMeta } from '@/interfaces/ResponseInterface'
import { ITableProperties } from '@/interfaces/TableInterface'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'

const Global = namespace('Global')

@Component({
  components: { CertificatesTable, AddCertificateModal, CompanyTrainingsTable, TrainingCalendar },
})
export default class UserCertificates extends Vue {
  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  private certificateData: any = null

  meta: IMeta | null = null

  tableProperties: ITableProperties = {
    currentPage: 1,
    perPage: 10,
    sortBy: null,
    order: null,
  }

  created(): void {
    this.getCertificateData()
  }

  private getCertificateData(): void {
    CertificateService.getMyCertificateList(this.tableProperties.currentPage, this.tableProperties.perPage).then(
      (response) => {
        this.certificateData = response.data
        this.meta = response.meta
      }
    )
  }

  private deleteCertificate(id: number) {
    CertificateService.deleteCertificate(id).then(() => {
      this.showSnackbar({
        type: SnackbarType.success,
        text: 'Successfully removed',
      })
      this.getCertificateData()
    })
  }
}
