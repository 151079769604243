enum trainingGetters {
  TRAINING = 'training',
  OLD_TRAINING = 'oldTraining',
  TRAINING_DATA = 'trainingData',
  TRAINING_LOCATIONS = 'trainingLocations',
  TRAINING_LOCATION_DATES = 'trainingLocationDates',
  TRAINING_LOCATION_DATE_SERVICES = 'trainingLocationDateServices',
  LOADING = 'loading',
  TRAINING_IS_CHANGED = 'trainingIsChanged',
  TRAINING_IS_INITIALIZED = 'trainingIsInitialized',
  TRAINING_BASE = 'trainingBase',
}

export default trainingGetters
