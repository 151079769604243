





















































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Pagination extends Vue {
  @Prop() meta!: any
  @Prop() disabled!: boolean
  @Prop({ default: 11 }) pageLimit!: number

  lowerPage(): void {
    if (this.meta.current_page !== 1) this.emitPageChange(this.meta.current_page - 1)
  }

  increasePage(): void {
    if (this.meta.current_page !== this.meta.last_page) this.emitPageChange(this.meta.current_page + 1)
  }

  emitPageChange(page: string | number): void {
    if (this.disabled || page === this.meta.current_page) return
    this.$emit('input', page)
    this.$emit('change', page)
    this.$emit('onChange', page)
  }

  get pageOffset(): number {
    if (this.meta.current_page >= 5) return 3

    return 8 - this.meta.current_page
  }

  get pageEndOffset(): number {
    if (this.meta.current_page <= this.meta.last_page - 5) return 3

    return 7 - (this.meta.last_page - this.meta.current_page)
  }
}
