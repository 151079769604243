import IFilter from '@/interfaces/FilterInterface'

/* Possible format modifiers :
  'equals' => 'equals'
  'notIn' => 'notIn'
  'like' => 'like'
  'in' => 'in'
  'lt' => 'less'
  'lte' => 'lessOrEqual'
  'gt' => 'greater'
  'gte' => 'greaterOrEqual'
  'nulled' => 'isNull'

  Format: filter-modifier-field=value
  Example: filter-gte-id=5
*/

export default class Filter {
  value: string | number | null = ''
  modifier: string = ''
  field: string = ''
  prefix: string = '&'

  constructor(data: IFilter) {
    if (data.value) this.value = data.value

    if (data.modifier) this.modifier = data.modifier

    if (data.prefix) this.prefix = data.prefix
    this.field = data.field
  }

  createFilterString(): string {
    return this.value === '' || this.value === null
      ? ''
      : `${this.prefix}filter${!this.modifier ? '' : `-${this.modifier}`}-${this.field}=${this.value}`
  }
}
