






























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ICountries, IRegisterResponse, IRegisterTrainingCenter, IValidationParams } from '@/interfaces/AuthInterface'
import { UserType } from '@/classes/user/user'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'

const Global = namespace('Global')

const Auth = namespace('Auth')

interface IOption {
  value: string
  text: string
}

@Component
export default class TrainingRegister extends Vue {
  @Prop() private bus: any
  @Prop() private agree: any
  private passwordRevealed: boolean = false
  private showPassword(): void {
    this.passwordRevealed = !this.passwordRevealed
  }
  private countries: ICountries[] = require('@/storage/countries.json')
  private trainingCenter: IRegisterTrainingCenter = {
    name: '',
    trainingCenterName: '',
    email: '',
    password: '',
    type: UserType.trainingCenter,
    country: '',
    city: '',
    address: '',
    phone: '',
  }
  private submitted: boolean = false
  private successful: boolean = false
  private message: string = ''
  private validationParams: IValidationParams = {
    passwordConfirmation: '',
    terms: '',
  }

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Auth.Action
  private register!: (data: IRegisterTrainingCenter) => Promise<IRegisterResponse>

  mounted(): void {
    this.bus.$on('submit', this.submit)
  }

  beforeDestroy(): void {
    this.bus.$off('submit', this.submit)
  }

  private async submit(token?: string) {
    try {
      this.message = ''
      this.submitted = true
      this.validationParams.terms = this.agree
      this.validationParams['g-recaptcha-response'] = token

      await this.register({ ...this.trainingCenter, ...this.validationParams })

      this.successful = true
      this.$emit('register:success', {
        success: true,
        email: this.trainingCenter.email,
      })

      this.showSnackbar({
        type: SnackbarType.success,
        text: 'Successfully registered',
      })
    } catch (error: any) {
      this.$emit('register:failed')
      this.message = error.message
      this.scrollToErrorMessage()
      this.$validator.errors.clear()

      if (error.status !== 422) return

      const errorFields = Object.keys(error.errors)
      errorFields.forEach((field: any) => {
        const errorString = error.errors[field].join(', ')
        this.$validator.errors.add({
          field,
          msg: errorString,
        })
      })
      this.successful = false
    }
  }

  get countryOptions(): IOption[] {
    return this.countries.map((country: ICountries) => ({
      value: country.name,
      text: country.name,
    }))
  }

  private scrollToErrorMessage(): void {
    const element = document.getElementById('message')

    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}
