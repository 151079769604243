









































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AxiosError } from 'axios'
import _ from 'lodash'
import { namespace } from 'vuex-class'
import { VueEditor } from 'vue2-editor'
import Classifier from '@/classes/training/classifier'
import { IClassifierForm } from '@/interfaces/training/ClassifierInterface'
import ClassifierService from '@/services/ClassifierService'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import TextArea from '@/components/form/fields/TextArea.vue'

const Global = namespace('Global')

@Component({
  components: { TextArea, VueEditor },
})
export default class EditClassifierModal extends Vue {
  @Prop() refreshList!: () => void
  @Prop() classifier!: Classifier

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  visible: boolean = false
  data: IClassifierForm = null!

  loading: boolean = false
  formErrors: any = {}

  public created(): void {
    this.data = this.classifier.toFormData()
  }

  private submit(): void {
    this.loading = true

    ClassifierService.update(this.classifier.id, this.data)
      .then(() => {
        this.refreshList()

        this.showSnackbar({
          type: SnackbarType.success,
          text: 'Classifier successfully updated',
        })

        this.close()
      })
      .catch((error: AxiosError) => {
        this.formErrors = error.response?.data.errors

        this.showSnackbar({
          type: SnackbarType.error,
          text: error.response?.data.message,
        })
      })
      .finally(() => (this.loading = false))
  }

  public toggle(): void {
    this.visible = !this.visible
  }

  public close(): void {
    this.visible = false
  }

  private getError(key: string): string[] | null {
    return _.get(this.formErrors, `${key}.0`, null)
  }

  private removeError(key: string): void {
    delete this.formErrors[key]
  }
}
