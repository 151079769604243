






































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { BvTableFieldArray } from 'bootstrap-vue/src/components/table'
import _ from 'lodash'
import { IMeta, IPaginatedResponse } from '@/interfaces/ResponseInterface'
import { ITableProperties, ITableSortChanged } from '@/interfaces/TableInterface'
import EmptyList from '@/components/EmptyList.vue'
import Pagination from '@/components/Pagination.vue'
import Search from '@/components/Search.vue'
import InvoiceService from '@/services/InvoicesService'
import Invoice from '@/classes/invoice'
import User, { UserType } from '@/classes/user/user'
import scrollToRef from '@/helpers/scroll-to-ref-helper'

const Auth = namespace('Auth')

enum OrdersFilter {
  search = 'q',
}

@Component({
  components: { Search, EmptyList, Pagination },
})
export default class MyOrders extends Vue {
  private loading: boolean = false
  private orders: any = []
  private meta: IMeta | any = {}

  @Auth.Getter
  private user!: User

  public tableProperties: ITableProperties = {
    currentPage: 1,
    perPage: 15,
    filters: {
      search: '',
    },
  }

  UserType = UserType

  public created(): void {
    this.parseQueryParams()
    this.ordersList()

    this.$watch(() => this.$route, this.ordersList)
  }

  private ordersList(): void {
    this.loading = true

    const { query } = this.$router.currentRoute

    InvoiceService.getInvoicesList(query).then((response: IPaginatedResponse<Invoice[]>) => {
      this.orders = response.data
      this.meta = response.meta
      this.loading = false
    })
  }

  private onTableSortChange(event: ITableSortChanged): void {
    this.tableProperties.sortBy = event.sortBy
    this.tableProperties.order = event.sortDesc ? 'desc' : 'asc'

    this.changeRouteByProperties()
  }

  private onPageChange(page: number): void {
    this.tableProperties.currentPage = page

    this.changeRouteByProperties()
  }

  private onSearch(search: string): void {
    this.tableProperties.filters.search = search

    this.changeRouteByProperties()
  }

  private changeRouteByProperties(): void {
    const { name, query, params }: any = this.$router.currentRoute

    const { currentPage, sortBy, order, filters } = this.tableProperties

    const routeQuery = {
      ...query,
      page: currentPage,
      sort_by: sortBy,
      order,
    }

    if (filters.search !== '') routeQuery[OrdersFilter.search] = filters.search
    else delete routeQuery[OrdersFilter.search]

    this.$router
      .replace({
        name,
        params,
        query: routeQuery,
      })
      .catch(() => {})
  }

  private onPageInput(id: string): void {
    this.loading = true

    scrollToRef(id)
  }

  private parseQueryParams(): void {
    const { query } = this.$router.currentRoute

    this.tableProperties.currentPage = Number(_.get(query, 'page', 1))
    this.tableProperties.filters.search = _.get(query, OrdersFilter.search, '')
    this.tableProperties.filters.sortBy = _.get(query, 'sort_by')
    this.tableProperties.filters.order = _.get(query, 'order')
  }

  public getToggleOrderEvent(order: string): any {
    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'asc') {
      return {
        sortBy: '',
        sortDesc: false,
      }
    }

    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'desc') {
      return {
        sortBy: order,
        sortDesc: false,
      }
    }

    return {
      sortBy: order,
      sortDesc: true,
    }
  }

  private getFilterOrderClass(order: string): string {
    if (this.tableProperties.sortBy === order) {
      return this.tableProperties.order || ''
    }

    return ''
  }

  private getFields(): BvTableFieldArray {
    return [
      {
        key: 'date',
        label: 'Document date',
        sortable: false,
      },
      {
        key: 'type',
        label: 'Document type',
        sortable: false,
      },
      {
        key: 'number',
        label: 'Document number',
        sortable: false,
      },
      {
        key: 'total',
        label: 'Total',
        sortable: false,
      },
      {
        key: 'name',
        label: this.user.type === UserType.trainingCenter ? 'Client name' : 'Training center name',
        sortable: false,
      },
      {
        key: 'invoice',
        label: 'Download',
        sortable: false,
      },
    ]
  }
}
