enum StudentStatusType {
  active = 'active',
  waitingApproval = 'waiting_approval',
  disabled = 'disabled',
}

export default StudentStatusType

export const StudentStatusTypeTitle: any = {
  [StudentStatusType.active]: {
    title: 'Active',
  },
  [StudentStatusType.waitingApproval]: {
    title: 'Waiting approval',
  },
  [StudentStatusType.disabled]: {
    title: 'Disabled',
  },
}
