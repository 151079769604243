import ITrainingCenterListUserResponse from '@/interfaces/training-center/TrainingCenterListUserInterface'
import DocumentClass from '@/classes/user/document'
import TrainingCenter from '@/classes/user/training-center'
import Model from '@/classes/model'

export default class TrainingCenterListUser extends Model {
  locations!: string
  totalTrainings!: number
  additionalEmail!: string
  totalActiveTrainings!: number
  trainingCenter!: TrainingCenter
  logo!: DocumentClass
  createdUserId!: number
  photos!: DocumentClass[]

  constructor(data: ITrainingCenterListUserResponse) {
    super(data)

    this.locations = data.locations
    this.totalTrainings = data.total_trainings
    this.additionalEmail = data.additional_email
    this.totalActiveTrainings = data.total_active_trainings
    this.createdUserId = data.created_user_id

    this.trainingCenter = this.setRelation(data.training_center, TrainingCenter)
    this.logo = this.setRelation(data.logo, DocumentClass)
    this.photos = this.setRelation(data.photos, DocumentClass)
  }

  get formattedLocation(): string {
    if (!this.locations) return '-'

    if (this.locations.split(',').length > 3) return 'Worldwide'

    return this.locations
  }
}
