import { AxiosResponse } from 'axios'
import http from '@/classes/http'
import DocumentClass from '@/classes/user/document'
import User from '@/classes/user/user'
import IUserForm from '@/interfaces/form-interfaces/UserFormInterface'

export default class ProfileService {
  static getMe(): Promise<User> {
    return http.get('auth/user/me').then((response: AxiosResponse) => {
      return new User(response.data.data)
    })
  }

  static deleteDBImage(image: DocumentClass): Promise<AxiosResponse> {
    return http.delete(`documents/${image.id}`)
  }

  static submitProfileEdit(user: IUserForm, formData: FormData): Promise<AxiosResponse> {
    return http.post(`users/${user.uuid}/update-user-and-profile`, formData, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    })
  }
}
