




































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { DateTime } from 'luxon'
import TrainingLocationDate from '@/classes/training/training-location-date'
import DocumentClass from '@/classes/user/document'
import { DocumentTypes } from '@/configs/document-type'
import { LocationTypesData } from '@/configs/training-steps'
import CartActionButton from '@/components/form/cart/CartActionButton.vue'
import Routes from '@/router/routes'

const Auth = namespace('Auth')

@Component({
  components: {
    CartActionButton,
  },
})
export default class TrainingDescription extends Vue {
  @Prop() private readonly trainingDate!: TrainingLocationDate
  private activeSlide: number = 0
  LocationTypesData = LocationTypesData
  Routes = Routes

  @Auth.Getter
  private userIsTrainingCenter!: boolean

  private setGallerySlide(index: number): void {
    this.activeSlide = index
  }

  get printValidity(): string | null {
    const validity = this.trainingDate.training?.trainingTypeClassifier?.validity

    if (!validity) return null

    if (validity > 1) return `${validity.toFixed(2)} years`

    return `${validity.toFixed(2)} year`
  }

  get trainingLogo(): DocumentClass | undefined {
    return this.trainingDate.training?.user?.documents?.find(
      (document: DocumentClass) => document.type === DocumentTypes.logo
    )
  }

  get gallery(): DocumentClass[] | undefined {
    return this.trainingDate.training?.user?.documents?.filter(
      (document: DocumentClass) => document.type === DocumentTypes.photo
    )
  }

  get galleryForSwiper(): any {
    return this.gallery?.map((document: DocumentClass) => ({
      src: document.getImageURL(),
      thumbnail: document.getImageURL(),
      w: window.innerWidth,
      h: window.innerHeight,
    }))
  }

  get ended(): any {
    return DateTime.fromISO(this.trainingDate.to) < DateTime.now()
  }
}
