import { AxiosResponse } from 'axios'
import { IJobPositionCertificate } from '@/interfaces/JobPositionsInterface'
import http from '@/classes/http'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'

export default class CertificateService {
  static prefix: string = 'user-certificates'

  static getMyCertificateList(page: number = 1, per_page: number = 10): Promise<any> {
    return http
      .get(`${this.prefix}/my`, {
        params: {
          per_page,
          page,
        },
      })
      .then((response: AxiosResponse<IPaginatedResponse<any[]>>) => ({
        data: response.data.data,
        meta: response.data.meta,
      }))
  }

  static addCertificate(data: IJobPositionCertificate): Promise<IJobPositionCertificate> {
    return http.post(this.prefix, data)
  }

  static deleteCertificate(certificateId: number): Promise<AxiosResponse> {
    return http.delete(`${this.prefix}/${certificateId}`)
  }
}
