














































































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import Classifier from '@/classes/training/classifier'
import TrainingCenterListUser from '@/classes/user/training-center-list-user'
import TrainingService from '@/services/TrainingService'
import DocumentClass from '@/classes/user/document'

@Component
export default class TrainingCenterDescription extends Vue {
  @Prop() trainingCenter!: TrainingCenterListUser
  @Prop() activeTrainingCenters!: number
  industryOptions: Classifier[] = []

  async created(): Promise<void> {
    this.industryOptions = await TrainingService.getClassifiers('get-industry-list')
  }

  industryVarName(code: string): string {
    return _.camelCase(`is_${code}`)
  }

  private formattedUrl(url: string): string {
    return `//${url}`
  }

  get galleryForSwiper(): any {
    return this.trainingCenter.photos.map((document: DocumentClass) => ({
      src: document.getImageURL(),
      thumbnail: document.getImageURL(),
      w: window.innerWidth,
      h: window.innerHeight,
    }))
  }
}
