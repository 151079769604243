














import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import User from '@/classes/user/user'

const Auth = namespace('Auth')

@Component
export default class AdminDetails extends Vue {
  @Auth.Getter
  private user!: User
}
