

























import { Component, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AbstractField from '@/components/form/fields/AbstractField.vue'
import Classifier from '@/classes/training/classifier'
import { ISteps } from '@/store/modules/training.module'

const Training = namespace('Training')

@Component
export default class StandardPicker extends AbstractField {
  @Prop({ default: [] }) private readonly options!: Classifier[]

  @Training.Action
  private appendToTraining!: (data: any) => void

  @Training.Action
  private setStates!: (states: any) => void

  @Training.Action
  private deleteStandardClassifierData!: () => void

  @Training.Mutation
  private setStepData!: (stepData: ISteps) => void

  created(): void {
    this.$root.$on('resetStepData', (step: number) => {
      if (step === 2) {
        this.clearData()
      }
    })
  }

  clearData(): void {
    this.deleteStandardClassifierData()
    this.setStepData({
      activeStep: 2,
      completedStep: 2,
    })
  }

  private setTraining(event: any): void {
    this.appendToTraining({
      standardClassifierId: Number(event?.target?.value),
      trainingTypeClassifierId: null,
    })
    this.setStates({ changed: true })
  }

  private getStripedHTMLContent(content: string, limit: number = 140): string {
    const text = content.replace(/(<([^>]+)>)/gi, ' ')

    if (text.length > limit) {
      return `${text.slice(0, limit)}...`
    }

    return text
  }
}
