











































import { Component, Prop, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import AbstractField from '@/components/form/fields/AbstractField.vue'

@Component
export default class DatePickerField extends AbstractField {
  @Prop({ default: false }) private readonly isRange!: boolean
  @Prop({ default: null }) private readonly minDate!: Date

  initialValue: any = null

  masks = {
    input: 'YYYY-MM-DD',
  }

  @Watch('value') private onExternalDateChange(newValue: string): void {
    this.initialValue = new Date(newValue)
  }

  beforeMount(): void {
    if (this.isRange && this.model && this.value) {
      const [start, end] = this.model.split(',')

      const value = {
        start: new Date(start),
        end: new Date(end),
      }

      this.initialValue = value

      return
    }

    this.initialValue = this.model ? new Date(this.model) : null
  }

  onDatePickerChange(date: any): void {
    if (this.isRange) {
      const start = DateTime.fromJSDate(date.start).toFormat('yyyy-MM-dd')

      const end = DateTime.fromJSDate(date.end).toFormat('yyyy-MM-dd')

      this.emitChange(`${start},${end}`)

      return
    }

    this.emitChange(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'))
  }

  getValue(inputValue: any): string {
    if (this.isRange) {
      if (inputValue.start) return `${inputValue.start} - ${inputValue.end}`

      return ''
    }

    return inputValue
  }
}
