
























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import PageHeader from '@/components/PageHeader.vue'
import Pagination from '@/components/Pagination.vue'
import TrainingCard from '@/components/TrainingCard.vue'
import TrainingFilter from '@/components/form/trainings/TrainingFilter.vue'
import TrainingService from '@/services/TrainingService'
import ITrainingLocationDate from '@/interfaces/training/TrainingLocationDateInterface'
import TrainingLocationDate from '@/classes/training/training-location-date'
import Classifier from '@/classes/training/classifier'
import SelectOption from '@/interfaces/SelectOption'
import Filter from '@/classes/filter'
import Search from '@/components/Search.vue'
import EmptyList from '@/components/EmptyList.vue'
import scrollToRef from '@/helpers/scroll-to-ref-helper'
import TrainingData from '@/classes/training/training'

@Component({
  components: { EmptyList, Search, TrainingFilter, TrainingCard, Pagination, PageHeader, Breadcrumbs },
})
export default class Trainings extends Vue {
  private searchTerm: string = ''
  private page: number = 1
  private loading: boolean = true
  private industryOptions: Classifier[] = []
  private standardOptions: Classifier[] = []
  private countryOptions: string[] = []
  private trainingDates: TrainingLocationDate[] = []
  private meta: any = null
  private topTrainings: TrainingData[] = []
  private topLoading: boolean = true

  private debouncedSearch = _.debounce(this.getTrainingDates, 400)

  private filters: Record<string, Filter> = {
    industry: new Filter({ field: 'training-industry_classifier_id', value: '' }),
    standard: new Filter({ field: 'training-standard_classifier_id', value: '' }),
    from: new Filter({ field: 'from', modifier: 'between' }),
    location: new Filter({ field: 'trainingLocation-country', modifier: 'like', value: '' }),
  }

  @Watch('searchTerm') private onSearchChange(): void {
    this.page = 1
  }

  async created(): Promise<void> {
    this.industryOptions = await TrainingService.getClassifiers('get-industry-list')
    this.parseQueryParams()
    await this.getTrainingDates()
    this.countryOptions = await TrainingService.getCountryFilterList()
    await this.getTopTrainingDates()
  }

  async getTopTrainingDates(): Promise<void> {
    this.topLoading = true

    await TrainingService.getTopTrainings()
      .then((response: TrainingData[]) => {
        this.topTrainings = response
      })
      .finally(() => {
        this.topLoading = false
      })
  }

  async getTrainingDates(): Promise<void> {
    this.loading = true

    await TrainingService.getTrainingActiveList(this.page, this.searchTerm, this.filters)
      .then((response: any) => {
        this.trainingDates = response.data.map((training: ITrainingLocationDate) => new TrainingLocationDate(training))
        this.meta = response.meta

        this.loading = false
      })
      .finally(() => {
        this.loading = false
      })
  }

  async getStandards(id: number | string): Promise<void> {
    const { standardFilter }: any = this.$refs
    this.$set(this.filters.standard, 'value', '')

    if (standardFilter) standardFilter.inputValue = ''

    await this.debouncedSearch()

    if (id === '' || id === null) {
      this.standardOptions = []

      return
    }
    this.standardOptions = await TrainingService.getClassifiersByParent(id)
  }

  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm

    this.scrollToResults()
    this.getTrainingDates()
  }

  onPageChange(): void {
    this.getTrainingDates()
    this.scrollToResults()
  }

  private scrollToResults() {
    const trainingFilterRef: any = this.$refs.trainingFilter
    trainingFilterRef.$el.scrollIntoView({ behavior: 'smooth' })
  }

  private onPageInput(id: string): void {
    this.loading = true

    scrollToRef(id)
  }

  private parseQueryParams(): void {
    const { query } = this.$router.currentRoute

    if (query.q) this.searchTerm = query.q.toString()

    if (query.industry) {
      const classifier = this.industryOptions.find((industry: Classifier) => industry.code === query.industry)

      if (classifier) this.filters.industry.value = classifier.id.toString()
    }
  }

  private clearAllFilters(): void {
    this.$set(this.filters.industry, 'value', '')
    this.$set(this.filters.standard, 'value', '')
    this.$set(this.filters.from, 'value', '')
    this.$set(this.filters.location, 'value', '')

    const { industryFilter, locationFilter, standardFilter, dateFilter }: any = this.$refs

    if (industryFilter) industryFilter.inputValue = ''

    if (locationFilter) locationFilter.inputValue = ''

    if (standardFilter) standardFilter.inputValue = ''

    if (dateFilter) dateFilter.initialValue = ''

    this.onSearch('')

    this.getTrainingDates()
  }

  get searchSuggestions(): string[] {
    return this.topTrainings.map((trainingDate: TrainingData) => trainingDate.title)
  }

  get standardSelections(): SelectOption[] {
    return this.standardOptions.map((industry: Classifier) => ({ value: industry.id, text: industry.title }))
  }

  get industrySelections(): SelectOption[] {
    return this.industryOptions.map((industry: Classifier) => ({ value: industry.id, text: industry.title }))
  }

  get countrySelections(): SelectOption[] {
    return this.countryOptions.map((option: string) => ({ value: option, text: option }))
  }
}
