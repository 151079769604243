import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import wysiwyg from 'vue-wysiwyg'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/assets/scss/app.scss'
import setupPlugins from '@/setup/setup-plugins'

Vue.config.productionTip = false
Vue.use(setupPlugins)
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY || '' })
Vue.use(wysiwyg)

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
