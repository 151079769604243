var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile-training-table__wrap"},[_c('div',{staticClass:"table-responsive",attrs:{"id":"jobPositionTable"}},[_c('b-table',{staticClass:"profile-training-table",attrs:{"responsive":"","show-empty":"","fields":_vm.tableFields,"items":_vm.certificateData,"sort-by":_vm.tableProperties.sortBy,"sort-desc":_vm.tableProperties.order === 'desc'},scopedSlots:_vm._u([{key:"cell(industry_classifier)",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value.title))]}},{key:"cell(standard_classifier)",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value.title))]}},{key:"cell(training_type_classifier)",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value.title))]}},{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"nice-table--compact-item-center"},[_c('button',{staticClass:"button-bin",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteCertificate(item.id)}}},[_vm._v("Delete")])])]}}])})],1),(_vm.meta)?_c('Pagination',{attrs:{"meta":_vm.meta},on:{"change":_vm.onPageChange},model:{value:(_vm.tableProperties.currentPage),callback:function ($$v) {_vm.$set(_vm.tableProperties, "currentPage", $$v)},expression:"tableProperties.currentPage"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }