










import { Component, Vue } from 'vue-property-decorator'
import Routes from '@/router/routes'

@Component
export default class NotFound extends Vue {
  Routes = Routes
}
