























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import PageHeader from '@/components/PageHeader.vue'
import Pagination from '@/components/Pagination.vue'
import TrainingCenterCard from '@/components/TrainingCenterCard.vue'
import TrainingFilter from '@/components/form/trainings/TrainingFilter.vue'
import Filter from '@/classes/filter'
import Classifier from '@/classes/training/classifier'
import TrainingService from '@/services/TrainingService'
import SelectOption from '@/interfaces/SelectOption'
import TrainingCenterService from '@/services/TrainingCenterService'
import TrainingCenterListUser from '@/classes/user/training-center-list-user'
import Search from '@/components/Search.vue'
import EmptyList from '@/components/EmptyList.vue'
import scrollToRef from '@/helpers/scroll-to-ref-helper'
import TrainingCenter from '@/classes/user/training-center'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'

@Component({
  components: { EmptyList, Search, TrainingFilter, TrainingCenterCard, Pagination, PageHeader, Breadcrumbs },
})
export default class TrainingCenters extends Vue {
  private searchTerm: string = ''
  private page: number = 1
  private loading: boolean = true
  private topLoading: boolean = true
  private industryOptions: Classifier[] = []
  private countryOptions: string[] = []
  private trainingCenters: TrainingCenterListUser[] = []
  private meta: any = null
  private debouncedSearch = _.debounce(this.getTrainingCenters, 400)
  private filters: Record<string, Filter> = {
    industry: new Filter({ field: 'training_center-industry' }),
    location: new Filter({ field: 'country', modifier: 'like' }),
  }
  private topTrainingCenters: TrainingCenter[] = []

  @Watch('searchTerm') private onSearchChange(): void {
    this.page = 1
  }

  async created(): Promise<void> {
    this.industryOptions = await TrainingService.getClassifiers('get-industry-list')
    this.countryOptions = await TrainingService.getCountryFilterList()
    await this.getTrainingCenters()
    await this.getTopTrainingCenters()
  }

  async getTopTrainingCenters(): Promise<void> {
    this.topLoading = true

    await TrainingCenterService.getTopTrainingCenters()
      .then((response: TrainingCenter[]) => {
        this.topTrainingCenters = response
      })
      .finally(() => {
        this.topLoading = false
      })
  }

  async getTrainingCenters(): Promise<void> {
    this.loading = true

    await TrainingCenterService.getTrainingCenters(this.page, this.searchTerm, this.filters)
      .then((response: IPaginatedResponse<TrainingCenterListUser[]>) => {
        this.trainingCenters = response.data
        this.meta = response.meta
      })
      .finally(() => {
        this.loading = false
      })
  }

  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm

    const trainingFilterRef: any = this.$refs.filter
    trainingFilterRef.$el.scrollIntoView({ behavior: 'smooth' })

    this.getTrainingCenters()
  }

  private onPageInput(id: string): void {
    this.loading = true

    scrollToRef(id)
  }

  get searchSuggestions(): string[] {
    return this.topTrainingCenters.map((trainingCenter: TrainingCenter) => trainingCenter.name)
  }

  get industrySelections(): SelectOption[] {
    return this.industryOptions.map((industry: Classifier) => ({ value: industry.id, text: industry.title }))
  }

  get countrySelections(): SelectOption[] {
    return this.countryOptions.map((option: string) => ({ value: option, text: option }))
  }
}
