

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { BvTableFieldArray } from 'bootstrap-vue/src/components/table'
import { IJobPositionCertificate } from '@/interfaces/JobPositionsInterface'
import { ITableProperties } from '@/interfaces/TableInterface'
import { IMeta } from '@/interfaces/ResponseInterface'
import Pagination from '@/components/Pagination.vue'

@Component({
  components: { Pagination },
})
export default class CertificatesTable extends Vue {
  @Prop() certificateData!: IJobPositionCertificate[]
  @Prop() tableProperties!: ITableProperties
  @Prop() private meta!: IMeta

  private tableFields: BvTableFieldArray = [
    {
      key: 'from',
      label: 'Valid from',
      sortable: true,
    },
    {
      key: 'to',
      label: 'Valid until',
      sortable: true,
    },
    {
      key: 'industry_classifier',
      label: 'Industry',
      sortable: true,
    },
    {
      key: 'standard_classifier',
      label: 'Standard',
      sortable: true,
    },
    {
      key: 'training_type_classifier',
      label: 'Training type',
      sortable: true,
    },
    {
      key: 'id',
      label: '',
      sortable: false,
    },
  ]

  private onPageChange(page: number): void {
    this.tableProperties.currentPage = page
  }

  private deleteCertificate(id: number): void {
    this.$emit('deleteCertificate', id)
  }
}
