import { AxiosResponse } from 'axios'
import http from '@/classes/http'
import Student from '@/classes/student'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'
import IStudent from '@/interfaces/StudentInterface'
import SelectOption from '@/interfaces/SelectOption'

export default class OrderItemUserService {
  static refund(userId: number): Promise<void> {
    return http.put(`order-item-users/${userId}/refund`)
  }

  static getByTrainingLocationDate(
    trainingLocationDateUuid: string,
    query: any = {},
    perPage: number = 15
  ): Promise<IPaginatedResponse<Student[]>> {
    return http
      .get(`order-item-users/by-training-location-date/${trainingLocationDateUuid}`, {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: IStudent) => new Student(item)),
        meta: response.data.meta,
      }))
  }

  static getStatuses(): Promise<SelectOption[]> {
    return http.get('order-item-users/get-statuses').then((response: AxiosResponse) => {
      return Object.keys(response.data.data).map((value: string) => ({
        value,
        text: response.data.data[value],
      }))
    })
  }

  static setStatus(uuid: string, status: string): Promise<void> {
    return http.put(`order-item-users/${uuid}/set-status`, {
      status,
    })
  }
}
