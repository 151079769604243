









































































































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { BvTableFieldArray } from 'bootstrap-vue/src/components/table'
import _ from 'lodash'
import { AxiosError } from 'axios'
import { IMeta, IPaginatedResponse } from '@/interfaces/ResponseInterface'
import { ITableProperties, ITableSortChanged } from '@/interfaces/TableInterface'
import IConfirmDialog from '@/interfaces/ConfirmDialogInterface'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import SelectOption from '@/interfaces/SelectOption'
import EmptyList from '@/components/EmptyList.vue'
import Pagination from '@/components/Pagination.vue'
import Search from '@/components/Search.vue'
import AdminPanelService from '@/services/AdminPanelService'
import { UserType } from '@/classes/user/user'
import ListUser from '@/classes/admin/list-user'
import scrollToRef from '@/helpers/scroll-to-ref-helper'

const Global = namespace('Global')

const Auth = namespace('Auth')

enum UsersFilter {
  search = 'q',
  type = 'type',
}

@Component({
  components: { Search, EmptyList, Pagination },
})
export default class AdminUserList extends Vue {
  private loading: boolean = false
  private users: any = []
  private meta: IMeta | any = {}

  public tableProperties: ITableProperties = {
    currentPage: 1,
    perPage: 15,
    filters: {
      search: '',
      type: UserType.user,
    },
  }

  @Global.Action
  private showConfirmDialog!: (confirmDialog: IConfirmDialog) => void

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Auth.Action
  private logInAsUser!: (uuid: string) => void

  public created(): void {
    this.parseQueryParams()
    this.loadUsersList()

    this.$watch(() => this.$route, this.loadUsersList)
  }

  private loadUsersList(): void {
    this.loading = true

    const { query } = this.$router.currentRoute

    const userType = query.type ? String(query.type) : UserType.user

    AdminPanelService.usersListByType(query, userType).then((response: IPaginatedResponse<ListUser[]>) => {
      this.users = response.data
      this.meta = response.meta
      this.loading = false
    })
  }

  private onTableSortChange(event: ITableSortChanged): void {
    this.tableProperties.sortBy = event.sortBy
    this.tableProperties.order = event.sortDesc ? 'desc' : 'asc'

    this.changeRouteByProperties()
  }

  private onPageChange(page: number): void {
    this.tableProperties.currentPage = page

    this.changeRouteByProperties()
  }

  private onSearch(search: string): void {
    this.tableProperties.filters.search = search

    this.changeRouteByProperties()
  }

  private changeRouteByProperties(): void {
    const { name, query, params }: any = this.$router.currentRoute

    const { currentPage, sortBy, order, filters } = this.tableProperties

    const routeQuery = {
      ...query,
      page: currentPage,
      sort_by: sortBy,
      order,
    }

    if (filters.search !== '') routeQuery[UsersFilter.search] = filters.search
    else delete routeQuery[UsersFilter.search]

    if (filters.type) routeQuery[UsersFilter.type] = filters.type
    else delete routeQuery[UsersFilter.type]

    this.$router
      .replace({
        name,
        params,
        query: routeQuery,
      })
      .catch(() => {})
  }

  private onPageInput(id: string): void {
    this.loading = true

    scrollToRef(id)
  }

  private parseQueryParams(): void {
    const { query } = this.$router.currentRoute

    this.tableProperties.currentPage = Number(_.get(query, 'page', 1))
    this.tableProperties.filters.search = _.get(query, UsersFilter.search, '')
    this.tableProperties.filters.sortBy = _.get(query, 'sort_by')
    this.tableProperties.filters.order = _.get(query, 'order')
    this.tableProperties.filters.type = _.get(query, UsersFilter.type, UserType.user)
  }

  public getToggleOrderEvent(order: string): any {
    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'asc') {
      return {
        sortBy: '',
        sortDesc: false,
      }
    }

    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'desc') {
      return {
        sortBy: order,
        sortDesc: false,
      }
    }

    return {
      sortBy: order,
      sortDesc: true,
    }
  }

  private getFilterOrderClass(order: string): string {
    if (this.tableProperties.sortBy === order) {
      return this.tableProperties.order || ''
    }

    return ''
  }

  private getFields(): BvTableFieldArray {
    const fields = [
      {
        key: 'name',
        label: 'Name',
        sortable: true,
      },
      {
        key: 'email',
        label: 'Email',
        sortable: true,
      },
      {
        key: 'phoneNumber',
        label: 'Phone number',
        sortable: false,
      },
      {
        key: 'isActive',
        label: 'Status',
        sortable: true,
      },
      {
        key: 'uuid',
        label: '',
      },
      {
        key: 'approve',
        label: '',
      },
      {
        key: 'logInAs',
        label: '',
      },
    ]

    if (this.tableProperties.filters.type === UserType.company) {
      fields.unshift({
        key: 'companyName',
        label: 'Company name',
        sortable: false,
      })
    }

    if (this.tableProperties.filters.type === UserType.trainingCenter) {
      fields.unshift({
        key: 'trainingCenterName',
        label: 'Training center name',
        sortable: false,
      })
    }

    return fields
  }

  get userTypeSelections(): SelectOption[] {
    return [
      { value: UserType.user, text: 'User' },
      { value: UserType.company, text: 'Company' },
      { value: UserType.trainingCenter, text: 'Training Center' },
    ]
  }

  private changeStatus(uuid: string, isActive: boolean): void {
    const action = isActive ? 'Disable' : 'Enable'
    this.showConfirmDialog({
      text: `Are you sure you want to change status of this user?`,
      confirmText: action,
      cancelText: 'Cancel',
      confirmAction: () => {
        return AdminPanelService.setUserActivityStatus(uuid, isActive)
          .then(() => {
            this.loadUsersList()
            this.showSnackbar({
              type: SnackbarType.success,
              text: 'User status changed!',
            })
          })
          .catch((error: AxiosError) =>
            this.showSnackbar({
              type: SnackbarType.error,
              text: `Something wrong, user status was not changed. More details: ${error.response?.data.message}`,
            })
          )
      },
    })
  }

  private approveTrainingCenter(uuid: string): void {
    this.showConfirmDialog({
      confirmText: 'Approve',
      cancelText: 'Cancel',
      confirmAction: () => {
        return AdminPanelService.setTrainingCenterApprovedStatus(uuid)
          .then(() => {
            this.loadUsersList()
            this.showSnackbar({
              type: SnackbarType.success,
              text: 'Selected training was approved!',
            })
          })
          .catch((error: AxiosError) =>
            this.showSnackbar({
              type: SnackbarType.error,
              text: `Something wrong, training was not approved. More details: ${error.response?.data.message}`,
            })
          )
      },
    })
  }
}
