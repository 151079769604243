import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { AxiosError, AxiosResponse } from 'axios'
import parsedErrorResponseData from '@/helpers/error-response-parser'
import CartService from '@/services/CartService'
import { cartMutations } from '@/store/mutations/cart.mutations'
import {
  ICartCheckoutResponseData,
  ICartEmployee,
  ICartResponseData,
  ICartSessionResponseData,
  ICartsWithEmployees,
  IOrderPayResponseData,
  IPaymentPayloadData,
  IUpdateCartEmployeesData,
} from '@/interfaces/CartInterface'
import cartHelper from '@/helpers/cart-helper'
import { UserType } from '@/classes/user/user'

const storedCart = cartHelper.getCart()

@Module({ namespaced: true })
class Cart extends VuexModule {
  public products = storedCart?.products || []
  public totalPrice = storedCart?.totalPrice || '0'
  public totalDiscount = storedCart?.totalDiscount || '0'
  public totalVat = storedCart?.totalVat || '0'
  public employeesOfCart = storedCart?.employees || []

  @Mutation
  public updateCartInfo(data: ICartSessionResponseData): void {
    this.totalDiscount = data.discount
    this.totalVat = data.vat

    // On Company user cart we still need to show item price if no employees selected
    const existsWithNoEmployees = !!data.cart_items.find((cartItem) => cartItem.count === 0)

    const userData = localStorage.getItem('user')

    const userType = userData ? JSON.parse(userData).userType : ''

    if (userType === UserType.company && existsWithNoEmployees) {
      this.products = data.cart_items.map((item) => {
        return {
          ...item,
          total: item.count ? item.total : item.price,
        }
      })
      // Temporary workaround while waiting for changes in back-end
      this.totalPrice = Number(
        this.products.reduce((total, cartItem) => parseFloat(cartItem.total.replace(/,/g, '')) + total, 0)
      ).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    } else {
      this.products = data.cart_items
      this.totalPrice = data.total
    }

    cartHelper.setCart(this.products, this.totalPrice, [], this.totalDiscount, this.totalVat)
  }

  @Mutation
  public setCartEmployeesList(employees: ICartsWithEmployees): void {
    this.employeesOfCart = employees
    cartHelper.setCart(this.products, this.totalPrice, employees, this.totalDiscount, this.totalVat)
  }

  @Mutation
  public clearCartInfo(): void {
    this.products = []
    this.totalPrice = '0'
    this.totalDiscount = '0'
    this.totalVat = '0'
    this.employeesOfCart = []
    cartHelper.clearCart()
  }

  @Mutation
  public updateOrderedProducts(): void {
    this.products = []
    this.totalPrice = '0'
    this.totalDiscount = '0'
    this.totalVat = '0'
    cartHelper.clearCart()
  }

  @Action({ rawError: true })
  async addItemToCart(trainingUuid: string): Promise<ICartResponseData> {
    try {
      const response: AxiosResponse<ICartResponseData> = await CartService.addToCart(trainingUuid)

      return response.data
    } catch (error) {
      throw parsedErrorResponseData(error as AxiosError)
    }
  }

  @Action({ rawError: true })
  async setEmployeesList(data: ICartsWithEmployees): Promise<void> {
    this.context.commit(cartMutations.SET_CART_EMPLOYEES_LIST, data)
  }

  @Action({ rawError: true })
  async removeItemFromCart(cartItem: ICartResponseData): Promise<void> {
    try {
      await CartService.removeItemFromCart(cartItem.uuid)
    } catch (error) {
      throw parsedErrorResponseData(error as AxiosError)
    }
  }

  @Action({ rawError: true })
  async getEmployeesByCartId(uuid: string): Promise<ICartEmployee[]> {
    try {
      const response: AxiosResponse<ICartEmployee[]> = await CartService.getEmployeesByCartId(uuid)

      return response.data
    } catch (error) {
      throw parsedErrorResponseData(error as AxiosError)
    }
  }

  @Action({ rawError: true })
  async updateCartEmployeesList(data: IUpdateCartEmployeesData): Promise<void> {
    try {
      await CartService.updateCartEmployeesList(data)
    } catch (error) {
      throw parsedErrorResponseData(error as AxiosError)
    }
  }

  @Action({ rawError: true })
  async fetchCart(): Promise<ICartResponseData[]> {
    try {
      const response: AxiosResponse<ICartSessionResponseData> = await CartService.fetchCartContent()

      if (response.data.cart_items.length) {
        this.context.commit(cartMutations.UPDATE_CART_INFO, response.data)
      } else {
        this.context.commit(cartMutations.CLEAR_CART_INFO, response.data)
      }

      return response.data.cart_items
    } catch (error) {
      throw parsedErrorResponseData(error as AxiosError)
    }
  }

  @Action({ rawError: true })
  async checkout(): Promise<ICartCheckoutResponseData> {
    try {
      const response: AxiosResponse<ICartCheckoutResponseData> = await CartService.checkout()

      return response.data
    } catch (error) {
      throw parsedErrorResponseData(error as AxiosError)
    }
  }

  @Action({ rawError: true })
  async paymentCallback(urlParams: string): Promise<ICartCheckoutResponseData> {
    try {
      const response: AxiosResponse<ICartCheckoutResponseData> = await CartService.paymentCallback(urlParams)

      return response.data
    } catch (error) {
      throw parsedErrorResponseData(error as AxiosError)
    }
  }

  @Action({ rawError: true })
  async orderPay(data: IPaymentPayloadData): Promise<IOrderPayResponseData> {
    try {
      const response: AxiosResponse<IOrderPayResponseData> = await CartService.orderPay(data)

      return response.data
    } catch (error) {
      throw parsedErrorResponseData(error as AxiosError)
    }
  }

  @Action({ rawError: true })
  async clearCart(): Promise<void> {
    this.context.commit(cartMutations.CLEAR_CART_INFO)
  }

  get cartItems(): ICartResponseData[] {
    return this.products
  }

  get cartEmployees(): ICartsWithEmployees {
    return this.employeesOfCart
  }

  get cartTotalPrice(): string {
    return this.totalPrice
  }

  get cartTotalDiscount(): string {
    return this.totalDiscount
  }

  get cartTotalVat(): string {
    return this.totalVat
  }

  get cartItemCount(): number {
    return this.products.length
  }

  get cartTotalUsersCount(): number {
    return this.products.reduce((total, cartItem) => cartItem.count + total, 0)
  }
}

export default Cart
