















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Cta extends Vue {
  @Prop() private heading!: string
  @Prop() private text!: string
  @Prop() private button!: string
  @Prop() private link!: string
  @Prop({ default: 'grey' }) private overBg!: string
}
