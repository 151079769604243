





























































































































































































































import { Component, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import { namespace } from 'vuex-class'
import { LocationTypesData } from '@/configs/training-steps'
import TrainingLocationDate from '@/classes/training/training-location-date'
import AbstractField from '@/components/form/fields/AbstractField.vue'
import TrainingLocationInfo from '@/components/TrainingLocationInfo.vue'
import ITrainingDataForm, {
  ITrainingFormLocation,
  ITrainingFormLocationDate,
} from '@/interfaces/form-interfaces/TrainingDataFormInterface'
import { ISteps } from '@/store/modules/training.module'

const Training = namespace('Training')

@Component({ components: { TrainingLocationInfo } })
export default class PricesSeatsField extends AbstractField {
  LocationTypesData = LocationTypesData
  localIndex: number = 0
  inputData: InputData = {
    price: 0,
    seats: 0,
    vat: 0,
  }

  @Training.Getter
  private training!: ITrainingDataForm

  @Training.Mutation
  private setTrainingLocationDates!: (data: ITrainingFormLocationDate) => void

  @Training.Action
  private backupData!: () => void

  @Training.Action
  private setStates!: (states: any) => void

  @Training.Action
  private addTrainingLocationDate!: (index: any) => void

  @Training.Action
  private deleteTrainingLocationDateServices!: () => void

  @Training.Mutation
  private setStepData!: (stepData: ISteps) => void

  created(): void {
    if (!this.model) this.model = []

    this.training.trainingLocations.forEach((location: ITrainingFormLocation) => {
      if (this.filterDates(location.uuid).length > 0) return

      this.addTrainingLocationDate({ training_location_uuid: location.uuid, index: this.localIndex })
    })

    this.backupData()
    this.setStates({ changed: false })

    this.$root.$on('resetStepData', (step: number) => {
      if (step === 6) {
        this.clearData()
      }
    })
  }

  @Watch('model', { deep: true })
  private onDatesChange(): void {
    this.setTrainingLocationDates(this.model)
    this.setStates({ changed: true })
  }

  clearData(): void {
    this.deleteTrainingLocationDateServices()
    this.inputData = {
      price: null,
      seats: null,
      vat: null,
    }
    this.applyValue('price', null)
    this.applyValue('vat', null)
    this.applyValue('seats', null)
    this.setStepData({
      activeStep: 6,
      completedStep: 6,
    })
  }

  filterDates(locationUuid: string | undefined): any {
    return this.model.filter((date: ITrainingFormLocationDate) => date.trainingLocationUuid === locationUuid)
  }

  applyValue(field: string, value: number | null): void {
    _.each(this.model, (date: TrainingLocationDate) => {
      _.set(date, field, value)
    })
  }
}

interface InputData {
  price: number | null
  seats: number | null
  vat: number | null
}
