
































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import TrainingCard from '@/components/TrainingCard.vue'
import { ICartResponseData, ICartsWithEmployees } from '@/interfaces/CartInterface'
import TrainingLocationDate from '@/classes/training/training-location-date'
import Routes from '@/router/routes'

const Cart = namespace('Cart')

@Component({
  components: { TrainingCard, Breadcrumbs },
})
export default class Register extends Vue {
  private cartTrainings: TrainingLocationDate[] = []
  private loading: boolean = false
  private email: string = ''
  private totalPrice: string = ''
  private discount: string = ''
  private vat: string = ''
  private orderId: string = ''
  private orderEmployees: ICartsWithEmployees = []
  private orderItems: ICartResponseData[] = []
  Routes = Routes

  @Cart.Getter
  private cartItems!: ICartResponseData[]

  @Cart.Getter
  private cartEmployees!: ICartsWithEmployees

  @Cart.Action
  private clearCart!: () => Promise<void>

  async mounted(): Promise<void> {
    if (this.$route.params.data) {
      const orderData = JSON.parse(this.$route.params.data)

      this.email = orderData.email
      this.totalPrice = orderData.totalPrice
      this.orderId = orderData.orderId
      this.discount = orderData.discount
      this.vat = orderData.vat

      this.orderItems = [...this.cartItems]

      this.orderEmployees = { ...this.cartEmployees }

      this.cartTrainings = this.orderItems.map((cartItem) => new TrainingLocationDate(cartItem.training_location_date))

      await this.clearCart()
    }
  }

  private getCartItem(trainingUuid: string): ICartResponseData | undefined {
    return this.orderItems.find((item) => item.training_location_date.uuid === trainingUuid)
  }
}
