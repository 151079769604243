























import { Component, Prop, Vue } from 'vue-property-decorator'
import { NewsInterface } from '@/interfaces/NewsInterface'

@Component
export default class NewsItem extends Vue {
  @Prop() private newsData!: NewsInterface
}
