






















































































import { Component } from 'vue-property-decorator'
import { AxiosError } from 'axios'
import { namespace } from 'vuex-class'
import ClassifierService from '@/services/ClassifierService'
import IClassifierOption from '@/interfaces/ClassifierOptionInterface'
import Classifier from '@/classes/training/classifier'
import { IJobPositionCertificate } from '@/interfaces/JobPositionsInterface'
import ProfileEditAbstract from '@/components/profile/edit/ProfileEditAbstract.vue'
import SelectField from '@/components/form/fields/SelectField.vue'
import DatePickerField from '@/components/form/fields/DatePickerField.vue'
import CertificateService from '@/services/certificateService'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'

const Global = namespace('Global')

@Component({
  components: { DatePickerField },
})
export default class AddCertificateModal extends ProfileEditAbstract {
  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  private industriesList: IClassifierOption[] = []
  private standardsList: IClassifierOption[] = []
  private trainingTypesList: IClassifierOption[] = []

  private certificatePayload: IJobPositionCertificate = {
    industry_classifier_id: null,
    standard_classifier_id: null,
    training_type_classifier_id: null,
    from: '',
    to: '',
    has_term_and_conditions: true,
  }

  get allowPost(): boolean {
    return !(
      this.certificatePayload.industry_classifier_id !== null &&
      this.certificatePayload.standard_classifier_id !== null &&
      this.certificatePayload.training_type_classifier_id !== null
    )
  }

  private mapCertificateClassifiers(data: Classifier[]): IClassifierOption[] {
    return data.map((item: Classifier) => ({
      value: item.id,
      text: item.title,
      industryId: item.industryId || null,
      standardId: item.standardId || null,
    }))
  }

  get filteredStandardList(): IClassifierOption[] {
    return this.standardsList.filter((item) => {
      return this.certificatePayload.industry_classifier_id === item.industryId
    })
  }

  get filteredTrainingList(): IClassifierOption[] {
    return this.trainingTypesList.filter((item) => {
      return (
        this.certificatePayload.industry_classifier_id === item.industryId &&
        this.certificatePayload.standard_classifier_id === item.standardId
      )
    })
  }

  private resetUpdatedSelect(resetStandard: boolean = false, resetType: boolean = false): void {
    const standardSelect = this.$refs.standardSelect as SelectField

    const trainingSelect = this.$refs.trainingSelect as SelectField

    if (resetStandard) {
      standardSelect.select(null)
    }

    if (resetType) {
      trainingSelect.select(null)
    }
  }

  private discardCertificate(): void {
    this.certificatePayload = {
      industry_classifier_id: null,
      standard_classifier_id: null,
      training_type_classifier_id: null,
      from: '',
      to: '',
      has_term_and_conditions: true,
    }
    this.$bvModal.hide('addCertificate')
  }

  private postCertificate(): void {
    CertificateService.addCertificate(this.certificatePayload)
      .then(() => {
        this.showSnackbar({
          type: SnackbarType.success,
          text: 'Successfully added a certificate',
        })
        this.$emit('certificateAdded')
        this.discardCertificate()
      })
      .catch((error: AxiosError) => {
        this.setErrorsFromResponse(error)
        this.showSnackbar({
          type: SnackbarType.error,
          text: 'Certificate error',
        })
      })
  }

  created(): void {
    ClassifierService.getIndustryList({}, 9999).then((response) => {
      this.industriesList = this.mapCertificateClassifiers(response.data)
    })
    ClassifierService.getStandardList({}, 9999).then((response) => {
      this.standardsList = this.mapCertificateClassifiers(response.data)
    })
    ClassifierService.getTrainingTypeList({}, 9999).then((response) => {
      this.trainingTypesList = this.mapCertificateClassifiers(response.data)
    })
  }
}
