


















import { Component, Vue } from 'vue-property-decorator'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Routes from '@/router/routes'

@Component({
  components: { Breadcrumbs },
})
export default class PassCreateSuccess extends Vue {
  Routes = Routes
}
