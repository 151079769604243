export default function scrollToRef(id: any): void {
  if (!id) return

  const element = document.getElementById(id)

  if (element) {
    const top = element.offsetTop
    window.scrollTo(0, top)
  }
}
