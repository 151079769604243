









































































import { Component, Vue } from 'vue-property-decorator'
import Routes from '@/router/routes'

@Component
export default class JoinAs extends Vue {
  Routes = Routes

  goTo(name: string): void {
    this.$router.push({ name }).catch(() => {})
  }
}
