








import { Component, Vue } from 'vue-property-decorator'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import PageHeader from '@/components/PageHeader.vue'
import CreateTraining from '@/components/training-create/CreateTraining.vue'

@Component({
  components: {
    CreateTraining,
    PageHeader,
    Breadcrumbs,
  },
})
export default class NewTraining extends Vue {}
