var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"training-new",attrs:{"id":"new-training-block"}},[_c('div',{staticClass:"training-new__steps"},[_c('b-container',[_c('ul',{staticClass:"d-none d-md-flex row no-gutters flex-md-row flex-column"},_vm._l((Object.keys(_vm.StepData)),function(step){return _c('li',{key:_vm.StepData[step].index,staticClass:"col"},[_c('span',{staticClass:"h-100 no-select",class:{
              'can-select': _vm.isNotCompleted(step) || _vm.isCompleted(step),
              active: _vm.tabIsActive(step),
              completed: _vm.isCompleted(step),
              incomplete: _vm.isNotCompleted(step),
            },on:{"click":function($event){_vm.moveToStep(Number(step))}}},[_vm._v(" "+_vm._s(step)+". "+_vm._s(_vm.StepData[step].title)+" ")])])}),0)]),_c('Swiper',{ref:"swiper",staticClass:"d-flex d-md-none sliding-list",attrs:{"options":_vm.mobileStepsSwiperOptions}},_vm._l((Object.keys(_vm.StepData)),function(step){return _c('SwiperSlide',{key:_vm.StepData[step].index,staticClass:"sliding-list-item"},[_c('div',{class:{
            'can-select': _vm.isNotCompleted(step) || _vm.isCompleted(step),
            active: _vm.tabIsActive(step),
            completed: _vm.isCompleted(step),
            incomplete: _vm.isNotCompleted(step),
          },on:{"click":function($event){_vm.moveToStep(Number(step))}}},[_vm._v(" "+_vm._s(step)+". "+_vm._s(_vm.StepData[step].title)+" ")])])}),1)],1),_c('b-container',[_c('div',{staticClass:"training-new__body"},[_c('div',{staticClass:"training-new__body-header"},[_c('div',[_c('span',{staticClass:"h4 mb-0 bold"},[_vm._v(" "+_vm._s(_vm.StepData[_vm.training.activeStep].heading)+" ")])])]),_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"training-new__body-content"},[_c(_vm.StepData[_vm.training.activeStep].component,{tag:"component",attrs:{"data":_vm.training}})],1),_c('div',{staticClass:"training-new__body-footer"},[_c('Button',{attrs:{"text":"Cancel","additional-class":"button-cancel button-cancel--white d-none d-md-block"},on:{"click":_vm.resetAllSettings}}),_c('div',{staticClass:"training-new__body-footer__right",class:{ 'is-first': _vm.StepData[_vm.training.activeStep].isFirst }},[(!_vm.StepData[_vm.training.activeStep].isFirst)?_c('div',{staticClass:"button button--back",on:{"click":function($event){return _vm.stepBackwards(_vm.training.activeStep)}}},[_vm._v(" Back ")]):_vm._e(),(!_vm.StepData[_vm.training.activeStep].isLast)?_c('b-btn',{staticClass:"button button--white",attrs:{"disabled":!_vm.StepData[_vm.training.activeStep].isValid(_vm.training)},on:{"click":function($event){return _vm.stepForward(_vm.training.activeStep)}}},[_vm._v(" Next ")]):_vm._e(),(_vm.StepData[_vm.training.activeStep].isLast)?_c('b-btn',{staticClass:"button button--green",attrs:{"disabled":!_vm.allStepsAreValid},on:{"click":_vm.startTrainingPublishing}},[_vm._v(" Publish ")]):_vm._e()],1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }