


































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import ForgotPasswordModal from '@/components/account/ForgotPasswordModal.vue'
import { ILoginData, ILoginResponse } from '@/interfaces/AuthInterface'
import Routes from '@/router/routes'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import { ICartResponseData } from '@/interfaces/CartInterface'
import User from '@/classes/user/user'
import tokenHelper from '@/helpers/token-helper'
import http from '@/classes/http'

const Global = namespace('Global')

const Cart = namespace('Cart')

const Auth = namespace('Auth')

@Component({
  components: { ForgotPasswordModal, Breadcrumbs },
})
export default class Login extends Vue {
  Routes = Routes
  private passwordRevealed: boolean = false
  private successful: boolean = false
  private submitted: boolean = false
  private routeAfterLogin = Routes.PROFILE

  private showPassword(): void {
    this.passwordRevealed = !this.passwordRevealed
  }

  private user: ILoginData = { email: '', password: '' }
  private loading: boolean = false
  private message: string = ''

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Cart.Action
  private fetchCart!: () => Promise<ICartResponseData[]>

  @Cart.Action
  private addItemToCart!: (trainingUuid: string) => Promise<ICartResponseData>

  @Auth.Getter
  private isLoggedIn!: boolean

  @Auth.Action
  private login!: (data: ILoginData) => Promise<ILoginResponse>

  @Auth.Action
  private getMe!: () => Promise<User>

  @Auth.Getter
  private userIsTrainingCenter!: boolean

  @Auth.Getter
  private userIsAdmin!: boolean

  created(): void {
    if (this.isLoggedIn) {
      this.$router.push({ name: Routes.PROFILE })
    }

    if (localStorage.getItem('cartItem')) localStorage.removeItem('cartItem')
  }
  async submit(): Promise<void> {
    this.message = ''
    this.submitted = true
    this.loading = true

    try {
      const loginResponseData = await this.login(this.user)

      const userData = await this.getMe()

      tokenHelper.setUser(loginResponseData.token, userData.type).then(async () => {
        // Logging in after add to cart action
        let error = null

        if (!this.userIsTrainingCenter && this.$route.params.data) {
          const trainingData = JSON.parse(this.$route.params.data)

          if (trainingData.trainingUuid) {
            this.routeAfterLogin = Routes.CART
            await this.addItemToCart(trainingData.trainingUuid).catch((e: any) => {
              error = e
              this.showSnackbar({
                type: SnackbarType.error,
                text: error.errors.training_location_date_uuid.join(';\n') || 'Adding to cart failed',
              })
            })
          }
        }

        if (!this.userIsTrainingCenter) await this.fetchCart()

        if (this.userIsAdmin) this.routeAfterLogin = Routes.ADMIN_PANEL

        this.successful = true

        await this.$router.push({ name: this.routeAfterLogin })

        if (!error) {
          this.showSnackbar({
            type: SnackbarType.success,
            text: 'Successfully logged in',
          })
        }

        this.$root.$emit('checkForCompleteProfileData')
      })
    } catch (error: any) {
      this.message = error.message
      this.loading = false
      this.$validator.errors.clear()
      this.scrollToErrorMessage()

      if (error.status !== 422) return

      const errorFields = Object.keys(error.errors)
      errorFields.forEach((field: any) => {
        const errorString = error.errors[field].join(', ')
        this.$validator.errors.add({
          field,
          msg: errorString,
        })
      })
      this.successful = false
    }
  }

  loginVia(provider: string): void {
    http.get(`auth/${provider}`).then((response: any) => {
      localStorage.setItem('cartItem', this.$route.params.data)
      window.location.href = response.data.data.url
    })
  }

  private scrollToErrorMessage(): void {
    const element = document.getElementById('message')

    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}
