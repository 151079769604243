





















import { Component, Vue } from 'vue-property-decorator'
import Hero from '@/components/home/Hero.vue'
import IndustriesInfo from '@/components/home/IndustriesInfo.vue'
import JoinAs from '@/components/home/JoinAs.vue'
import Steps from '@/components/home/Steps.vue'
import NewTrainings from '@/components/home/NewTrainings.vue'
import Stories from '@/components/home/Stories.vue'
import Cta from '@/components/home/Cta.vue'
import Routes from '@/router/routes'

@Component({
  components: {
    Cta,
    Stories,
    NewTrainings,
    Steps,
    JoinAs,
    IndustriesInfo,
    Hero,
  },
})
export default class Home extends Vue {
  Routes = Routes

  goTo(name: string): void {
    this.$router.push({ name }).catch(() => {})
  }
}
