import { AxiosResponse } from 'axios'
import { DateTime } from 'luxon'
import http from '@/classes/http'
import { IPaginatedResponse } from '@/interfaces/ResponseInterface'
import IStudentResponse from '@/interfaces/StudentInterface'
import Student from '@/classes/student'
import { downloadFile } from '@/helpers/download-file'
import CompanyDetails from '@/classes/company-details'

export default class UserService {
  static getStudents(query: any, perPage: number = 15): Promise<IPaginatedResponse<Student[]>> {
    return http
      .get('users/students', {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: IStudentResponse) => new Student(item)),
        meta: response.data.meta,
      }))
  }

  static getStudentsByTrainingLocationDate(
    trainingLocationDateUuid: string,
    query: any = {},
    perPage: number = 15
  ): Promise<IPaginatedResponse<Student[]>> {
    return http
      .get(`users/students-by-training-location-date/${trainingLocationDateUuid}`, {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: IStudentResponse) => new Student(item)),
        meta: response.data.meta,
      }))
  }

  static generateStudentsReport(exportType: string, query: any = {}): Promise<void> {
    return http
      .get(`users/students/export/${exportType}`, {
        responseType: 'blob',
        params: {
          ...query,
        },
      })
      .then((response: any) =>
        downloadFile(response, `students-report-${DateTime.now().toFormat('yyyyLLdd-HHmm')}.${exportType}`)
      )
  }

  static getCompanyDetails(uuid: string): Promise<CompanyDetails> {
    return http
      .get(`users/${uuid}/company-details`)
      .then((response: AxiosResponse) => new CompanyDetails(response.data.data))
  }

  static getUserDetails(uuid: string): Promise<CompanyDetails> {
    return http
      .get(`users/${uuid}/cadet-details`)
      .then((response: AxiosResponse) => new CompanyDetails(response.data.data))
  }
}
