import { UserType } from '@/classes/user/user'
import ITrainingLocationDateResponse from '@/interfaces/training/TrainingLocationDateInterface'
import { IUserProfileResponse } from '@/interfaces/user/UserInterface'

export enum OrderStatusType {
  pending = 'pending',
  awaitingPayment = 'awaiting_payment',
  completed = 'completed',
  cancelled = 'cancelled',
}

// Cart

export interface ICartResponseData {
  uuid: string
  price: string
  discount: string
  total: string
  count: number
  vat: number
  cart_item_users: ICartItemUsers[]
  training_location_date: ITrainingLocationDateResponse
}

interface ICartItemUsers {
  cart_item_uuid: string
  uuid: string
}

export interface ICartItemUser {
  uuid: string
  name: string
  email: string
  email_verified: string
  type: UserType
  is_active: boolean
  user_profile: IUserProfileResponse
  has_term_and_conditions: boolean
  phone_number: string
}

export interface ICartSessionResponseData {
  id: number
  user_id: number
  price: string
  discount: string
  total: string
  vat: string
  cart_items: ICartResponseData[]
}

export interface ICartLocalStorage {
  products: ICartResponseData[]
  totalPrice: string
  totalDiscount: string
  totalVat: string
  employees: ICartsWithEmployees
}

export interface ICartEmployee {
  id: number
  status: string
  company_id: number
  cart_item_user_uuid: string | null
  employee: ICartItemUser
}

export interface IEmployeesAddItem {
  user_uuid: string
}

export interface IEmployeesRemoveItem {
  cart_item_user_uuid: string
}

export interface ICartEmployeesModalProp {
  cartEmployeeList: ICartEmployee[]
  cartItemUuid: string
  trainingUuid: string
}

export interface IUpdateCartEmployeesData {
  cartUuid: string
  add: Array<string>
  remove: Array<string>
}

export interface ICartsWithEmployees {
  [key: number]: ICartEmployee[]
}

// Order

export interface IOrderItemBase {
  uuid: string
  training_end_date: string
  price: string
  vat: string
  discount: number
  total: string
  total_per_unit: string
  total_per_unit_without_vat: string
  total_number: number
  count: number
  training_location_date: IOrderItemBaseTrainingLocationDate
}

interface IOrderItemBaseTrainingLocationDate {
  duration_in_days: number
  from: string
  is_refresh: boolean
  price: string
  seats: number
  to: string
  uuid: string
  vat: string
}

interface IOrderBase {
  uuid: string
  code: string
  price: string
  vat: string
  discount: number
  total: string
  status: OrderStatusType
}

interface IOrderItemUsers {
  uuid: number
}

interface IOrderAddress {
  uuid: string
  name: string
  email: string
  company: string | null
  company_code: string | null
  company_vat: string | null
  country: string | null
  city: string | null
  address: string | null
  address_complement: string | null
  phone_number: string | null
}

// Checkout

interface ICheckoutOrderItem extends IOrderItemBase {
  order_item_users: IOrderItemUsers[]
}

export interface ICartCheckoutResponseData extends IOrderBase {
  order_address: IOrderAddress
  order_items: ICheckoutOrderItem[]
}

export interface ICheckoutUser {
  name: string
  email: string
  company: string
  companyCode: string
  address: string
  city: string
  country: string
  phoneNumber: string
  companyVat: string
}

// Payment

export interface IPaymentPayloadData extends ICheckoutUser {
  orderUuid?: string
  card: ICreditCard
}

interface ICreditCard {
  number: number
  exp_month: number
  exp_year: number
  cvc: number
}

export interface IOrderPayResponseData extends IOrderBase {
  order_items?: IOrderItemBase[]
  order_address?: IOrderAddress
  url?: string
}
