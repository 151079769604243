











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PageHeader extends Vue {
  @Prop() private heading!: string
  @Prop() private tabs!: boolean
}
