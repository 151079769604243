export enum trainingMutations {
  SET_OLD_TRAINING = 'setOldTraining',
  SET_TRAINING = 'setTraining',
  SET_TRAINING_LOCATIONS = 'setTrainingLocations',
  SET_TRAINING_LOCATION_DATES = 'setTrainingLocationDates',
  SET_TRAINING_LOCAITON_DATE_SERVICES = 'setTrainingLocationDateServices',
  SET_DATA_STATE = 'setState',
  SET_INITIALIZED_STATE = 'setInitialized',
  SET_STEPS = 'setStepData',
  SET_LOADING = 'setLoading',
}
