



















import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Snackbar from '@/components/Snackbar.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import User from './classes/user/user'
import tokenHelper from './helpers/token-helper'
import UserTitle from './configs/user-title'

const Auth = namespace('Auth')

@Component({
  components: { ConfirmDialog, Snackbar, Header, Footer },
})
export default class App extends Vue {
  tokenHelper = tokenHelper
  UserTitle = UserTitle
  @Auth.Getter
  private user!: User
}
