import _ from 'lodash'
import { IUserIndustryForm } from '@/interfaces/form-interfaces/UserFormInterface'
import { IIndustryResponse } from '@/interfaces/user/UserInterface'
import Model from '@/classes/model'
import Classifier from '../training/classifier'
import { IndustryTypeData } from '@/configs/industry-types'

export default class Industry extends Model {
  trainingCenterId: number
  isHydro!: boolean
  isWind!: boolean
  isSolar!: boolean
  isBiomass!: boolean
  isNuclear!: boolean
  isOilAndGas!: boolean

  constructor(data: IIndustryResponse) {
    super(data)

    this.trainingCenterId = data.training_center_id
    this.isHydro = data.is_hydro
    this.isWind = data.is_wind
    this.isSolar = data.is_solar
    this.isBiomass = data.is_biomass
    this.isNuclear = data.is_nuclear
    this.isOilAndGas = data.is_oil_and_gas
  }

  toFormData(): IUserIndustryForm {
    return {
      trainingCenterId: this.trainingCenterId,
      isHydro: this.isHydro,
      isWind: this.isWind,
      isSolar: this.isSolar,
      isBiomass: this.isBiomass,
      isNuclear: this.isNuclear,
      isOilAndGas: this.isOilAndGas,
    }
  }

  getActiveIndustries(): string[] {
    return _.keys(this)
      .map((key: string) => {
        // @ts-ignore
        if (this[key] && typeof this[key] === 'boolean' && IndustryTypeData[key]) return IndustryTypeData[key].title

        return false
      })
      .filter((element: string | boolean) => element !== false)
  }

  getIndustryOptions(options: Classifier[]): Classifier[] {
    const keys: string[] = _.keys(this)
      .map((key: string) => {
        // @ts-ignore
        if (this[key] && typeof this[key] === 'boolean' && IndustryTypeData[key]) return IndustryTypeData[key].code

        return false
      })
      .filter((element: string | boolean) => element !== false)

    return options.filter((option: Classifier) => keys.includes(option.code))
  }
}
