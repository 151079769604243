





import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import http from '@/classes/http'
import User from '@/classes/user/user'
import tokenHelper from '@/helpers/token-helper'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import Routes from '@/router/routes'
import { ICartResponseData } from '@/interfaces/CartInterface'

const Global = namespace('Global')

const Cart = namespace('Cart')

const Auth = namespace('Auth')

@Component
export default class SocialLogin extends Vue {
  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Cart.Action
  private fetchCart!: () => Promise<ICartResponseData[]>

  @Cart.Action
  private addItemToCart!: (trainingId: number) => Promise<ICartResponseData>

  @Auth.Mutation
  private loginSuccess!: (token: string) => void

  @Auth.Action
  private getMe!: () => Promise<User>

  @Auth.Getter
  private userIsTrainingCenter!: boolean

  private routeAfterLogin = Routes.PROFILE

  created(): void {
    const { provider } = this.$route.params

    const { query } = this.$route

    http
      .get(`auth/${provider}/callback`, {
        params: query,
      })
      .then(async (response: any) => {
        const { token } = response.data.data

        this.loginSuccess(token)
        tokenHelper.setToken(token)

        const userData = await this.getMe()

        tokenHelper.setUser(token, userData.type).then(async () => {
          if (!this.userIsTrainingCenter && this.$route.params.data) {
            const trainingData = JSON.parse(this.$route.params.data)

            if (trainingData.trainingId) {
              this.routeAfterLogin = Routes.CART
              await this.addItemToCart(trainingData.trainingId)
            }
          }

          const cart = localStorage.getItem('cartItem')

          if (!this.userIsTrainingCenter && cart && !this.$route.params.data) {
            try {
              const jsonCart = JSON.parse(cart)
              this.routeAfterLogin = Routes.CART
              await this.addItemToCart(jsonCart.trainingUuid).then(() => {
                localStorage.removeItem('cartItem')
                this.showSnackbar({
                  type: SnackbarType.success,
                  text: 'Training added to cart',
                })
              })
            } catch (error) {
              this.showSnackbar({
                type: SnackbarType.error,
                text: 'Failed to add training to card',
              })
            }
          }

          if (!this.userIsTrainingCenter) await this.fetchCart()

          await this.$router.push({ name: this.routeAfterLogin })

          this.showSnackbar({
            type: SnackbarType.success,
            text: 'Successfully logged in',
          })
        })
      })
  }
}
