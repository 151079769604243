






















































































































import { Component, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { namespace } from 'vuex-class'
import { AxiosError } from 'axios'
import { BvTableFieldArray } from 'bootstrap-vue/src/components/table'
import CompanyDiscount from '@/classes/company-discount'
import { IMeta, IPaginatedResponse } from '@/interfaces/ResponseInterface'
import CompanyDiscountService from '@/services/CompanyDiscountService'
import { ITableProperties, ITableSortChanged } from '@/interfaces/TableInterface'
import DiscountModal from '@/components/profile/DiscountModal.vue'
import IConfirmDialog from '@/interfaces/ConfirmDialogInterface'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import SelectOption from '@/interfaces/SelectOption'
import EmptyList from '@/components/EmptyList.vue'
import Pagination from '@/components/Pagination.vue'
import Search from '@/components/Search.vue'
import scrollToRef from '@/helpers/scroll-to-ref-helper'

const Global = namespace('Global')

enum DiscountsFilter {
  search = 'q',
  validity = 'discount_validity',
}

@Component({
  components: { Search, EmptyList, DiscountModal, Pagination },
})
export default class Discounts extends Vue {
  private loading: boolean = false
  private discounts: CompanyDiscount[] = []
  private meta: IMeta | any = {}

  public tableProperties: ITableProperties = {
    currentPage: 1,
    perPage: 15,
    filters: {
      search: '',
      discountValidity: null,
    },
  }

  @Global.Action
  private showConfirmDialog!: (confirmDialog: IConfirmDialog) => void

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  public created(): void {
    this.parseQueryParams()
    this.loadCompanyDiscounts()

    this.$watch(() => this.$route, this.loadCompanyDiscounts)
  }

  private loadCompanyDiscounts(): void {
    this.loading = true

    const { query } = this.$router.currentRoute

    CompanyDiscountService.list(query, this.tableProperties.perPage).then(
      (response: IPaginatedResponse<CompanyDiscount[]>) => {
        this.discounts = response.data
        this.meta = response.meta
      }
    )
    this.loading = false
  }

  private removeDiscount(id: number): void {
    this.showConfirmDialog({
      confirmAction: () => {
        return CompanyDiscountService.destroy(id)
          .then(() => {
            this.loadCompanyDiscounts()
            this.showSnackbar({
              type: SnackbarType.success,
              text: 'Discount was successfully removed',
            })
          })
          .catch((error: AxiosError) =>
            this.showSnackbar({
              type: SnackbarType.error,
              text: `Something wrong, discount not removed. More details: ${error.response?.data.message}`,
            })
          )
      },
    })
  }

  private onTableSortChange(event: ITableSortChanged): void {
    this.tableProperties.sortBy = event.sortBy
    this.tableProperties.order = event.sortDesc ? 'desc' : 'asc'
    this.changeRouteByProperties()
  }

  private onPageChange(page: number): void {
    this.tableProperties.currentPage = page
    this.changeRouteByProperties()
  }

  private onSearch(search: string): void {
    this.tableProperties.filters.search = search

    this.changeRouteByProperties()
  }

  public getToggleOrderEvent(order: string): any {
    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'asc') {
      return {
        sortBy: '',
        sortDesc: false,
      }
    }

    if (this.tableProperties.sortBy === order && this.tableProperties.order === 'desc') {
      return {
        sortBy: order,
        sortDesc: false,
      }
    }

    return {
      sortBy: order,
      sortDesc: true,
    }
  }

  private getFilterOrderClass(order: string): string {
    if (this.tableProperties.sortBy === order) {
      return this.tableProperties.order || ''
    }

    return ''
  }

  private changeRouteByProperties(): void {
    const { name, query, params }: any = this.$router.currentRoute

    const { currentPage, sortBy, order, filters } = this.tableProperties

    const routeQuery = {
      ...query,
      page: currentPage,
      sort_by: sortBy,
      order,
    }

    if (filters.search !== '') routeQuery[DiscountsFilter.search] = filters.search
    else delete routeQuery[DiscountsFilter.search]

    if (filters.discountValidity) routeQuery[DiscountsFilter.validity] = filters.discountValidity
    else delete routeQuery[DiscountsFilter.validity]

    this.$router
      .replace({
        name,
        params,
        query: routeQuery,
      })
      .catch(() => {})
  }

  private parseQueryParams(): void {
    const { query } = this.$router.currentRoute

    this.tableProperties.currentPage = Number(_.get(query, 'page', 1))
    this.tableProperties.filters.search = _.get(query, DiscountsFilter.search, '')
    this.tableProperties.filters.sortBy = _.get(query, 'sort_by')
    this.tableProperties.filters.order = _.get(query, 'order')
    this.tableProperties.filters.discountValidity = _.get(query, DiscountsFilter.validity, '')
  }

  private getFields(): BvTableFieldArray {
    return [
      {
        key: 'company.name',
        label: 'Company',
        sortable: true,
      },
      {
        key: 'percent',
        label: 'Discount',
        sortable: true,
      },
      {
        key: 'startDate',
        label: 'Valid from',
        sortable: true,
      },
      {
        key: 'endDate',
        label: 'Valid until',
        sortable: true,
      },
      {
        key: 'id',
        label: '',
      },
    ]
  }

  private onPageInput(id: string): void {
    this.loading = true

    scrollToRef(id)
  }

  private clearAllFilters(): void {
    this.tableProperties.filters.discountValidity = ''
    this.tableProperties.filters.search = ''

    const { discountFilter }: any = this.$refs

    if (discountFilter) discountFilter.inputValue = ''

    this.changeRouteByProperties()
  }

  get discountValiditySelections(): SelectOption[] {
    return [
      { value: null, text: 'All' },
      { value: 'true', text: 'Only valid' },
      { value: 'false', text: 'Only invalid' },
    ]
  }
}
