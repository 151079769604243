


























import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import User from '@/classes/user/user'
import Routes from '@/router/routes'

const Auth = namespace('Auth')

@Component
export default class MissingInfoModal extends Vue {
  Routes = Routes
  visible: boolean = false

  @Auth.Getter
  private user!: User

  @Auth.Getter
  private userIsCompany!: boolean

  @Auth.Getter
  private userIsTrainingCenter!: boolean

  @Auth.Getter
  private userIsDefault!: boolean

  public toggle(): void {
    this.visible = !this.visible
  }

  public close(): void {
    this.visible = false
  }

  created(): void {
    if (this.user) this.dataCheck()
    this.$root.$on('checkForCompleteProfileData', this.dataCheck)
  }

  beforeDestroy() {
    this.$root.$off('checkForCompleteProfileData')
  }

  private dataCheck() {
    if (
      this.userIsCompany &&
      (!this.user.company.name ||
        !this.user.company.code ||
        !this.user.name ||
        !this.user.phoneNumber ||
        !this.user.userLocation.address ||
        !this.user.userLocation.city ||
        !this.user.userLocation.post)
    ) {
      this.visible = true
    }

    if (
      this.userIsTrainingCenter &&
      (!this.user.trainingCenter.description ||
        !this.user.trainingCenter.name ||
        !this.user.trainingCenter.url ||
        !this.user.trainingCenter.invoiceNumberFormat ||
        !this.user.name ||
        !this.user.phoneNumber ||
        !this.user.userLocation.address ||
        !this.user.userLocation.city ||
        !this.user.userLocation.post)
    ) {
      this.visible = true
    }

    if (
      this.userIsDefault &&
      (!this.user.name ||
        !this.user.phoneNumber ||
        !this.user.userLocation ||
        !this.user.userLocation.address ||
        !this.user.userLocation.city ||
        !this.user.userLocation.post)
    ) {
      this.visible = true
    }
  }
}
