






















import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import IConfirmDialog from '@/interfaces/ConfirmDialogInterface'

const Global = namespace('Global')

@Component
export default class ConfirmDialog extends Vue {
  loading: boolean = false

  @Global.Getter
  private confirmDialog!: IConfirmDialog

  @Global.Action
  private hideConfirmDialog!: () => void

  async onConfirm(): Promise<any> {
    this.loading = true

    if (this.confirmDialog.confirmAction) {
      await this.confirmDialog.confirmAction()
      this.loading = false
    }

    this.hideConfirmDialog()
  }

  onCancel(): void {
    this.hideConfirmDialog()
  }
}
