
































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Routes from '@/router/routes'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import { IOrderPayResponseData } from '@/interfaces/CartInterface'

const Global = namespace('Global')

const Cart = namespace('Cart')

@Component({
  components: { Breadcrumbs },
})
export default class StripeCallback extends Vue {
  private message: string = ''
  private loading: boolean = false
  Routes = Routes

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Cart.Action
  private paymentCallback!: (urlParams: string) => Promise<IOrderPayResponseData>

  created(): void {
    this.loading = true

    if (this.$route.query.payment_intent) {
      const urlParams = this.$route.fullPath.split('/stripe-callback?').pop() as string
      this.verifyPayment(urlParams)
    } else {
      this.loading = false
      this.message = 'Payment process failed. Try Again.'
    }
  }

  private async verifyPayment(urlParams: string): Promise<void> {
    try {
      const response = await this.paymentCallback(urlParams)

      await this.processSuccessPayment(response)
    } catch (error) {
      this.loading = false
      this.message = 'Payment process failed. Try Again.'
    }
  }

  private async processSuccessPayment(orderData: IOrderPayResponseData): Promise<void> {
    const routeParams = {
      totalPrice: orderData.total,
      email: orderData.order_address?.email,
      orderId: orderData.code,
      discount: orderData.discount,
    }

    this.showSnackbar({
      type: SnackbarType.success,
      text: 'Payment accepted successfully',
    })

    this.loading = false
    await this.$router.push({ name: Routes.SUCCESS, params: { data: JSON.stringify(routeParams) } })
  }
}
