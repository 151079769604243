




































import { Component, Prop } from 'vue-property-decorator'
import AbstractField from '@/components/form/fields/AbstractField.vue'

@Component
export default class CheckboxField extends AbstractField {
  @Prop() private readonly checkForValue!: void
  @Prop({ default: true }) private readonly checkedValue!: boolean | number | string
  @Prop({ default: false }) private readonly uncheckedValue!: boolean | number | string
}
