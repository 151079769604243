import Company from '@/classes/user/company'
import UserLocation from '@/classes/user/user-location'
import ICompanyDetailsResponse from '@/interfaces/CompanyDetailsInterface'
import Model from '@/classes/model'

export default class CompanyDetails extends Model {
  name!: string
  email!: string
  totalTrainings!: number
  totalRevenue!: string

  company!: Company
  location!: UserLocation

  constructor(data: ICompanyDetailsResponse) {
    super(data)

    this.name = data.name
    this.email = data.email
    this.totalTrainings = data.total_trainings
    this.totalRevenue = data.total_revenue

    this.company = this.setRelation(data.company, Company)
    this.location = this.setRelation(data.location, UserLocation)
  }
}
