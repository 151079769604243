import { IUserProfileResponse } from '@/interfaces/user/UserInterface'
import Model from '@/classes/model'
import { IUserProfileForm } from '@/interfaces/form-interfaces/UserFormInterface'

export default class UserProfile extends Model {
  sendNewsletters!: boolean
  sendOffers!: boolean
  location!: string
  url!: string
  positionClassifierId!: number | null
  vindaId!: string | null
  nationalityClassifierId!: number | null

  constructor(data: IUserProfileResponse) {
    super(data)

    this.location = data.location
    this.url = data.url
    this.sendNewsletters = data.send_newsletters
    this.sendOffers = data.send_offers
    this.positionClassifierId = data.position_classifier_id
    this.vindaId = data.vinda_id
    this.nationalityClassifierId = data.nationality_classifier_id
  }

  toFormData(): IUserProfileForm {
    return {
      sendNewsletters: this.sendNewsletters,
      sendOffers: this.sendOffers,
      location: this.location,
      url: this.url,
      positionClassifierId: this.positionClassifierId,
      vindaId: this.vindaId,
      nationalityClassifierId: this.nationalityClassifierId,
    }
  }
}
