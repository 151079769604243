




import { Component, Prop, Vue } from 'vue-property-decorator'
import TrainingTypePicker from '@/components/form/fields/training/TrainingTypePicker.vue'
import TrainingData from '@/classes/training/training'
import Classifier from '@/classes/training/classifier'
import TrainingService from '@/services/TrainingService'

@Component({
  components: {
    TrainingTypePicker,
  },
})
export default class TrainingStepTrainingType extends Vue {
  @Prop() private readonly data!: TrainingData
  typeOptions: Classifier[] = []

  async created(): Promise<void> {
    this.typeOptions = await TrainingService.getClassifiersByParent(this.data.standardClassifierId)
  }
}
