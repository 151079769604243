import { AxiosResponse } from 'axios'
import _ from 'lodash'
import Filter from '@/classes/filter'
import http from '@/classes/http'
import TrainingCenterListUser from '@/classes/user/training-center-list-user'
import TrainingCenter from '@/classes/user/training-center'
import ITrainingCenterListUserResponse from '@/interfaces/training-center/TrainingCenterListUserInterface'
import { IPaginatedResponse, IResponse } from '@/interfaces/ResponseInterface'
import { ITrainingCenterResponse } from '@/interfaces/user/UserInterface'

export default class TrainingCenterService {
  static async getTrainingCenters(
    page: number,
    search: string,
    filters?: Record<string, Filter>
  ): Promise<IPaginatedResponse<TrainingCenterListUser[]>> {
    return http
      .get(`users/training-centers?per_page=10&page=${page}&q=${search}${this.formatFilters(filters)}`)
      .then((response: AxiosResponse<IPaginatedResponse<ITrainingCenterListUserResponse[]>>) => ({
        data: response.data.data.map(
          (trainingCenterUser: ITrainingCenterListUserResponse) => new TrainingCenterListUser(trainingCenterUser)
        ),
        meta: response.data.meta,
      }))
  }

  static async getTrainingCenter(slug: string): Promise<TrainingCenterListUser> {
    return http
      .get(`users/${slug}/training-center-details`)
      .then((response) => new TrainingCenterListUser(response.data.data))
  }

  static formatFilters(filters?: Record<string, Filter>): string {
    if (!filters) return ''

    let filterString: string = ''
    _.each(filters, (filter: Filter) => {
      filterString += filter.createFilterString()
    })

    return filterString
  }

  static getDetails(slug: string): Promise<TrainingCenter> {
    return http
      .get(`users/${slug}/training-center-details`)
      .then((response: AxiosResponse) => new TrainingCenter(response.data.data))
  }

  static getTopTrainingCenters(): Promise<TrainingCenter[]> {
    return http
      .get('training-centers/get-top')
      .then((response: AxiosResponse<IResponse<ITrainingCenterResponse[]>>) =>
        response.data.data.map((trainingCenter: ITrainingCenterResponse) => new TrainingCenter(trainingCenter))
      )
  }
}
