
























import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Routes from '@/router/routes'
import AuthService from '@/services/AuthService'

const Auth = namespace('Auth')

@Component({
  components: { Breadcrumbs },
})
export default class EmailVerify extends Vue {
  private message: string = ''
  private canLogin: boolean = false
  Routes = Routes

  @Auth.Getter
  private isLoggedIn!: boolean

  created(): void {
    if (!this.isLoggedIn && this.$route.query.verify_url) {
      const apiUrl = this.$route.fullPath.split('verify_url=').pop() as string
      this.verifyAccount(decodeURIComponent(apiUrl))
    } else {
      this.message = 'Email verification failed.'
    }
  }

  private async verifyAccount(url: string): Promise<void> {
    try {
      const response = await AuthService.emailVerify(url)

      if (response.status === 'Success') {
        this.canLogin = true
        this.message = 'Email verification succeeded! You can login now.'
      }
    } catch (error) {
      this.message = 'Email verification failed. Try again later.'
    }
  }
}
