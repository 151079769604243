


































import { Component, Prop, Vue } from 'vue-property-decorator'
import { IJobData } from '@/interfaces/JobPositionsInterface'
import Routes from '@/router/routes'

@Component
export default class JobCard extends Vue {
  @Prop() data!: IJobData
  Routes = Routes

  get logoUrl(): string {
    if (this.data.company.user.logo !== null) {
      return this.data.company.user.logo.file_path
    }

    return require('@/assets/images/logo-thumbnail.png')
  }
}
