
















import { Component, Prop, Vue } from 'vue-property-decorator'
import SelectOption from '@/interfaces/SelectOption'

@Component
export default class ChangeStudentStatus extends Vue {
  @Prop() object!: any
  @Prop({ default: () => [] }) options!: SelectOption[]
  @Prop() uuid!: string

  status: string = null!

  async apply(): Promise<void> {
    this.$emit('apply', { uuid: this.uuid, status: this.status, object: this.object })
  }
}
