import { AxiosResponse } from 'axios'
import http from '@/classes/http'
import {
  IEmployeesAddItem,
  IEmployeesRemoveItem,
  IPaymentPayloadData,
  IUpdateCartEmployeesData,
} from '@/interfaces/CartInterface'

export default class CartService {
  static addToCart(trainingUuid: string): Promise<AxiosResponse> {
    return http
      .post('cart-items', { training_location_date_uuid: trainingUuid })
      .then(async (response) => response.data)
  }

  static fetchCartContent(): Promise<AxiosResponse> {
    return http.get('shopping-sessions/my').then(async (response) => response.data)
  }

  static checkout(): Promise<AxiosResponse> {
    return http.post('shopping-sessions/checkout').then(async (response) => response.data)
  }

  static removeItemFromCart(cartItemUuid: string): Promise<AxiosResponse> {
    return http.delete(`cart-items/${cartItemUuid}`)
  }

  static getEmployeesByCartId(cartItemUuid: string): Promise<AxiosResponse> {
    return http
      .get(`company-employees/get-by-cart-item/${cartItemUuid}?per_page=9999`)
      .then(async (response) => response.data)
  }

  static updateCartEmployeesList(data: IUpdateCartEmployeesData): Promise<AxiosResponse> {
    const payload = this.getUpdateCartEmployeesPayload(data)

    return http.put(`cart-items/${data.cartUuid}/update-user-list`, payload)
  }

  static orderPay(data: IPaymentPayloadData): Promise<AxiosResponse> {
    const payload = this.getOrderPayPayload(data)

    return http.post(`orders/${data.orderUuid}/pay`, payload).then(async (response) => response.data)
  }

  static paymentCallback(urlParams: string): Promise<AxiosResponse> {
    return http.get(`payments/stripe-callback?${urlParams}`).then(async (response) => response.data)
  }

  // Payloads

  private static getUpdateCartEmployeesPayload(data: IUpdateCartEmployeesData) {
    const addList: IEmployeesAddItem[] = []

    const removeList: IEmployeesRemoveItem[] = []
    data.add.forEach((item) => {
      addList.push({
        user_uuid: item,
      })
    })
    data.remove.forEach((item) => {
      removeList.push({
        cart_item_user_uuid: item,
      })
    })

    return {
      add_to_cart_item: addList,
      remove_from_cart_item: removeList,
    }
  }

  private static getOrderPayPayload(data: IPaymentPayloadData) {
    return {
      ...data,
      payment: {
        type: 'stripe',
      },
      userProfile: [],
    }
  }
}
