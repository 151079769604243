


































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Routes from '@/router/routes'
import { IBaseResponse, IPasswordResetData } from '@/interfaces/AuthInterface'
import ISnackbar, { SnackbarType } from '@/interfaces/SnackbarInterface'
import router from '@/router'

const Global = namespace('Global')

const Auth = namespace('Auth')

@Component({
  components: { Breadcrumbs },
})
export default class PasswordReset extends Vue {
  private loading = false
  private submitted = false
  private passwordRevealed = false
  private showPassword(): void {
    this.passwordRevealed = !this.passwordRevealed
  }
  private user: IPasswordResetData = { token: '', email: '', password: '', passwordConfirmation: '' }
  Routes = Routes

  @Global.Action
  private showSnackbar!: (snackbar: ISnackbar) => void

  @Auth.Getter
  private isLoggedIn!: boolean

  @Auth.Action
  private passwordReset!: (user: IPasswordResetData) => Promise<IBaseResponse>

  created(): void {
    if (!this.isLoggedIn && this.$route.query) {
      this.user.email = this.$route.query.email as string
      this.user.token = this.$route.query.token as string
    }
  }

  private async resetPassword(): Promise<void> {
    this.loading = true
    this.submitted = true
    try {
      const response = await this.passwordReset(this.user)

      if (response.status === 'Success') {
        this.showSnackbar({
          type: SnackbarType.success,
          text: response.message,
        })
      }
      this.loading = false
      await router.push({ name: Routes.LOGIN })
    } catch (error: any) {
      this.loading = false
      this.showSnackbar({
        type: SnackbarType.error,
        text: error.message,
      })
      this.$validator.errors.clear()

      if (error.status !== 422) return

      const errorFields = Object.keys(error.errors)
      errorFields.forEach((field: any) => {
        const errorString = error.errors[field].join(', ')
        this.$validator.errors.add({
          field,
          msg: errorString,
        })
      })
    }
  }
}
