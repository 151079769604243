



















import { Component, Vue } from 'vue-property-decorator'
import NewsItem from '@/components/home/NewsItem.vue'
import { NewsInterface } from '@/interfaces/NewsInterface'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Pagination from '@/components/Pagination.vue'

@Component({
  components: { Pagination, Breadcrumbs, NewsItem },
})
export default class NewsArchive extends Vue {
  public news: NewsInterface[] = [
    {
      id: 1,
      heading: 'Lessons and insights from 8 years of Pixelgrade',
      date: '2020 10 16',
      image: 'https://placeimg.com/560/460/any',
    },
    {
      id: 2,
      heading: 'Lessons and insights from 8 years of Pixelgrade',
      date: '2020 10 16',
      image: 'https://placeimg.com/560/460/any',
    },
    {
      id: 3,
      heading: 'Lessons and insights from 8 years of Pixelgrade',
      date: '2020 10 16',
      image: 'https://placeimg.com/560/460/any',
    },
    {
      id: 4,
      heading: 'Lessons and insights from 8 years of Pixelgrade',
      date: '2020 10 16',
      image: 'https://placeimg.com/560/460/any',
    },
  ]
}
