






















import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import User from '@/classes/user/user'
import CompanyDetailsModel from '@/classes/company-details'
import UserService from '@/services/UserService'

const Auth = namespace('Auth')

@Component
export default class UserDetails extends Vue {
  @Auth.Getter
  private user!: User

  public userDetails: CompanyDetailsModel = null!
  public loading: boolean = true

  public created(): void {
    this.loadUserDetails()
  }

  public loadUserDetails(): void {
    this.loading = true

    UserService.getUserDetails(this.user.uuid)
      .then((userDetails: CompanyDetailsModel) => (this.userDetails = userDetails))
      .finally(() => (this.loading = false))
  }
}
