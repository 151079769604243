import { ITrainingCenterResponse } from '@/interfaces/user/UserInterface'
import Industry from '@/classes/user/industry'
import Model from '@/classes/model'
import { IUserTrainingCenterForm } from '@/interfaces/form-interfaces/UserFormInterface'
import TrainingCenterLocation from '@/classes/user/training-center-location'

export default class TrainingCenter extends Model {
  name!: string
  slug!: string
  url!: string
  description!: string
  totalRevenue!: number
  totalTrainings!: number
  totalActiveTrainings!: number
  invoiceNumberFormat!: string
  code!: string
  vat!: string

  industry!: Industry
  location!: TrainingCenterLocation

  constructor(data: ITrainingCenterResponse) {
    super(data)

    this.name = data.name
    this.slug = data.slug
    this.url = data.url
    this.description = data.description
    this.totalRevenue = data.total_revenue
    this.totalTrainings = data.total_trainings
    this.totalActiveTrainings = data.total_active_trainings
    this.invoiceNumberFormat = data.invoice_number_format
    this.code = data.code
    this.vat = data.vat

    if (data.industry) this.industry = this.setRelation(data.industry, Industry)

    if (data.location) this.location = this.setRelation(data.location, TrainingCenterLocation)

    if (this.id && this.industry) this.industry.trainingCenterId = this.id
  }

  toFormData(): IUserTrainingCenterForm {
    return {
      id: this.id,
      name: this.name,
      slug: this.slug,
      url: this.url,
      description: this.description,
      industry: this.toFormDataGeneric(this.industry),
      invoiceNumberFormat: this.invoiceNumberFormat,
      code: this.code,
      vat: this.vat,
    }
  }
}
